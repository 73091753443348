import { DateTime, Duration } from 'luxon';

/** Local datetime type that enforces that its datetime is in local timezone. */
export default class LocalDateTime {
  public datetime: DateTime;

  public constructor(datetime?: DateTime | LocalDateTime) {
    // Default constructor
    if (datetime === undefined) {
      this.datetime = DateTime.local();

      // Copy constructor
    } else if (datetime instanceof LocalDateTime) {
      this.datetime = datetime.datetime;

      // DateTime constructor
    } else {
      const localZone = DateTime.local().zone;
      if (!localZone.equals(datetime.zone)) {
        throw new TypeError(
          `Datetime must be in local timezone; got ${datetime.toISO()} with zone ${
            datetime.zoneName
          }, expected timezone ${localZone.name}`
        );
      }

      this.datetime = datetime;
    }
  }

  public static fromOffset(offset: Duration): LocalDateTime {
    return new LocalDateTime(DateTime.local().startOf('day').plus(offset));
  }

  public asOffset(): Duration {
    const dayStart = this.datetime.startOf('day');
    return this.datetime.diff(dayStart, ['hours', 'minutes']);
  }

  public toISO(): string | null {
    return this.datetime.toISO();
  }

  public equals(d: LocalDateTime): boolean {
    return this.datetime.equals(d.datetime);
  }
}
