import { isNumber } from 'lodash';
import { DateTime } from 'luxon';
import React, { useCallback, useContext, useMemo, useState } from 'react';

import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';

import EventDisplay from 'components/EventDisplay';
import HeaderNavigationBar from 'components/HeaderNavigationBar';
import SearchInput from 'components/SearchInput';
import Select from 'components/Select';
import { ProgramsContext } from 'contexts/ProgramsContext';
import useGetDers from 'hooks/useGetDers';
import useGetPricingEvents from 'hooks/useGetPricingEvents';
import useLocaleFormatter from 'hooks/useLocaleFormatters';
import { useGetAllOffersRequestEffect } from 'hooks/useOffersApi';
import { MarketType, SelectOption, BidOfferType } from 'types';

import SupplyStackGraph from './SupplyStackGraph';
import './BidsOffers.scss';

const MARKET_TYPES: SelectOption[] = [
  { label: 'Same Day', value: MarketType.SAMEDAY },
  { label: 'Day Ahead', value: MarketType.DAYAHEAD },
];

const BidsOffers = () => {
  const { getSamedayEventDuration, getTimezone } = useContext(ProgramsContext);
  const eventDuration = useMemo(() => getSamedayEventDuration(), [getSamedayEventDuration]);
  const timezone = useMemo(() => getTimezone(), [getTimezone]);

  const getStartTime = useCallback(() => {
    return DateTime.local().setZone(timezone).startOf('hour');
  }, [timezone]);

  const [market, setMarket] = useState(MarketType.SAMEDAY);
  const [eventStartTime, setEventStartTime] = useState(getStartTime());

  const toggleMarket = (marketType: MarketType) => {
    if (market !== marketType) {
      setMarket(marketType);
      setEventStartTime(eventStartTime.startOf('hour'));
    }
  };

  const duration = market === MarketType.DAYAHEAD ? 3600 : eventDuration || 3600;

  const eventEndTime = useMemo(() => eventStartTime.plus({ seconds: duration }), [
    duration,
    eventStartTime,
  ]);

  const { loadingOffers, offers } = useGetAllOffersRequestEffect(
    duration,
    market,
    eventStartTime,
    timezone
  );

  const { ders } = useGetDers();
  const dersMap = ders.reduce((acc, der) => acc.set(der.rdfId, der), new Map());
  const { events } = useGetPricingEvents(eventStartTime, eventEndTime);
  const eventsMap = events.reduce((acc, event) => acc.set(event.derRdfId, event), new Map());
  const { programs } = useContext(ProgramsContext);
  const { currencyFormatter, numberFormatter } = useLocaleFormatter(
    programs[0]?.currency,
    programs[0]?.locale
  );

  return (
    <div className="bids-offers">
      <HeaderNavigationBar title="Bids/Offers" />
      <div className="control-container">
        <Select
          label="Market"
          onChange={(opt: SelectOption) => toggleMarket(opt.value)}
          options={MARKET_TYPES}
          row
          value={market === MarketType.SAMEDAY ? MARKET_TYPES[0] : MARKET_TYPES[1]}
        />
        <EventDisplay
          eventStartTime={eventStartTime}
          duration={duration}
          addTime={(multiple) => {
            const newTime = eventStartTime.plus({
              seconds: multiple * duration,
            });
            setEventStartTime(newTime);
          }}
        />
      </div>
      <div className="chart-container">
        <SupplyStackGraph
          currencyFormatter={currencyFormatter}
          offers={(offers || []).filter(({ bidOfferType }) => bidOfferType === BidOfferType.OFFER)}
          title="Offers"
          xLabel="Offer Quantity (kW)"
          yLabel={`${programs[0]?.currency} / MW`}
        />
        <SupplyStackGraph
          currencyFormatter={currencyFormatter}
          offers={(offers || []).filter(({ bidOfferType }) => bidOfferType === BidOfferType.BID)}
          title="Bids"
          xLabel="Bid Quantity (kW)"
          yLabel={`${programs[0]?.currency} / MW`}
        />
      </div>
      <div className="table-container">
        <ReactTable
          data={offers}
          loading={loadingOffers}
          showPageSizeOptions={false}
          pageSize={8}
          columns={[
            {
              Header: 'DER',
              accessor: (offer) => dersMap.get(offer.derRdfId)?.info?.name,
              filterable: true,
              filterMethod: (filter: any, row: any) =>
                row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
              // eslint-disable-next-line react/display-name
              Filter: (cellInfo) => (
                <SearchInput
                  onChange={(e) => cellInfo.onChange(e.target.value)}
                  placeholder="DER"
                />
              ),
              id: 'der',
              sortable: true,
            },
            {
              Header: 'Bid (Price)',
              accessor: ({ bidOfferType, price }) =>
                bidOfferType === BidOfferType.BID
                  ? `${currencyFormatter.format(price || 0)} MWh`
                  : null,
              filterable: true,
              filterMethod: (filter: any, row: any) =>
                row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
              // eslint-disable-next-line react/display-name
              Filter: (cellInfo) => (
                <SearchInput
                  onChange={(e) => cellInfo.onChange(e.target.value)}
                  placeholder="Bid Price"
                />
              ),
              id: 'bid_price',
              sortable: true,
            },
            {
              Header: 'Bid (Quantity)',
              accessor: ({ bidOfferType, quantity }) =>
                bidOfferType === BidOfferType.BID
                  ? `${numberFormatter.format((quantity || 0) * 1e3)} kW`
                  : null,
              filterable: true,
              filterMethod: (filter: any, row: any) =>
                row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
              // eslint-disable-next-line react/display-name
              Filter: (cellInfo) => (
                <SearchInput
                  onChange={(e) => cellInfo.onChange(e.target.value)}
                  placeholder="Bid Quantity"
                />
              ),
              id: 'bid_quantity',
              sortable: true,
            },
            {
              Header: 'Offer (Price)',
              accessor: ({ bidOfferType, price }) =>
                bidOfferType === BidOfferType.OFFER
                  ? `${currencyFormatter.format(price || 0)}/MW`
                  : null,
              filterable: true,
              filterMethod: (filter: any, row: any) =>
                row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
              // eslint-disable-next-line react/display-name
              Filter: (cellInfo) => (
                <SearchInput
                  onChange={(e) => cellInfo.onChange(e.target.value)}
                  placeholder="Offer Price"
                />
              ),
              id: 'offer_price',
              sortable: true,
            },
            {
              Header: 'Offer (Quantity)',
              accessor: ({ bidOfferType, quantity }) =>
                bidOfferType === BidOfferType.OFFER
                  ? `${numberFormatter.format((quantity || 0) * 1e3)} kW`
                  : null,
              filterable: true,
              filterMethod: (filter: any, row: any) =>
                row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
              // eslint-disable-next-line react/display-name
              Filter: (cellInfo) => (
                <SearchInput
                  onChange={(e) => cellInfo.onChange(e.target.value)}
                  placeholder="Offer Quantity"
                />
              ),
              id: 'offer_quantity',
              sortable: true,
            },
            {
              Header: 'Cleared',
              accessor: ({ derRdfId }) => {
                const { powerRequired } = eventsMap?.get(derRdfId as any) || {};
                return isNumber(powerRequired) && powerRequired !== 0.0;
              },
              id: 'cleared',
              resizable: false,
              sortable: true,
              width: 75,
              // eslint-disable-next-line react/display-name
              Cell: (props) => {
                return (
                  <span className="center-container" style={{ color: '#6D982A' }}>
                    <i className="material-icons">{props.value ? 'check_circle' : ''}</i>
                  </span>
                );
              },
            },
          ]}
          className="-striped -highlight"
          showPaginationBottom
        />
      </div>
    </div>
  );
};

export default BidsOffers;
