import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import CancelEventsModal from 'components/CancelEventsModal';
import PricingEventsList from 'components/PricingEventsList';
import GenerationChartPanel from 'components/GenerationChartPanel';
import DatePicker from 'components/DatePicker';
import HeaderNavigationBar from 'components/HeaderNavigationBar';
import { ProgramsContext } from 'contexts/ProgramsContext';
import asyncActionStates from 'helpers/asyncActionStates';
import marketTypes from 'helpers/marketTypes';
import ACTIVE_STATES from 'helpers/activeStates';
import NoCommittedEvents from './NoCommittedEvents';
import NoEnrolledAssets from './NoEnrolledAssets';
import './Home.scss';

class Home extends PureComponent {
  state = {
    currentDate: DateTime.local(),
    showCancelModal: false,
    eventToCancel: null,
  };

  // Show placeholder if there are no events to display
  getPlaceholder = () => {
    if (this.props.DERRequest === asyncActionStates.LOADING) {
      return (
        <div className="events-placeholder">
          <div className="loading-dots">
            <div className="bounce1" />
            <div className="bounce2" />
            <div className="bounce3" />
          </div>
        </div>
      );
    }

    return Object.keys(this.props.DERLookup).length > 0 ? (
      <NoCommittedEvents />
    ) : (
      <NoEnrolledAssets />
    );
  };

  UNSAFE_componentWillMount() {
    this.props.actions.getPricingEvents(this.handleTimezone(this.state.currentDate));
    this.props.actions.getDERs();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { pricingEvents } = nextProps.events;
    // Format the pricing events to display grouped by timestamp
    if (pricingEvents !== this.props.events.pricingEvents) {
      this.props.actions.summarizePricingEvents(pricingEvents);
      this.props.actions.processPricingEvents(pricingEvents, [marketTypes.SAME_DAY]);
    }
  }

  handleDateChange = (date) => {
    this.props.actions.getPricingEvents(this.handleTimezone(date));
    this.setState({ currentDate: this.handleTimezone(date) });
  };

  handleTimezone = (date) => {
    const timezone = this.context.getTimezone();
    return date.setZone(timezone);
  };

  updatePricingEventThreshold = () => {
    this.props.actions.summarizePricingEvents(this.props.events.pricingEvents);
  };

  canEditTimestamp = (isTimestampOpen, events) => {
    if (!isTimestampOpen || !events || !events.length) {
      return false;
    }
    const expiryTime = DateTime.fromISO(events[0].expiration_time);
    return DateTime.local() <= expiryTime;
  };

  anyEvents = (eventsObject) => {
    if (eventsObject === undefined) {
      return false;
    }
    return Object.keys(eventsObject).length > 0;
  };

  askForCancellationReason = (event) => {
    this.setState({
      showCancelModal: true,
      eventToCancel: event,
    });
  };

  closeCancellationModal = () => {
    this.setState({ showCancelModal: false });
  };

  handleStatusChange = (event, status) => {
    if (ACTIVE_STATES.includes(event.status)) {
      this.askForCancellationReason(event);
    } else {
      this.props.actions.updatePricingEventStatus(event, this.state.currentDate, status);
    }
  };

  render() {
    const hasCommittedEvents = this.anyEvents(this.props.events.committedEvents);
    const hasConfirmedEvents = this.anyEvents(this.props.events.confirmedEvents);

    return (
      <div className="home-page">
        <HeaderNavigationBar>
          <div className="date-picker-wrapper">
            <DatePicker onChange={this.handleDateChange} date={this.state.currentDate} />
          </div>
        </HeaderNavigationBar>
        {this.state.showCancelModal && (
          <CancelEventsModal
            closeCancellationModal={this.closeCancellationModal}
            eventToCancel={this.state.eventToCancel}
            currentDate={this.handleTimezone(this.state.currentDate)}
            updatePricingEventStatus={this.props.actions.updatePricingEventStatus}
          />
        )}
        <GenerationChartPanel
          currentDate={this.handleTimezone(this.state.currentDate)}
          DERLookup={this.props.DERLookup}
          pricingEventsByDER={this.props.events.pricingEventsByDER}
        />
        <div className="homepage-contents">
          {hasConfirmedEvents && (
            <div className="confirmed-events events-list">
              <h4 className="event-list-header">Upcoming Confirmed Events</h4>
              <div className="events-list-contents">
                <PricingEventsList
                  currency={this.context.programs[0]?.currency || 'USD'}
                  events={this.props.events.confirmedEvents}
                  DERLookup={this.props.DERLookup}
                  eventDate={this.handleTimezone(this.state.currentDate)}
                  marketType="dayahead"
                  pricingEventsReq={this.props.events.pricingEventsReq}
                  showResponseWidget
                  onStatusChange={this.handleStatusChange}
                  canEditTimestamp={this.canEditTimestamp}
                  updateEventReq={this.props.events.updateEventReq}
                  onAssetClick={(id) => this.props.history.push(`/assets/${id}`)}
                />
              </div>
            </div>
          )}
          {hasCommittedEvents && (
            <div className="committed-events events-list">
              <h4 className="event-list-header">Committed Events</h4>
              <div className="events-list-contents">
                <PricingEventsList
                  currency={this.context.programs[0]?.currency || 'USD'}
                  events={this.props.events.committedEvents}
                  DERLookup={this.props.DERLookup}
                  eventDate={this.handleTimezone(this.state.currentDate)}
                  marketType="dayahead"
                  pricingEventsReq={this.props.events.pricingEventsReq}
                  showResponseWidget
                  onStatusChange={(event, status) => {
                    this.props.actions.updatePricingEventStatus(
                      event,
                      this.handleTimezone(this.state.currentDate),
                      status
                    );
                  }}
                  canEditTimestamp={this.canEditTimestamp}
                  updateEventReq={this.props.events.updateEventReq}
                  onAssetClick={(id) => this.props.history.push(`/assets/${id}`)}
                />
              </div>
            </div>
          )}
          {!hasCommittedEvents && !this.hasConfirmedEvents && this.getPlaceholder()}
        </div>
      </div>
    );
  }
}

Home.contextType = ProgramsContext;
Home.contextTypes = {
  programs: PropTypes.array,
  getTimezone: PropTypes.func,
};

Home.propTypes = {
  actions: PropTypes.object.isRequired,
  events: PropTypes.object.isRequired,
  DERLookup: PropTypes.object.isRequired,
  DERRequest: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired,
};

export default Home;
