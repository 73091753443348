import { AxiosError } from 'axios';
import { useRequestEffect } from '@opusonesolutions/gridos-app-framework';

import { Der, toDer } from 'types/der';

const useGetDers = () => {
  const {
    data: ders,
    loading: loadingDers,
    refetch: refetchDers,
  }: {
    data: Der[];
    loading: boolean;
    refetch: () => void;
  } = useRequestEffect({
    url: '/api/mpi/der',
    method: 'get',
    dataTransform: (data) => data.map((der: any) => toDer(der)),
    initialData: [],
    toast: {
      error: (error: AxiosError) => {
        if (error?.response?.data.message) {
          return `${error?.response?.data.message}`;
        }
        return 'Could not load DERs.';
      },
      settings: {
        autoDismiss: true,
      },
    },
  });

  return {
    ders,
    loadingDers,
    refetchDers,
  };
};

export default useGetDers;
