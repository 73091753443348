import { isNumber } from 'lodash';
import { DateTime, Duration } from 'luxon';

import { BidOfferType, MarketType, QuantityType } from 'types';

const TIME_FORMAT = 'HH:mmZZ';

const formatQuantity = (quantity?: number): number | undefined => {
  if (isNumber(quantity)) {
    return quantity / 1e6;
  }
  return undefined;
};

export interface BidOffer {
  marketType: MarketType;
  bidOfferType: BidOfferType;
  startTime: DateTime;
  endTime: DateTime;
  duration: number;
  price?: number;
  quantity?: number;
  quantityType: QuantityType;

  id?: number;
  derRdfId?: string;
}

export const fromBidOffer = (assetID: string, data: BidOffer[], useDate?: boolean): any[] => {
  return data
    .filter(({ price, quantity }) => isNumber(price) && isNumber(quantity))
    .map(
      ({
        marketType: market_type,
        bidOfferType: bid_offer_type,
        quantityType: quantity_type,
        startTime,
        duration,
        price,
        quantity,
      }) => {
        return {
          der_rdf_id: assetID,
          market_type,
          bid_offer_type,
          start_time: useDate ? startTime.toISO() : startTime.toFormat(TIME_FORMAT),
          duration,
          price,
          quantity: Math.floor((quantity as number) * 1e6),
          quantity_type,
        };
      }
    );
};

export const sortBidOffer = (data: BidOffer[]): BidOffer[] => {
  return data.sort((a, b) => {
    const { startTime: startTimeA, endTime: endTimeA } = a;
    const { startTime: startTimeB, endTime: endTimeB } = b;

    if (startTimeA < startTimeB) {
      return -1;
    }
    if (startTimeA > startTimeB) {
      return 1;
    }
    if (endTimeA < endTimeB) {
      return -1;
    }
    if (endTimeA > endTimeB) {
      return 1;
    }

    return 0;
  });
};

export const toBidOffer = (
  data: any[],
  timezone: string,
  useDate?: boolean,
  assetType?: string
): BidOffer[] => {
  return data.map(
    ({
      market_type: marketType,
      type: bidOfferType,
      start_time: startTime,
      duration,
      price,
      quantity,
      id,
      der_rdf_id: derRdfId,
    }) => {
      const convertedStartTime = useDate
        ? DateTime.fromISO(startTime).setZone(timezone)
        : DateTime.fromFormat(startTime, 'HH:mm:ss', { zone: timezone });

      let defaultQuantityType = QuantityType.POWER;
      if (marketType.localeCompare('DAYAHEAD') === 0 && assetType === 'Load (Shifting)') {
        defaultQuantityType = QuantityType.ENERGY;
      }

      return {
        marketType,
        bidOfferType,
        quantityType: defaultQuantityType,
        startTime: convertedStartTime,
        endTime: convertedStartTime.plus(Duration.fromMillis(duration * 1000)),
        duration,
        price,
        quantity: formatQuantity(quantity),
        id,
        derRdfId,
      };
    }
  );
};
