import { hasKey, isNumber, isString, isUndefined } from './guards';
import { getProperty, setProperty } from './utils';

export { hasKey, isNumber, isString, isUndefined, getProperty, setProperty };

export enum BidOfferType {
  BID = 'BID',
  OFFER = 'OFFER',
}

export const isBidOfferType = (item: any): item is BidOfferType => {
  if (item === BidOfferType.BID || item === BidOfferType.OFFER) {
    return true;
  }
  return false;
};

export enum MarketType {
  DAYAHEAD = 'DAYAHEAD',
  SAMEDAY = 'SAMEDAY',
}

export enum QuantityType {
  POWER = 'POWER',
  ENERGY = 'ENERGY',
}

export enum OfferType {
  DatedTimeSeries = 'DatedTimeSeries',
  StandingPair = 'StandingPair',
  StandingTimeSeries = 'StandingTimeSeries',
}

export interface SelectOption {
  isDisabled?: boolean;
  label: string;
  value: any;
}
