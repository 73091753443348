import React, { FunctionComponent, useMemo, useCallback, useReducer } from 'react';
import { DateTime, Duration } from 'luxon';
import ReactTable, { CellInfo } from 'react-table-6';
import { useToasts } from 'react-toast-notifications';

import IconButton from 'components/IconButton';
import { MarketType, BidOfferType, QuantityType } from 'types';
import { BidOffer } from 'types/bidOffer';
import DatePicker from 'components/DatePicker';
import LocalDateTime from 'helpers/LocalDateTime';
import { EditableNumberInput } from '../BidOfferTable/renderNumberInputFactory';
import * as Actions from './Actions';
import reducer from './Reducer';
import { MarketParams, marketParamsEquals } from './State';

import 'react-table-6/react-table.css';
import './ShiftingBidsTable.scss';

const TIME_FORMAT = 'HH:mm:ss';

// (hkamal) DAYAHEAD event duration is hard-coded to 1h
const DAYAHEAD_EVENT_DURATION = Duration.fromObject({ hours: 1 });

interface ShiftingBidsTableParams {
  program: {
    currency: string;
    eventDuration: number;
  };
  data: BidOffer[];
  deleteRow: (index: number) => void;
  idPrefix: string;
  loading: boolean;
  setRow: (row: number, column: keyof BidOffer, value?: number | BidOfferType) => void;
  onSave: (offers: BidOffer[]) => void;
  timezone: string;
  assetType: string;
  marketType: MarketType;
  date: DateTime;
}

interface PageOptions {
  defaultPageSize: number;
  showPagination: boolean;
  showPageSizeOptions: boolean;
}

const getPageOptions = (data: BidOffer[]): PageOptions => {
  const pageOptions: PageOptions = {
    defaultPageSize: 12,
    showPagination: false,
    showPageSizeOptions: false,
  };

  if (data.length > 12) {
    pageOptions.showPagination = true;
  }

  return pageOptions;
};

const ShiftingBidsTable: FunctionComponent<ShiftingBidsTableParams> = ({
  program,
  data,
  deleteRow,
  idPrefix,
  loading,
  setRow,
  date,
  timezone,
  marketType,
  assetType,
  onSave,
}) => {
  const programSameDayEventDurationSeconds = program.eventDuration;
  const marketParams: MarketParams = useMemo(
    () => ({
      // (hkamal) DAYAHEAD event duration is hard-coded
      eventDuration:
        marketType === MarketType.DAYAHEAD
          ? DAYAHEAD_EVENT_DURATION
          : Duration.fromObject({ seconds: programSameDayEventDurationSeconds }),
      marketType,
    }),
    [programSameDayEventDurationSeconds, marketType]
  );

  const [state, dispatch] = useReducer(reducer, Actions.makeInitState(marketParams));
  const { addToast } = useToasts();

  // If market params or event duration have changed, clear state
  if (!marketParamsEquals(marketParams, state.marketParams)) {
    dispatch({
      type: Actions.Type.Initialize,
      marketParams,
    });
  }

  // If there are errors, dispatch them then clear errors
  if (state.errors.length > 0) {
    for (const error of state.errors) {
      addToast(error, { appearance: 'error', autoDismiss: true });
    }
    dispatch({ type: Actions.Type.ClearErrors });
  }

  const pageOptions = useMemo(() => getPageOptions(data), [data]);

  /**
   * Handles submitting shifting bids.
   */
  const handleSubmit = useMemo(
    () => () => {
      const dayStart = date.startOf('day');
      const newBidStart = dayStart.plus(state.startOffset);
      const newBidEnd = dayStart.plus(state.endOffset);
      const newBidStartMs = newBidStart.toMillis();
      const newBidEndMs = newBidEnd.toMillis();

      // check if the bid is valid relative to the other bids.
      const validBid = data.every((bid, index) => {
        if (index === 0) {
          return true;
        }

        const bidStartMs = bid.startTime.toMillis();
        const bidEndMs = bid.endTime.toMillis();

        return !(newBidEndMs > bidStartMs && bidEndMs > newBidStartMs);
      });

      if (!validBid) {
        addToast(`Two bids cannot be submitted for the same time.`, {
          appearance: 'error',
          autoDismiss: true,
        });
        return;
      }

      if (state.price === undefined) {
        addToast(`Please enter a price.`, {
          appearance: 'error',
          autoDismiss: true,
        });
        return;
      }

      if (state.quantity === undefined) {
        addToast(`Please enter a quantity.`, {
          appearance: 'error',
          autoDismiss: true,
        });
        return;
      }

      onSave([
        {
          id: undefined,
          bidOfferType: BidOfferType.BID,
          quantityType:
            marketType === 'DAYAHEAD' && assetType === 'Load (Shifting)'
              ? QuantityType.ENERGY
              : QuantityType.POWER,
          marketType,
          startTime: newBidStart,
          endTime: newBidEnd,
          duration: (newBidEnd.toMillis() - newBidStart.toMillis()) / 1000,
          price: state.price,
          quantity: state.quantity,
        },
      ]);

      // Reset state
      dispatch({
        type: Actions.Type.Initialize,
        marketParams,
      });
    },
    [date, data, state, addToast, onSave, marketType, assetType, marketParams]
  );

  /**
   * Renders the start time column contents.
   */
  const renderStartTime = useMemo(
    () => (cellInfo: CellInfo) => {
      const { value, row } = cellInfo;
      if (row._index === 0) {
        return (
          <span style={{ display: 'flex', justifyContent: 'center' }}>
            <DatePicker
              date={LocalDateTime.fromOffset(state.startOffset).datetime}
              onChange={(d: DateTime) =>
                dispatch({
                  type: Actions.Type.SetStartOffset,
                  pickedDateTime: new LocalDateTime(d),
                })
              }
              options={{ enableTime: true, noCalendar: true, time_24hr: true }}
              showArrows={false}
              dateFormat="H:i"
              useUTC={false}
            />
          </span>
        );
      }
      return <span>{value}</span>;
    },
    [state]
  );

  /**
   * Renders the end time column contents.
   */
  const renderEndTime = useMemo(
    () => (cellInfo: CellInfo) => {
      const { value, row } = cellInfo;
      if (row._index === 0) {
        return (
          <span style={{ display: 'flex', justifyContent: 'center' }}>
            <DatePicker
              date={LocalDateTime.fromOffset(state.endOffset).datetime}
              onChange={(d: DateTime) =>
                dispatch({
                  type: Actions.Type.SetEndOffset,
                  pickedDateTime: new LocalDateTime(d),
                })
              }
              options={{ enableTime: true, noCalendar: true, time_24hr: true }}
              showArrows={false}
              dateFormat="H:i"
              useUTC={false}
            />
          </span>
        );
      }
      return <span>{value}</span>;
    },
    [state]
  );

  const updatePrice = useCallback(
    (price?: number) =>
      dispatch({
        type: Actions.Type.SetPrice,
        price,
      }),
    []
  );

  /**
   * Renders the price column contents.
   */
  const renderPriceInput = useMemo(
    () => (cellInfo: CellInfo) => {
      if (cellInfo.row._index === 0) {
        return (
          <EditableNumberInput
            isDisabled={false}
            cellInfo={cellInfo}
            data={data}
            idPrefix={idPrefix}
            setRow={setRow}
            updateValue={updatePrice}
          />
        );
      }
      return <span>{cellInfo.value}</span>;
    },
    [data, idPrefix, setRow, updatePrice]
  );

  const updateQuantity = useCallback(
    (quantity?: number) =>
      dispatch({
        type: Actions.Type.SetQuantity,
        quantity,
      }),
    []
  );

  /**
   * Renders the quantity column contents.
   */
  const renderQuantityInput = useMemo(
    () => (cellInfo: CellInfo) => {
      if (cellInfo.row._index === 0) {
        return (
          <EditableNumberInput
            isDisabled={false}
            cellInfo={cellInfo}
            data={data}
            idPrefix={idPrefix}
            setRow={setRow}
            updateValue={updateQuantity}
          />
        );
      }
      return <span>{cellInfo.value}</span>;
    },
    [data, idPrefix, setRow, updateQuantity]
  );

  /**
   * Handles Rendering the delete or submit buttons.
   */
  const renderActions = useMemo(
    () => (cellInfo: CellInfo) => {
      const { row, index } = cellInfo;
      if (row._index === 0) {
        const canSubmit = state.price !== undefined && state.quantity !== undefined;

        return (
          <IconButton
            iconClassName={canSubmit ? 'shifting-bids-table-submit' : ''}
            disabled={!canSubmit}
            icon="add_circle"
            onClick={handleSubmit}
            tooltip="Submit"
          />
        );
      }
      return (
        <IconButton
          iconClassName="shifting-bids-table-delete"
          icon="cancel"
          onClick={() => deleteRow(index)}
          tooltip="Delete"
        />
      );
    },
    [deleteRow, handleSubmit, state]
  );

  const { currency } = program;
  const quantityHeader =
    marketType === MarketType.DAYAHEAD && assetType.includes('Load (Shifting)')
      ? 'Quantity (MWh)'
      : 'Quantity (MW)';

  const columns = useMemo(
    () => [
      {
        Header: `Start time (${timezone})`,
        id: 'startTime',
        accessor: (d: any) => d.startTime.toFormat(TIME_FORMAT),
        Cell: renderStartTime,
      },
      {
        Header: `End time (${timezone})`,
        id: 'endTime',
        accessor: (d: any) => d.endTime.toFormat(TIME_FORMAT),
        Cell: renderEndTime,
      },
      {
        Header: `Price (${currency}/MWh)`,
        accessor: 'price',
        Cell: renderPriceInput,
      },
      {
        Header: quantityHeader,
        accessor: 'quantity',
        Cell: renderQuantityInput,
      },
      {
        Header: 'Action',
        Cell: renderActions,
        width: 100,
      },
    ],
    [
      renderStartTime,
      renderEndTime,
      renderActions,
      renderPriceInput,
      renderQuantityInput,
      timezone,
      currency,
      quantityHeader,
    ]
  );

  return (
    <ReactTable
      className="-striped"
      columns={columns}
      data={data}
      loading={loading}
      {...pageOptions}
    />
  );
};

export default ShiftingBidsTable;
