import { ITooltipItem } from 'chart.js';
import React, { useContext, useEffect, useState, FunctionComponent } from 'react';
import { DateTime } from 'luxon';

import ChartWrapper from 'components/ChartWrapper';
import MetricsRibbon from 'components/MetricsRibbon';
import Select from 'components/Select';
import Tabs from 'components/Tabs';
import { ProgramsContext } from 'contexts/ProgramsContext';
import useLocaleFormatter from 'hooks/useLocaleFormatters';

import CALENDAR_MONTHS from '../../../helpers/months';
import './AssetInfo.scss';

const PAST_YEAR_LIMIT = 50;
const NOW = DateTime.local();
const YEARS = [...Array(PAST_YEAR_LIMIT).keys()].map((i) => ({
  value: NOW.year - i,
  label: (NOW.year - i).toString(),
}));

const getDateRangeForYearMonth = (year: number, month: number) => {
  const startDate = DateTime.local(year, month + 1, 1).startOf('month');
  const endDate = startDate.endOf('month');
  return { startDate, endDate };
};

type MarketPEStats = {
  count: number;
  max: number;
  sum: number;
};

type DailyEnergyAggregation = {
  committedEnergy: number;
  dayAhead: MarketPEStats;
  generatedEnergy: number;
  sameDay: MarketPEStats;
};

type DailyPricingEventAggregation = {
  dayAhead: number;
  sameDay: number;
};

type ReportTabProps = {
  assetEventsByDay: DailyEnergyAggregation[];
  assetID: string;
  assetPricingEvents: DailyPricingEventAggregation[];
  getDERPricingEvents: (assetID: string, startDate: DateTime, endDate: DateTime) => void;
};

const AssetInfoReportTab: FunctionComponent<ReportTabProps> = ({
  assetEventsByDay,
  assetID,
  assetPricingEvents,
  getDERPricingEvents,
}) => {
  const { programs } = useContext(ProgramsContext);
  const { currencyFormatter } = useLocaleFormatter(programs[0]?.currency, programs[0]?.locale);

  const [month, setMonth] = useState(NOW.month - 1);
  const [year, setYear] = useState(NOW.year);

  // Replaces onDateChange and componentDidMount
  useEffect(() => {
    const { startDate, endDate } = getDateRangeForYearMonth(year, month);
    getDERPricingEvents(assetID, startDate, endDate);
  }, [assetID, getDERPricingEvents, month, year]);

  const createLMPDTimeGraph = (rawData: DailyEnergyAggregation[]) => {
    const { startDate, endDate } = getDateRangeForYearMonth(year, month);

    let t = startDate;
    const ticks = [];

    while (t < endDate) {
      ticks.push(t.valueOf());
      t = t.plus({ days: 1 });
    }

    return (
      <div className="my-assets-graph-container lmpd-graph-container">
        <ChartWrapper
          type="line"
          data={{
            datasets: [
              {
                backgroundColor: '#FFC222',
                borderColor: '#FFC222',
                borderWidth: 2,
                data: ticks
                  .map((timestamp, i) => ({
                    x: timestamp,
                    y:
                      rawData[i] && rawData[i].dayAhead
                        ? rawData[i].dayAhead.sum / rawData[i].dayAhead.count
                        : null,
                  }))
                  .filter((pt) => pt.y !== null && !isNaN(pt.y)),
                fill: false,
                label: 'Day-Ahead Daily Average',
              },
              {
                backgroundColor: '#78A22F',
                borderColor: '#78A22F',
                borderWidth: 2,
                data: ticks
                  .map((timestamp, i) => ({
                    x: timestamp,
                    y: rawData[i] && rawData[i].dayAhead ? rawData[i].dayAhead.max : null,
                  }))
                  .filter((pt) => pt.y !== null && !isNaN(pt.y)),
                fill: false,
                label: 'Day-Ahead Daily Max',
              },
              {
                backgroundColor: '#E64097',
                borderColor: '#E64097',
                borderWidth: 2,
                data: ticks
                  .map((timestamp, i) => ({
                    x: timestamp,
                    y:
                      rawData[i] && rawData[i].sameDay
                        ? rawData[i].sameDay.sum / rawData[i].sameDay.count
                        : null,
                  }))
                  .filter((pt) => pt.y !== null && !isNaN(pt.y)),
                fill: false,
                label: 'Same-Day Daily Average',
              },
              {
                backgroundColor: '#F78F1E',
                borderColor: '#F78F1E',
                borderWidth: 2,
                data: ticks
                  .map((timestamp, i) => ({
                    x: timestamp,
                    y: rawData[i] && rawData[i].sameDay ? rawData[i].sameDay.max : null,
                  }))
                  .filter((pt) => pt.y !== null && !isNaN(pt.y)),
                fill: false,
                label: 'Same-Day Daily Max',
              },
            ],
          }}
          options={{
            maintainAspectRatio: false,
            responsive: true,
            elements: {
              line: {
                tension: 0,
              },
            },
            scales: {
              x: {
                type: 'time',
                scaleLabel: {
                  display: true,
                  labelString: 'Date',
                },
                min: startDate.valueOf(),
                max: endDate.valueOf(),
              },
              y: {
                suggestedMin: 0,
                suggestedMax: 1, // Ensure that we at last show to $1
                gridLines: {
                  drawBorder: false,
                },
                scaleLabel: {
                  display: true,
                  labelString: `${programs[0]?.currency}/MWh`,
                },
                ticks: {
                  callback: (value: any) => `${value.toFixed(1)}`,
                },
              },
            },
            tooltips: {
              intersect: false,
              mode: 'index',
              callbacks: {
                title: (tooltipItems: ITooltipItem[]) => {
                  let title = '';
                  if (tooltipItems.length > 0) {
                    const { dataPoint } = tooltipItems[0];
                    const dateTime = DateTime.fromMillis(dataPoint.x);
                    title = dateTime.toFormat('DDD');
                  }

                  return title;
                },
                label: (tooltipItem: ITooltipItem) => {
                  const { dataPoint, dataset } = tooltipItem;
                  return `${dataset.label}: ${currencyFormatter.format(dataPoint.y)}/MWh`;
                },
              },
            },
          }}
        />
      </div>
    );
  };

  const createGenerationTimeGraph = (rawData: DailyEnergyAggregation[]) => {
    const { startDate, endDate } = getDateRangeForYearMonth(year, month);

    let t = startDate;
    const ticks = [];

    while (t < endDate) {
      ticks.push(t.valueOf());
      t = t.plus({ days: 1 });
    }

    return (
      <div className="my-assets-graph-container generation-graph-container">
        <ChartWrapper
          type="line"
          data={{
            datasets: [
              {
                backgroundColor: '#78A22F',
                borderColor: '#78A22F',
                borderWidth: 2,
                data: ticks
                  .map((timestamp, i) => ({
                    x: timestamp,
                    y: rawData[i] ? rawData[i].committedEnergy : null,
                  }))
                  .filter((pt) => pt.y !== null && !isNaN(pt.y)),
                fill: false,
                label: 'Committed Generation',
              },
              {
                backgroundColor: '#F78F1E',
                borderColor: '#F78F1E',
                borderWidth: 2,
                data: ticks
                  .map((timestamp, i) => ({
                    x: timestamp,
                    y: rawData[i] ? rawData[i].generatedEnergy : null,
                  }))
                  .filter((pt) => pt.y !== null && !isNaN(pt.y)),
                fill: false,
                label: 'Actual Generation',
              },
            ],
          }}
          options={{
            maintainAspectRatio: false,
            responsive: true,
            elements: {
              line: {
                tension: 0,
              },
            },
            scales: {
              x: {
                type: 'time',
                scaleLabel: {
                  display: true,
                  labelString: 'Date',
                },
                min: startDate.valueOf(),
                max: endDate.valueOf(),
              },
              y: {
                suggestedMin: 0,
                suggestedMax: 1, // Ensure that we at last show to $1
                gridLines: {
                  drawBorder: false,
                },
                scaleLabel: {
                  display: true,
                  labelString: 'MWh',
                },
                ticks: {
                  callback: (value: number) => value.toLocaleString(),
                },
              },
            },
            tooltips: {
              intersect: false,
              mode: 'index',
              callbacks: {
                title: (tooltipItems: ITooltipItem[]) => {
                  let title = '';
                  if (tooltipItems.length > 0) {
                    const { dataPoint } = tooltipItems[0];
                    const dateTime = DateTime.fromMillis(dataPoint.x);
                    title = dateTime.toFormat('DDD');
                  }

                  return title;
                },
                label: (tooltipItem: ITooltipItem) => {
                  const { dataPoint, dataset } = tooltipItem;
                  let value = dataPoint.y;
                  value = Math.round(value * 100) / 100; // Get 2 decimal places
                  return `${dataset.label}: ${value.toLocaleString()} MWh`;
                },
              },
            },
          }}
        />
      </div>
    );
  };

  // LMP+D Pricing, max and average by hour, exclude 0s
  let maxSD = 0;
  let maxDA = 0;
  let averageSD = 0;
  let averageDA = 0;
  let nonZeroSD = 0;
  let nonZeroDA = 0;

  for (let i = 0; i < assetPricingEvents.length; i += 1) {
    const pe = assetPricingEvents[i];

    const sameDay = pe.sameDay || 0;
    const dayAhead = pe.dayAhead || 0;

    if (sameDay) {
      maxSD = Math.max(maxSD, sameDay);
      averageSD += sameDay;
      nonZeroSD += sameDay && 1;
    }
    if (dayAhead) {
      maxDA = Math.max(maxDA, dayAhead);
      averageDA += dayAhead;
      nonZeroDA += dayAhead && 1;
    }
  }
  averageSD /= nonZeroSD || 1;
  averageDA /= nonZeroDA || 1;

  // Actual (G) & Committed (C) Generation, min, max, avg by day, exclude 0s
  let minG = 0;
  let maxG = 0;
  let avgG = 0;
  let nonZeroG = 0;
  let minC = 0;
  let maxC = 0;
  let avgC = 0;
  let nonZeroC = 0;

  for (let i = 0; i < assetEventsByDay.length; i += 1) {
    const dayOfPEs = assetEventsByDay[i];
    const committedEnergy = dayOfPEs.committedEnergy || 0;
    const generatedEnergy = dayOfPEs.generatedEnergy || 0;

    if (generatedEnergy) {
      minG = Math.min(minG, generatedEnergy);
      maxG = Math.max(maxG, generatedEnergy);
      avgG += generatedEnergy;
      nonZeroG += 1;
    }

    if (committedEnergy) {
      minC = Math.min(minC, committedEnergy);
      maxC = Math.max(maxC, committedEnergy);
      avgC += committedEnergy;
      nonZeroC += 1;
    }
  }

  avgG /= nonZeroG || 1;
  avgC /= nonZeroC || 1;

  return (
    <div className="my-assets-tab-container">
      <div className="asset-row__first">
        <div className="asset-header">Asset Performance</div>
        <div className="graph-date-container">
          <Select
            options={CALENDAR_MONTHS}
            value={CALENDAR_MONTHS[month]}
            onChange={(e: any) => setMonth(e.value)}
            className="month-selector"
            id="month-selector"
            isClearable={false}
          />
          <Select
            options={YEARS}
            value={YEARS[NOW.year - year]}
            onChange={(e: any) => setYear(e.value)}
            className="year-selector"
            id="year-selector"
            isClearable={false}
          />
        </div>
      </div>
      <Tabs
        tabs={[
          {
            name: 'Price',
            disabled: false,
            permissionTipOptions: null,
          },
          {
            name: 'Generation',
            disabled: false,
            permissionTipOptions: null,
          },
        ]}
      >
        {(TabPanel) => [
          <TabPanel className="lmpd-tab" key="lmpd">
            <MetricsRibbon
              metrics={[
                {
                  label: 'Average Day-Ahead',
                  value: currencyFormatter.format(averageDA),
                  unit: '/MWh',
                },
                {
                  label: 'Maximum Day-Ahead',
                  value: currencyFormatter.format(maxDA),
                  unit: '/MWh',
                },
              ]}
            />
            <MetricsRibbon
              metrics={[
                {
                  label: 'Average Same-Day',
                  value: currencyFormatter.format(averageSD),
                  unit: '/MWh',
                },
                {
                  label: 'Maximum Same-Day',
                  value: currencyFormatter.format(maxSD),
                  unit: '/MWh',
                },
              ]}
            />
            {createLMPDTimeGraph(assetEventsByDay)}
          </TabPanel>,
          <TabPanel className="generation-tab" key="generation">
            <MetricsRibbon
              metrics={[
                {
                  label: 'Min Committed',
                  value: `${minC.toFixed(2)}`,
                  unit: 'MWh',
                },
                {
                  label: 'Max Committed',
                  value: `${maxC.toFixed(2)}`,
                  unit: 'MWh',
                },
                {
                  label: 'Average Committed',
                  value: `${avgC.toFixed(2)}`,
                  unit: 'MWh',
                },
              ]}
            />
            <MetricsRibbon
              metrics={[
                {
                  label: 'Min Generation',
                  value: `${minG.toFixed(2)}`,
                  unit: 'MWh',
                },
                {
                  label: 'Max Generation',
                  value: `${maxG.toFixed(2)}`,
                  unit: 'MWh',
                },
                {
                  label: 'Average Generation',
                  value: `${avgG.toFixed(2)}`,
                  unit: 'MWh',
                },
              ]}
            />
            {createGenerationTimeGraph(assetEventsByDay)}
          </TabPanel>,
        ]}
      </Tabs>
    </div>
  );
};

export default AssetInfoReportTab;
