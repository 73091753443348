import React, { useMemo, useState, FunctionComponent, ReactChild } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuthContext } from '@opusonesolutions/gridos-app-framework';

import Breadcrumbs from 'components/Breadcrumbs';
import { BreadcrumbParent } from 'components/Breadcrumbs/Breadcrumbs';
import IconButton from 'components/IconButton';

import './HeaderNavigationBar.scss';

type HeaderNavigationProps = {
  actions: {
    setMenuState: (open: boolean) => void;
  };
  breadcrumbParents: BreadcrumbParent[];
  children: ReactChild;
  menuOpen: boolean;
  title: string;
};

const HeaderNavigationBar: FunctionComponent<HeaderNavigationProps> = ({
  actions,
  breadcrumbParents = [],
  children,
  menuOpen,
  title,
}) => {
  const history = useHistory();
  const { logout, user } = useAuthContext();
  const [profileMenu, setProfileMenu] = useState(false);

  const toggleProfileMenu = useMemo(
    () => () => {
      setProfileMenu((p) => !p);
    },
    []
  );

  const handleProfileClick = useMemo(
    () => () => {
      // If the menu is open, ensure it is closed
      actions.setMenuState(false);
      history.push('/profile');
    },
    [actions, history]
  );

  return (
    <>
      <div className="header-navigation-bar">
        <IconButton
          className="nav-menu-btn"
          icon="menu"
          onClick={() => actions.setMenuState(!menuOpen)}
          tooltip="Menu"
        />
        <div className="center-container">
          <Breadcrumbs parents={breadcrumbParents} separator="/" currentHeader={title} />
          <div className="header-container">{children}</div>
        </div>
        <div className="dropdown-menus">
          <div className="profile-menu dropdown-menu-container">
            <button
              className={`dropdown-menu-selector ${
                profileMenu ? 'dropdown-menu-selector--active' : ''
              }`}
              onClick={() => toggleProfileMenu()}
            >
              <i className="material-icons">account_circle</i>
            </button>
            <div className={`dropdown-menu ${profileMenu ? 'dropdown-menu--active' : ''}`}>
              <div className="dropdown-menu-header">
                <p className="user-email">{user.email}</p>
              </div>

              <button className="dropdown-menu-item" onClick={() => handleProfileClick()}>
                My Profile
              </button>

              <button
                className="dropdown-menu-item"
                onClick={() => window.open('/GridOS TEMS MPI User Guide V1.0.pdf', '_blank')}
              >
                Help
              </button>

              <button
                className="dropdown-menu-item"
                onClick={() =>
                  window.open(
                    'https://opusonesolutions.atlassian.net/servicedesk/customer/portal/8',
                    '_blank'
                  )
                }
              >
                Report an issue
              </button>

              <button
                id="profile-menu-logout"
                className="dropdown-menu-item"
                onClick={() => logout()}
              >
                LOGOUT
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderNavigationBar;
