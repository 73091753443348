import { useMemo } from 'react';

export function getCurrencySymbol(formatter: Intl.NumberFormat) {
  const parts = formatter.formatToParts(0);

  let symbol = '';
  let i;

  for (i = 0; i < parts.length; i += 1) {
    const part = parts[i];
    if (part.type === 'currency') {
      symbol = part.value;
      break;
    }
  }

  return symbol;
}

export default function useLocaleFormatter(currency = 'USD', locale = 'en-US') {
  const currencyFormatter = useMemo(() => {
    return new Intl.NumberFormat(locale, { style: 'currency', currency });
  }, [currency, locale]);
  const numberFormatter = useMemo(() => {
    return new Intl.NumberFormat(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }, [locale]);
  const currencySymbol = useMemo(() => {
    return getCurrencySymbol(currencyFormatter);
  }, [currencyFormatter]);

  return { currencyFormatter, currencySymbol, numberFormatter };
}
