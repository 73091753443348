import React from 'react';
import { Link } from 'react-router-dom';
import { useAuthContext } from '@opusonesolutions/gridos-app-framework';

import Button from 'components/Button';

import './Login.scss';

const Login = () => {
  const { isAuthenticated, isLoading, login } = useAuthContext();

  return (
    <div className="login-container">
      {!isLoading && !isAuthenticated && (
        <div className="center-modal">
          <div className="logo-container">
            <img src="/gridos_darkgrey_250px.svg" alt="gridos-logo" className="gridos-logo" />
          </div>
          <div className="contents-container">
            <Button className="login-btn" onClick={() => login()}>
              Login
            </Button>
          </div>
          <div className="contents-container">
            <p>Don&apos;t have an account?</p>
            <Link to="/register">Register</Link>
          </div>
        </div>
      )}
      {(isLoading || isAuthenticated) && (
        <div className="contents-container">
          <p className="login-text">Loading</p>
        </div>
      )}
    </div>
  );
};
export default Login;
