import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { Request } from '@opusonesolutions/gridos-app-framework';

import CancelEventsModal from 'components/CancelEventsModal';
import DatePicker from 'components/DatePicker';
import IconButton from 'components/IconButton';
import PricingEventsList from 'components/PricingEventsList';
import HeaderNavigationBar from 'components/HeaderNavigationBar';
import LMPChartPanel from 'components/LMPChartPanel';
import SimpleSlider from 'components/SimpleSlider';
import { ProgramsContext } from 'contexts/ProgramsContext';

import ACTIVE_STATES from 'helpers/activeStates';
import fileExportSave from 'helpers/downloadFile';
import marketTypes from 'helpers/marketTypes';

import './SameDayMarket.scss';
import * as VIEW_MARKET_FINANCIALS from '../../../helpers/permissions';

class SameDayMarket extends PureComponent {
  state = {
    downloading: false,
    threshold: 0,
    currentDate: DateTime.local().startOf('day'),
    showCancelModal: false,
    eventToCancel: null,
  };

  handleTimezone = (date) => {
    const timezone = this.context.getTimezone();
    return date.setZone(timezone);
  };

  UNSAFE_componentWillMount() {
    this.props.actions.getPricingEvents(this.handleTimezone(this.state.currentDate));
    this.props.actions.getDERs();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { pricingEvents } = nextProps.events;

    if (pricingEvents !== this.props.events.pricingEvents) {
      // Format the pricing events to display grouped by timestamp
      this.props.actions.processPricingEvents(
        pricingEvents,
        [marketTypes.SAME_DAY],
        this.state.threshold
      );
    }
  }

  updatePricingEventThreshold = (threshold) => {
    this.props.actions.processPricingEvents(
      this.props.events.pricingEvents,
      [marketTypes.SAME_DAY],
      threshold
    );
  };

  handleDateChange = (date) => {
    if (this.state.currentDate.toISO() !== date.toISO()) {
      this.props.actions.getPricingEvents(this.handleTimezone(date));
      this.props.actions.getDERs();
      this.setState({ currentDate: this.handleTimezone(date) });
    }
  };

  canEditTimestamp = (isTimestampOpen, events) => {
    if (!isTimestampOpen || !events || !events.length) {
      return false;
    }
    const startTime = DateTime.fromISO(events[0].start_time);
    // All events are editable up until the event is over
    return DateTime.local() <= startTime;
  };

  askForCancellationReason = (event) => {
    this.setState({
      showCancelModal: true,
      eventToCancel: event,
    });
  };

  closeCancellationModal = () => {
    this.setState({ showCancelModal: false });
  };

  downloadPricingEventReport = async () => {
    this.setState({ downloading: true });

    const req = new Request('/api/mpi/pricing_event/report');

    const { data, headers } = await req.get({
      params: {
        market_types: ['SAMEDAY'],
        from_start_time: this.state.currentDate.startOf('day').toISO(),
        to_start_time: this.state.currentDate.endOf('day').toISO(),
      },
      headers: {
        'Cache-Control': 'no-cache, no-store',
        Pragma: 'no-cache',
        Expires: '0',
      },
      responseType: 'blob',
    });
    fileExportSave(data, headers);

    this.setState({ downloading: false });
  };

  handleStatusChange = (event, status) => {
    if (ACTIVE_STATES.includes(event.status)) {
      this.askForCancellationReason(event);
    } else {
      this.props.actions.updatePricingEventStatus(event, this.state.currentDate, status);
    }
  };

  render() {
    const sameday = marketTypes.SAME_DAY.toLowerCase();
    const currency = this.context.programs[0]?.currency || 'USD';
    const currencyFormatter = new Intl.NumberFormat(this.context.programs[0]?.locale || 'en-US', {
      style: 'currency',
      currency,
    });

    return (
      <div className="same-day-market">
        <HeaderNavigationBar title="Same Day Market">
          <div className="date-picker-wrapper">
            <DatePicker onChange={this.handleDateChange} date={this.state.currentDate} />
          </div>
        </HeaderNavigationBar>
        {(!this.props.authEnabled ||
          this.props.permissions.has(VIEW_MARKET_FINANCIALS.default)) && (
          <LMPChartPanel
            currency={currency}
            currencyFormatter={currencyFormatter}
            date={this.state.currentDate}
            marketType={sameday}
            derLookup={this.props.DERLookup}
            pricingEventsByDER={this.props.events.pricingEventsByDER}
          />
        )}
        <div className="same-day-controls">
          <h4>Events</h4>
          <div className="right-controls">
            <IconButton
              active
              className="download-button"
              disabled={this.state.downloading}
              icon="get_app"
              onClick={() => this.downloadPricingEventReport()}
              tooltip="Download pricing event report"
            />
            <div className="threshold-container">
              <p>{`${currencyFormatter.format(this.state.threshold)}/MWh`}</p>
              <div className="slider-container">
                <h4>Price Filter</h4>
                <SimpleSlider
                  defaultValue={this.state.threshold}
                  domain={[
                    Math.min(this.props.events.minUnitPrice, 0),
                    Math.max(this.props.events.maxUnitPrice, 1),
                  ]}
                  onChange={this.updatePricingEventThreshold}
                  onUpdate={(e) => this.setState({ threshold: e })}
                  railType="thin"
                  railCorners="square"
                  trackColor="#D8D8D8"
                  railColor="rgba(0, 121, 193, 0.6)"
                  step={
                    (Math.round(
                      (this.props.events.maxUnitPrice - this.props.events.minUnitPrice) / 1000
                    ),
                    1)
                  }
                />
              </div>
            </div>
          </div>
        </div>
        {this.state.showCancelModal && (
          <CancelEventsModal
            closeCancellationModal={this.closeCancellationModal}
            eventToCancel={this.state.eventToCancel}
            currentDate={this.state.currentDate}
            updatePricingEventStatus={this.props.actions.updatePricingEventStatus}
          />
        )}
        <div className="same-day-contents">
          <PricingEventsList
            currency={currency}
            marketType={sameday}
            events={this.props.events.pricingEventsByTimestamp}
            DERLookup={this.props.DERLookup}
            eventDate={this.state.currentDate}
            pricingEventsReq={this.props.events.pricingEventsReq}
            showResponseWidget
            onStatusChange={this.handleStatusChange}
            canEditTimestamp={this.canEditTimestamp}
            updateEventReq={this.props.events.updateEventReq}
            onAssetClick={(id) => this.props.history.push(`/assets/${id}`)}
          />
        </div>
      </div>
    );
  }
}

SameDayMarket.contextType = ProgramsContext;
SameDayMarket.contextTypes = {
  programs: PropTypes.array,
  getTimezone: PropTypes.func,
};

SameDayMarket.propTypes = {
  actions: PropTypes.object.isRequired,
  events: PropTypes.object.isRequired,
  DERLookup: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  permissions: PropTypes.object.isRequired,
  authEnabled: PropTypes.bool.isRequired,
};

export default SameDayMarket;
