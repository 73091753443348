import { DateTime, Duration } from 'luxon';
import { useCallback, useMemo, useEffect, useState } from 'react';

import {
  BidOfferType,
  QuantityType,
  MarketType,
  isBidOfferType,
  isNumber,
  isUndefined,
} from 'types';
import { BidOffer } from 'types/bidOffer';

const MAX_DATETIME = DateTime.fromMillis(864000000000000);
const SECONDS_IN_DAY = 86400;

const getBlankSinglePair = (marketType: MarketType, assetType: string) => {
  const local = DateTime.local();

  const start = local.startOf('day');
  const duration = Duration.fromMillis(SECONDS_IN_DAY * 1000);

  const defaultBidOfferType =
    assetType === 'Load (Shifting)' ? BidOfferType.BID : BidOfferType.OFFER;
  let defaultQuantityType = QuantityType.POWER;
  if (marketType.localeCompare('DAYAHEAD') === 0 && assetType === 'Load (Shifting)') {
    defaultQuantityType = QuantityType.ENERGY;
  }

  return {
    id: undefined,
    bidOfferType: defaultBidOfferType,
    quantityType: defaultQuantityType,
    marketType,
    startTime: start,
    endTime: start.plus(duration),
    duration: SECONDS_IN_DAY,
    price: undefined,
    quantity: undefined,
  };
};

const getBlankOffers = (
  eventDuration: number,
  marketType: MarketType,
  timezone: string,
  assetType: string,
  date?: DateTime
): BidOffer[] => {
  const dateWithZone = (date && date.setZone(timezone)) || DateTime.local().setZone(timezone);

  const start = dateWithZone.startOf('day');
  const end = dateWithZone.endOf('day');

  const duration = Duration.fromMillis(eventDuration * 1000);

  if (assetType === 'Load (Shifting)') {
    return [];
  }

  const blanks = [];
  for (let current = start; current < end; current = current.plus(duration)) {
    blanks.push({
      id: undefined,
      bidOfferType: BidOfferType.OFFER,
      quantityType: QuantityType.POWER,
      marketType,
      startTime: current,
      endTime: current.plus(duration),
      duration: eventDuration,
      price: undefined,
      quantity: undefined,
    });
  }

  return blanks;
};

const interleaveOffers = (sorted: BidOffer[], blanks: BidOffer[]): BidOffer[] => {
  const interleaved: BidOffer[] = [];

  let sortedIdx = 0;
  blanks.forEach((blank) => {
    const { startTime: blankStartTime, endTime: blankEndTime } = blank;
    const { startTime: sortedStartTime, endTime: sortedEndTime } = sorted[sortedIdx] || {
      startTime: MAX_DATETIME,
      endTime: MAX_DATETIME,
    };

    if (blankStartTime < sortedStartTime || blankEndTime < sortedEndTime) {
      interleaved.push(blank);
    } else {
      interleaved.push(sorted[sortedIdx]);
      sortedIdx += 1;
    }
  });

  return interleaved;
};

const isValidBidOfferType = (column: keyof BidOffer, value: any): boolean =>
  column === 'bidOfferType' && isBidOfferType(value);

const isValidPrice = (column: keyof BidOffer, value: any): boolean =>
  column === 'price' && (isNumber(value) || isUndefined(value));

const isValidQuantity = (column: keyof BidOffer, value: any): boolean =>
  column === 'quantity' && (isNumber(value) || isUndefined(value));

const isValidMutation = (column: keyof BidOffer, value: any): boolean =>
  isValidBidOfferType(column, value) ||
  isValidPrice(column, value) ||
  isValidQuantity(column, value);

interface UseSinglePairOptions {
  marketType: MarketType;
  offers: BidOffer[];
  assetType: string;
}

export const useSinglePair = ({ marketType, offers, assetType }: UseSinglePairOptions) => {
  const getOrCreateSinglePair = useCallback(
    () =>
      offers.find(({ duration }) => duration === SECONDS_IN_DAY) ||
      getBlankSinglePair(marketType, assetType),
    [marketType, offers, assetType]
  );

  const [singlePair, setSinglePair] = useState(getOrCreateSinglePair());

  useEffect(() => {
    setSinglePair(getOrCreateSinglePair());
  }, [getOrCreateSinglePair]);

  const deletePair = useMemo(() => () => setSinglePair(getBlankSinglePair(marketType, assetType)), [
    marketType,
    assetType,
  ]);

  const setPair = useMemo(
    () => (column: keyof BidOffer, value?: BidOfferType | number) => {
      if (singlePair && isValidMutation(column, value)) {
        setSinglePair({ ...singlePair, [column]: value });
      }
    },
    [singlePair]
  );

  return {
    deletePair,
    setPair,
    singlePair,
  };
};

interface UseTimeSeriesOptions {
  date?: DateTime;
  eventDuration: number;
  marketType: MarketType;
  offers: BidOffer[];
  timezone: string;
  assetType: string;
}

export const useTimeSeries = ({
  date,
  eventDuration,
  marketType,
  offers,
  timezone,
  assetType,
}: UseTimeSeriesOptions) => {
  const getOrCreateTimeSeries = useCallback(() => {
    if (assetType === 'Load (Shifting)') {
      return [
        {
          id: undefined,
          bidOfferType: BidOfferType.BID,
          quantityType: marketType === 'SAMEDAY' ? QuantityType.POWER : QuantityType.ENERGY,
          marketType,
          startTime: DateTime.local().startOf('day'),
          endTime: DateTime.local().startOf('day').plus(eventDuration),
          duration: eventDuration,
          price: undefined,
          quantity: undefined,
        },
        ...offers.filter(({ duration }) => duration <= SECONDS_IN_DAY),
      ];
    }

    return interleaveOffers(
      offers.filter(({ duration }) => duration !== SECONDS_IN_DAY),
      getBlankOffers(eventDuration, marketType, timezone, assetType, date)
    );
  }, [date, eventDuration, marketType, offers, timezone, assetType]);
  const [timeSeries, setTimeSeries] = useState(getOrCreateTimeSeries());

  useEffect(() => {
    setTimeSeries(getOrCreateTimeSeries());
  }, [getOrCreateTimeSeries]);

  const deleteRow = useMemo(
    () => (row: number) => {
      if (timeSeries[row]) {
        const idx = timeSeries.findIndex((offer) => Object.is(offer, timeSeries[row]));
        timeSeries[idx] = {
          ...timeSeries[row],
          id: undefined,
          price: undefined,
          quantity: undefined,
        };
      }
    },
    [timeSeries]
  );

  const setRow = useMemo(
    () => (row: number, column: keyof BidOffer, value?: number | BidOfferType) => {
      if (timeSeries[row] && isValidMutation(column, value)) {
        const idx = timeSeries.findIndex((offer) => Object.is(offer, timeSeries[row]));
        timeSeries[idx] = { ...timeSeries[row], [column]: value };
      }
    },
    [timeSeries]
  );

  return {
    deleteRow,
    setRow,
    timeSeries,
  };
};
