import _ from 'lodash';

export function hasKey<T>(item: T, key: any): key is keyof T {
  return Object.prototype.hasOwnProperty.call(item, key);
}

export const isNumber = (item: any): item is number => {
  return _.isNumber(item);
};

export const isString = (item: any): item is string => {
  return _.isString(item);
};

export const isUndefined = (item: any): item is undefined => {
  return _.isUndefined(item);
};
