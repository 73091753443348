import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import FormField from './FormField';
import validateRequiredFields from '../helpers/requiredFieldValidation';

class AssetGeneralInfo extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isValid: true,
    };
    this.requiredFields = [
      'assetName',
      'assetType',
      'manufacturer',
      'model',
      'communicationCapacity',
    ];
  }

  isValidated() {
    const isValid = validateRequiredFields(this.props, this.requiredFields);
    this.setState({ isValid });
    return isValid;
  }

  render() {
    return (
      <div>
        <div className="registration-title">Asset General Info</div>
        <form name="asset-general-info">
          {this.state.isValid || (
            <div className="registration-info">
              All fields marked with <span className="required-field">*</span> are required.
            </div>
          )}

          <FormField
            name="assetName"
            label="Asset Name"
            value={this.props.assetName}
            isRequired
            onChange={(e) => this.props.onFieldChange(e)}
          />

          <FormField
            name="assetType"
            label="Asset Type"
            value={this.props.assetType}
            isRequired
            onChange={(e) => this.props.onFieldChange(e)}
          />

          <FormField
            name="manufacturer"
            label="Manufacturer"
            value={this.props.manufacturer}
            isRequired
            onChange={(e) => this.props.onFieldChange(e)}
          />

          <FormField
            name="model"
            label="Model"
            value={this.props.model}
            isRequired
            onChange={(e) => this.props.onFieldChange(e)}
          />

          <FormField
            name="communicationCapacity"
            label="Communication Capacity"
            value={this.props.communicationCapacity}
            isRequired
            onChange={(e) => this.props.onFieldChange(e)}
          />
        </form>
      </div>
    );
  }
}

AssetGeneralInfo.propTypes = {
  onFieldChange: PropTypes.func.isRequired,
  assetName: PropTypes.string.isRequired,
  assetType: PropTypes.string.isRequired,
  manufacturer: PropTypes.string.isRequired,
  model: PropTypes.string.isRequired,
  communicationCapacity: PropTypes.string.isRequired,
};

export default AssetGeneralInfo;
