import React from 'react';

import { MarketType } from 'types';

import StandingOffer from './StandingOffer';
import DatetimeOffer from './DatetimeOffer';
import ShiftingBids from './ShiftingBids';

import './BidOfferSection.scss';

type BidOfferSectionProps = {
  assetID: string;
  assetInfo: {
    der_type: string;
    operating_mode: string;
    usage_point_type: string;
  };
  program: {
    currency: string;
    eventDuration: number;
  };
  marketType: MarketType;
  title: string;
};

const BidOfferSection = ({
  assetID,
  assetInfo,
  program,
  marketType,
  title,
}: BidOfferSectionProps) => {
  const {
    operating_mode: operatingMode,
    der_type: assetType,
    usage_point_type: UsagePointType,
  } = assetInfo;
  const { currency } = program;

  /**
   * Handles rendering the special bid tables for shifting assets.
   */
  function renderShiftingBidsTable() {
    return (
      <>
        <ShiftingBids
          isStanding={true}
          assetID={assetID}
          assetType={assetType}
          marketType={marketType}
          program={program}
        />
        <ShiftingBids
          isStanding={false}
          assetID={assetID}
          assetType={assetType}
          marketType={marketType}
          program={program}
        />
      </>
    );
  }

  /**
   * Handles rendering the standard bid offer tables.
   */
  function renderStandardBidOfferTables() {
    return (
      <>
        <StandingOffer
          allowSinglePair={assetType !== 'BESS'}
          assetID={assetID}
          assetType={assetType}
          currency={currency}
          disableBessOffers={operatingMode === 'UNCONSTRAINED'}
          marketType={marketType}
          showOfferSelector={assetType === 'BESS'}
        />
        <DatetimeOffer
          allowSinglePair={assetType !== 'BESS'}
          assetID={assetID}
          assetType={assetType}
          currency={currency}
          disableBessOffers={operatingMode === 'UNCONSTRAINED'}
          marketType={marketType}
        />
      </>
    );
  }

  return (
    <div className="bid-offer-section">
      <div className="title">{title}</div>
      {UsagePointType && UsagePointType.localeCompare('Shifting') === 0
        ? renderShiftingBidsTable()
        : renderStandardBidOfferTables()}
    </div>
  );
};

export default BidOfferSection;
