function validateRequiredField(fields, fieldName) {
  return fields[fieldName].trim() !== '';
}

function flagMissingRequiredFields(fields, requiredFieldNames) {
  return requiredFieldNames.filter((fieldName) => !validateRequiredField(fields, fieldName));
}

function validateRequiredFields(fields, requiredFieldNames) {
  return flagMissingRequiredFields(fields, requiredFieldNames).length === 0;
}

export default validateRequiredFields;
