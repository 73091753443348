function parsePricingEvent(event) {
  const duration = parseInt(event.duration, 10); // Duration is always in seconds
  const u = parseFloat(event.unit_price);
  const realPower = parseFloat(event.power_required);
  const reactivePower = parseFloat(event.reactive_power_required);
  const p = realPower / 1000000;
  const d = event.power_delivered === 'None' ? 0 : parseFloat(event.power_delivered) / 1000000;
  return {
    ...event,
    powerDeliveredMW: d,
    powerRequiredMW: p,
    realPower,
    reactivePower,
    totalPrice: u * p * (duration / 3600),
    unit_price: u,
  };
}

export default parsePricingEvent;
