import { createStore, applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import rootReducer from './rootReducer';

export default function configureStore(preloadedState) {
  const middlewares = [thunk];

  if (process.env.NODE_ENV !== 'production') {
    const logger = createLogger({
      collapsed: true,
    });
    middlewares.push(logger);
  }
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const storeEnhancers = [middlewareEnhancer];

  const composedEnhancer = compose(...storeEnhancers);

  const store = createStore(rootReducer, preloadedState, composedEnhancer);

  return store;
}
