import React from 'react';
import { useHistory } from 'react-router-dom';

import Button from 'components/Button';

const NoEnrolledAssets = () => {
  const history = useHistory();

  return (
    <div className="events-placeholder">
      <div className="events-message">
        <h2>No Enrolled Assets, yet.</h2>
        <p>You don&#39;t have assets enrolled in the market.</p>
        <p>Start the application to participate.</p>
      </div>
      <Button
        onClick={() => history.push('/assets/register')}
        className="enroll-asset-btn"
        label="Enroll New Asset"
      />
      <img
        className="events-placeholder__img"
        src="/no_enrolled_asset.svg"
        alt="No Enrolled Asset"
      />
    </div>
  );
};

export default NoEnrolledAssets;
