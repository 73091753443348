import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Checkbox from 'components/Checkbox';

import FormField from './FormField';
import validateRequiredFields from '../helpers/requiredFieldValidation';

class InvoiceInfo extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isValid: true,
    };
    this.requiredFields = [
      'name',
      'phoneNumber',
      'line1',
      'city',
      'administrativeArea',
      'country',
      'postalCode',
      'customerAccountId',
    ];
  }

  isValidated() {
    const isValid = validateRequiredFields(this.props, this.requiredFields);
    this.setState({ isValid });
    return isValid;
  }

  render() {
    return (
      <div>
        <div className="registration-title">Invoice Information</div>

        {this.state.isValid || (
          <div className="registration-info">
            All fields marked with <span className="required-field">*</span> are required.
          </div>
        )}

        <form name="invoice-info">
          <div className="checkbox-container">
            <Checkbox
              checked={this.props.invoiceContactSameAsPrimary}
              backgroundColor="#FFF"
              checkColor="#0079C1"
              borderColor="#d1d1d1"
              onClick={() =>
                this.props.onCopyPrimaryContact({
                  target: {
                    name: 'invoiceContactSameAsPrimary',
                    value: !this.props.invoiceContactSameAsPrimary ? 'on' : 'off',
                  },
                })
              }
              name="invoiceContactSameAsPrimary"
            />

            <label htmlFor="invoiceContactSameAsPrimary">Same as primary contact</label>
          </div>

          <div className="column-container">
            <div className="left-column">
              <FormField
                name="name"
                label="Name"
                value={this.props.name}
                isRequired
                onChange={(e) => this.props.onFieldChange(e)}
              />
            </div>
            <div className="right-column">
              <FormField
                name="customerAccountId"
                label="Customer Account ID"
                value={this.props.customerAccountId}
                isRequired
                onChange={(e) => this.props.onFieldChange(e)}
              />
            </div>
          </div>

          <FormField
            name="phoneNumber"
            label="Phone Number"
            value={this.props.phoneNumber}
            isRequired
            onChange={(e) => this.props.onFieldChange(e)}
          />

          <FormField
            name="line1"
            label="Street Address"
            value={this.props.line1}
            placeholder="Street Name and Number"
            isRequired
            onChange={(e) => this.props.onFieldChange(e)}
          />

          <FormField
            name="line2"
            label=""
            placeholder="Suite, unit, building, floor, etc."
            value={this.props.line2}
            onChange={(e) => this.props.onFieldChange(e)}
          />

          <div className="column-container">
            <div className="left-column">
              <FormField
                name="city"
                label="City"
                value={this.props.city}
                isRequired
                onChange={(e) => this.props.onFieldChange(e)}
              />

              <FormField
                name="administrativeArea"
                label="State/Province"
                value={this.props.administrativeArea}
                isRequired
                onChange={(e) => this.props.onFieldChange(e)}
              />
            </div>
            <div className="right-column">
              <FormField
                name="country"
                label="Country"
                value={this.props.country}
                isRequired
                onChange={(e) => this.props.onFieldChange(e)}
              />

              <FormField
                name="postalCode"
                label="Zip/Postal Code"
                value={this.props.postalCode}
                isRequired
                onChange={(e) => this.props.onFieldChange(e)}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

InvoiceInfo.propTypes = {
  onFieldChange: PropTypes.func.isRequired,
  onCopyPrimaryContact: PropTypes.func.isRequired,
  invoiceContactSameAsPrimary: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  customerAccountId: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  line1: PropTypes.string.isRequired,
  line2: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  administrativeArea: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  postalCode: PropTypes.string.isRequired,
};

export default InvoiceInfo;
