import { DateTime } from 'luxon';

import { MarketType } from 'types';
import { Program } from 'types/program';

const parseTimestamp = (timestamp: string, timezone?: string): DateTime => {
  if (timezone) {
    return DateTime.fromISO(timestamp, { zone: timezone });
  }
  return DateTime.fromISO(timestamp, { setZone: true });
};

enum StateType {
  Accepted = 'Accepted',
  Active = 'Active',
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  Confirmed = 'Confirmed',
  Countered = 'Countered',
  Expired = 'Expired',
  Incomplete = 'Incomplete',
  Pending = 'Pending',
  Rejected = 'Rejected',
}

export interface EventState {
  changedBy: string;
  reason: string;
  stateType: StateType;
  time: DateTime;
}

export const toState = (data: any, timezone?: string): EventState => {
  const { changed_by: changedBy, reason, state_type: stateType, time } = data;
  return {
    changedBy,
    reason,
    stateType,
    time: parseTimestamp(time, timezone),
  };
};

enum SettlementStatus {
  Paid = 'Paid',
  NotVerified = 'Not Verified',
  VerifiedDelivered = 'Verified Delivered',
  VerifiedUnderdelivered = 'Verified Underdelivered',
}

export interface Event {
  createdTime: DateTime;
  derRdfId: string;
  duration: number;
  expirationTime: DateTime;
  feederRdfId: string;
  financialModelLog: Record<string, unknown>;
  id: number;
  marketType: MarketType;
  powerDelivered?: number;
  powerRequired: number;
  program: Program;
  programId: number;
  settlementStatus: SettlementStatus;
  startTime: DateTime;
  state: EventState;
  unitPrice: number;
}

export const toEvent = (data: any, timezone?: string): Event => {
  const {
    created_time: createdTime,
    der_rdf_id: derRdfId,
    duration,
    expiration_time: expirationTime,
    feeder_rdf_id: feederRdfId,
    financial_model_log: financialModelLog,
    id,
    market_type: marketType,
    power_delivered: powerDelivered,
    power_required: powerRequired,
    program,
    program_id: programId,
    settlement_status: settlementStatus,
    start_time: startTime,
    state,
    unit_price: unitPrice,
  } = data;

  return {
    createdTime: parseTimestamp(createdTime, timezone),
    derRdfId,
    duration,
    expirationTime: parseTimestamp(expirationTime, timezone),
    feederRdfId,
    financialModelLog,
    id,
    marketType,
    powerDelivered: powerDelivered !== 'None' ? parseFloat(powerDelivered) : undefined,
    powerRequired: parseFloat(powerRequired),
    program,
    programId,
    settlementStatus,
    startTime: parseTimestamp(startTime, timezone),
    state: toState(state),
    unitPrice: parseFloat(unitPrice),
  };
};
