import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import FormField from './FormField';
import validateRequiredFields from '../helpers/requiredFieldValidation';

class PrimaryContact extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isValid: true,
    };
    this.requiredFields = [
      'name',
      'email',
      'phoneNumber',
      'line1',
      'city',
      'administrativeArea',
      'country',
      'postalCode',
    ];
  }

  isValidated() {
    const isValid = validateRequiredFields(this.props, this.requiredFields);
    this.setState({ isValid });
    return isValid;
  }

  render() {
    return (
      <div className="registration">
        <div className="registration-title">Primary Contact</div>

        {this.state.isValid || (
          <div className="registration-info">
            All fields marked with <span className="required-field">*</span> are required.
          </div>
        )}

        <form name="primary-contact">
          <FormField
            name="name"
            label="Name"
            value={this.props.name}
            isRequired
            onChange={(e) => this.props.onFieldChange(e)}
          />

          <FormField
            name="email"
            label="Email"
            value={this.props.email}
            isRequired
            onChange={(e) => this.props.onFieldChange(e)}
          />

          <FormField
            name="phoneNumber"
            label="Phone Number"
            value={this.props.phoneNumber}
            isRequired
            onChange={(e) => this.props.onFieldChange(e)}
          />

          <FormField
            name="line1"
            label="Street Address"
            value={this.props.line1}
            placeholder="Street Name and Number"
            isRequired
            onChange={(e) => this.props.onFieldChange(e)}
          />

          <FormField
            name="line2"
            label=""
            value={this.props.line2}
            placeholder="Suite, unit, building, floor, etc."
            onChange={(e) => this.props.onFieldChange(e)}
          />

          <div className="column-container">
            <div className="left-column">
              <FormField
                name="city"
                label="City"
                value={this.props.city}
                isRequired
                onChange={(e) => this.props.onFieldChange(e)}
              />

              <FormField
                name="administrativeArea"
                label="State/Province"
                value={this.props.administrativeArea}
                isRequired
                onChange={(e) => this.props.onFieldChange(e)}
              />
            </div>
            <div className="right-column">
              <FormField
                name="country"
                label="Country"
                value={this.props.country}
                isRequired
                onChange={(e) => this.props.onFieldChange(e)}
              />

              <FormField
                name="postalCode"
                label="Zip/Postal Code"
                value={this.props.postalCode}
                isRequired
                onChange={(e) => this.props.onFieldChange(e)}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

PrimaryContact.propTypes = {
  onFieldChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  line1: PropTypes.string.isRequired,
  line2: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  administrativeArea: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  postalCode: PropTypes.string.isRequired,
};

export default PrimaryContact;
