import { DateTime } from 'luxon';
import { Request } from '@opusonesolutions/gridos-app-framework';

import asyncActionStates from 'helpers/asyncActionStates';
import parsePricingEvent from '../helpers/parsePricingEvent';

const GET_PRICING_EVENTS_LOADING = 'GET_PRICING_EVENTS_LOADING';
const GET_PRICING_EVENTS_SUCCESS = 'GET_PRICING_EVENTS_SUCCESS';
const GET_PRICING_EVENTS_FAILURE = 'GET_PRICING_EVENTS_FAILURE';
const PROCESS_PRICING_EVENTS = 'PROCESS_PRICING_EVENTS';
const SUMMARIZE_PRICING_EVENTS = 'SUMMARIZE_PRICING_EVENTS';
const UPDATE_EVENT_LOADING = 'UPDATE_EVENT_LOADING';
const UPDATE_EVENT_SUCCESS = 'UPDATE_EVENT_SUCCESS';
const UPDATE_EVENT_FAILURE = 'UPDATE_EVENT_FAILURE';

function getPricingEvents(startDate, endDate) {
  return (dispatch) => {
    const start = encodeURIComponent(startDate.startOf('day').toISO());
    const end = encodeURIComponent((endDate || startDate).endOf('day').toISO());

    const request = new Request(
      `/api/mpi/pricing_event?from_start_time=${start}&to_start_time=${end}`
    );
    dispatch({
      type: GET_PRICING_EVENTS_LOADING,
      request,
    });

    // Get a list of all pricing events.
    return request
      .get()
      .then(({ data }) => {
        const updatedEvents = data.map((event) => {
          const instance = event;
          instance.status = event.state ? event.state.state_type : 'Not Available';
          return instance;
        });

        dispatch({
          type: GET_PRICING_EVENTS_SUCCESS,
          payload: updatedEvents,
          status: asyncActionStates.SUCCESS,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_PRICING_EVENTS_FAILURE,
          payload: error,
          status: asyncActionStates.ERROR,
        });
      });
  };
}

function groupEventsByDER(marketEvents) {
  const events = {};
  let maxUnitPrice = 0;
  let minUnitPrice = 0;

  marketEvents.forEach((event) => {
    const nEvent = parsePricingEvent(event);
    maxUnitPrice = Math.max(maxUnitPrice, nEvent.unit_price);
    minUnitPrice = Math.min(minUnitPrice, nEvent.unit_price);

    if (!events[event.der_rdf_id]) {
      events[event.der_rdf_id] = [];
    }

    events[event.der_rdf_id].push(event);
  });

  return { events, maxUnitPrice, minUnitPrice };
}

function groupEventsByTimestamp(marketEvents) {
  const eventsByTimestamp = {};

  marketEvents.forEach((event) => {
    const timestamp = DateTime.fromISO(event.start_time).valueOf();
    const nEvent = parsePricingEvent(event);

    if (!eventsByTimestamp[timestamp]) {
      eventsByTimestamp[timestamp] = [];
    }

    eventsByTimestamp[timestamp].push(nEvent);
  });

  return eventsByTimestamp;
}

function processPricingEvents(pricingEvents, marketTypes, threshold = 0) {
  // Process only events of the selected market type and that are over the given threshold
  const marketEvents = pricingEvents.filter(
    (event) => marketTypes.includes(event.market_type) && event.unit_price >= threshold
  );
  const { events, maxUnitPrice, minUnitPrice } = groupEventsByDER(marketEvents);
  const eventsByTimestamp = groupEventsByTimestamp(marketEvents);
  return {
    type: PROCESS_PRICING_EVENTS,
    events,
    eventsByTimestamp,
    maxUnitPrice,
    minUnitPrice,
  };
}

function summarizePricingEvents(pricingEvents) {
  const confirmedEvents = pricingEvents.filter(
    (event) => event.status === 'Confirmed' && event.market_type === 'SAMEDAY'
  );

  const committedEvents = pricingEvents.filter(
    (event) =>
      (event.status === 'Completed' || event.status === 'Active') && event.market_type === 'SAMEDAY'
  );

  const committedPerTimestamp = groupEventsByTimestamp(committedEvents);
  const confirmedPerTimestamp = groupEventsByTimestamp(confirmedEvents);

  return {
    type: SUMMARIZE_PRICING_EVENTS,
    confirmedEvents: confirmedPerTimestamp,
    committedEvents: committedPerTimestamp,
  };
}

function updatePricingEventStatus(event, date, status, reason) {
  return (dispatch) => {
    const updateReason = reason || `Manual change to ${status}.`;
    const { id } = event;
    dispatch({ type: UPDATE_EVENT_LOADING, id });
    const req = new Request(`/api/mpi/pricing_event/${id}`);
    const stateUpdate = {
      state_type: status,
      reason: updateReason,
    };
    return req
      .patch(stateUpdate)
      .then(async () => {
        await getPricingEvents(date)(dispatch);
        return dispatch({ type: UPDATE_EVENT_SUCCESS, id });
      })
      .catch((error) => dispatch({ type: UPDATE_EVENT_FAILURE, error, id }));
  };
}

export const actions = {
  getPricingEvents,
  processPricingEvents,
  summarizePricingEvents,
  updatePricingEventStatus,
};

const initialState = {
  pricingEventsReq: 0,
  pendingPricingEventsReq: null,
  pricingEvents: [],
  errors: {},
  pricingEventsByDER: {},
  pricingEventsByTimestamp: {},
  maxUnitPrice: 0,
  minUnitPrice: 0,
  updateEventReq: {},
};

export default function PricingEventsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PRICING_EVENTS_LOADING:
      if (state.pendingPricingEventsReq) {
        state.pendingPricingEventsReq.cancel('Cancelled due to navigation');
      }
      return {
        ...state,
        pricingEvents: [],
        pricingEventsReq: asyncActionStates.LOADING,
        pendingPricingEventsReq: action.request,
      };
    case GET_PRICING_EVENTS_SUCCESS:
      return {
        ...state,
        pricingEventsReq: action.status,
        pricingEvents: action.payload,
        pendingPricingEventsReq: null,
      };
    case GET_PRICING_EVENTS_FAILURE:
      return {
        ...state,
        pricingEventsReq: action.status,
        pendingPricingEventsReq: null,
        errors: { pricingEventsReq: action.payload },
      };
    case PROCESS_PRICING_EVENTS:
      return {
        ...state,
        pricingEventsByDER: action.events,
        pricingEventsByTimestamp: action.eventsByTimestamp,
        maxUnitPrice: action.maxUnitPrice,
        minUnitPrice: action.minUnitPrice,
      };
    case SUMMARIZE_PRICING_EVENTS:
      return {
        ...state,
        confirmedEvents: action.confirmedEvents,
        committedEvents: action.committedEvents,
      };
    case UPDATE_EVENT_LOADING:
      return {
        ...state,
        updateEventReq: {
          ...state.updateEventReq,
          [action.id]: asyncActionStates.LOADING,
        },
      };
    case UPDATE_EVENT_SUCCESS:
      return {
        ...state,
        updateEventReq: {
          ...state.updateEventReq,
          [action.id]: asyncActionStates.SUCCESS,
        },
      };
    case UPDATE_EVENT_FAILURE:
      return {
        ...state,
        updateEventReq: {
          ...state.updateEventReq,
          [action.id]: asyncActionStates.ERROR,
        },
        errors: { ...state.errors, updateEventReq: action.error },
      };
    default:
      return state;
  }
}
