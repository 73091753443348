import classNames from 'classnames';
import React, { ChangeEvent, FocusEvent, FormEvent, FunctionComponent } from 'react';

import './SimpleNumberInput.scss';

const inputClassName = 'simple-number-input';

const handleValue = (value?: number): number | string => {
  if (!value && value !== 0) {
    return '';
  }
  return value;
};

interface FormOptions {
  id: string;
  name: string;
  required: boolean;
}

interface SimpleNumberInputProps {
  id: string;

  className?: string;
  disabled?: boolean;
  labelText?: string;
  unit?: string;

  max?: number;
  min?: number;
  step?: number;
  value?: number;

  formOptions?: FormOptions;

  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onInput?: (event: FormEvent<HTMLInputElement>) => void;
}

/**
 * SimpleNumberInput is a simple wrapper on a number input,
 * its label (with optional text), and optional text for
 * showing units. Parent components are expected to handle
 * state changes by passing event handlers.
 */
const SimpleNumberInput: FunctionComponent<SimpleNumberInputProps> = ({
  id,
  className,
  disabled,
  labelText,
  unit,
  max,
  min,
  step,
  value,
  formOptions,
  onBlur,
  onChange,
  onInput,
  onFocus,
}) => {
  const { id: formId, name, required } = formOptions || {};

  return (
    <label htmlFor={id} className={`${inputClassName}__label`}>
      <span className={`${inputClassName}-label-text`}>{labelText}</span>
      <input
        id={id}
        className={classNames(`${inputClassName}__input`, className)}
        disabled={disabled || false}
        type="number"
        max={max}
        min={min}
        step={step}
        value={handleValue(value)}
        form={formId}
        name={name}
        required={required || false}
        onBlur={onBlur}
        onChange={onChange}
        onInput={onInput}
        onFocus={onFocus}
      />
      <span className={`${inputClassName}-unit`}>{unit}</span>
    </label>
  );
};

export default SimpleNumberInput;
