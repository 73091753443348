import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import { ProgramsContext } from 'contexts/ProgramsContext';
import eventStates from './eventStates';

const ACTIVE_STATUSES = [eventStates.CONFIRMED, eventStates.ACTIVE, eventStates.COMPLETED];
const OFFER_STATUSES = [eventStates.PENDING, eventStates.ACCEPTED, eventStates.COUNTERED];

// Creates a single event row on the pricing events page
class EventRow extends Component {
  /**
   * Creates the element for the status section.
   * @return {JSX}              Element for status column
   */
  getSettlementStatus = (status) => {
    return (
      <div
        className={`pricing-event-settlement pricing-event-settlement--${status
          .replace(' ', '_')
          .toLowerCase()}`}
      >
        <span>{status}</span>
      </div>
    );
  };

  /**
   * Creates the element for the status section.
   * @param  {String}  status   Current event status
   * @return {JSX}              Element for status column
   */
  getStatus = (status) => {
    return (
      <div className={`pricing-event-status pricing-event-status--${status.toLowerCase()}`}>
        <span>{status}</span>
      </div>
    );
  };

  getTotalPrice = (totalPrice, status, currencyFormatter) => (
    <div className="pricing-events-event-cell cell-sm">
      <p className={ACTIVE_STATUSES.includes(status) ? 'committed-value' : null}>
        {currencyFormatter.format(totalPrice)}
      </p>
    </div>
  );

  /**
   * Creates the element to display kW (market participation)
   * @param   {Float} kw
   * @return  {JSX}
   */
  getPowerRequiredKW = (kw, numberFormatter) => {
    return (
      <div className="pricing-events-event-cell cell-md">
        <p>{numberFormatter.format(kw)}</p>
      </div>
    );
  };

  render() {
    const {
      DERLookup,
      event,
      onStatusChange,
      eventLoading,
      showAccept,
      showResponseWidget,
      canEditTimestamp,
      onAssetClick,
    } = this.props;

    const showConfirm = showAccept && OFFER_STATUSES.includes(event.status) && event.realPower > 0;
    const confirmButtonText = event.status === eventStates.PENDING ? 'Accept' : 'Confirm';

    const showWidget =
      showResponseWidget && event.status === eventStates.PENDING && canEditTimestamp;

    const showCancelWidget =
      showResponseWidget &&
      (event.status === eventStates.ACTIVE ||
        event.status === eventStates.CONFIRMED ||
        event.status === eventStates.ACCEPTED);

    const currencyFormatter = new Intl.NumberFormat(this.context.programs[0]?.locale || 'en-US', {
      style: 'currency',
      currency: this.context.programs[0]?.currency || 'USD',
    });
    const numberFormatter = new Intl.NumberFormat(this.context.programs[0]?.locale || 'en-US');
    return (
      <div className="pricing-events-event-row">
        <div className="pricing-events-event-cell cell-sm">
          <div className="names">
            {/* DER Name */}
            <div className="der-name">
              {!onAssetClick && (
                <p>
                  {DERLookup && DERLookup[event.der_rdf_id] ? DERLookup[event.der_rdf_id].name : ''}
                </p>
              )}
              {onAssetClick && (
                <button
                  className="pricing-events-asset__btn"
                  onClick={() => onAssetClick(event.der_rdf_id)}
                  type="button"
                >
                  <p>
                    {DERLookup && DERLookup[event.der_rdf_id]
                      ? DERLookup[event.der_rdf_id].name
                      : ''}
                  </p>
                </button>
              )}
            </div>
            {/* Program Name */}
            {event.program_id && (
              <div className="program-name">{this.context.getProgram(event.program_id)?.name}</div>
            )}
          </div>
        </div>

        {/* Unit Price */}
        <div className="pricing-events-event-cell cell-sm">
          <p>{currencyFormatter.format(event.unit_price)}</p>
        </div>

        {this.getTotalPrice(event.totalPrice, event.status, currencyFormatter)}

        {this.getPowerRequiredKW(event.realPower / 1000, numberFormatter)}
        {this.getPowerRequiredKW(event.reactivePower / 1000, numberFormatter)}

        {/* Status */}
        <div className="pricing-events-event-cell cell-md">
          {this.getStatus(event.status)}
          {showConfirm && (
            <Button
              className="pricing-event-accept__btn"
              type="secondary"
              onClick={() => onStatusChange(event)}
              loading={eventLoading}
            >
              <span>{confirmButtonText}</span>
            </Button>
          )}
          {showCancelWidget && (
            <div className="response-widget">
              <button
                className="response-btn response-btn--reject"
                onClick={() => onStatusChange(event)}
                disabled={eventLoading}
                title="Cancel"
                type="button"
              >
                {eventLoading ? (
                  <i className="fa fa-spinner fa-pulse fa-1x fa-fw" />
                ) : (
                  <i className="material-icons">close</i>
                )}
              </button>
            </div>
          )}
          {showWidget && (
            <div className="response-widget">
              <button
                className="response-btn response-btn--reject"
                onClick={() => onStatusChange(event, eventStates.REJECTED)}
                disabled={eventLoading}
                title="Reject"
                type="button"
              >
                {eventLoading ? (
                  <i className="fa fa-spinner fa-pulse fa-1x fa-fw" />
                ) : (
                  <i className="material-icons">close</i>
                )}
              </button>
              <button
                className="response-btn response-btn--accept"
                onClick={() => onStatusChange(event, eventStates.ACCEPTED)}
                disabled={eventLoading}
                title="Accept"
                type="button"
              >
                {eventLoading ? (
                  <i className="fa fa-spinner fa-pulse fa-1x fa-fw" />
                ) : (
                  <i className="material-icons">check</i>
                )}
              </button>
            </div>
          )}
        </div>

        {/* Settlement Status */}
        <div className="pricing-events-event-cell cell-md cell-hide-mobile">
          {event.status === eventStates.COMPLETED &&
            this.getSettlementStatus(event.settlement_status)}
        </div>
      </div>
    );
  }
}

EventRow.contextType = ProgramsContext;
EventRow.contextTypes = {
  getProgram: PropTypes.func,
  programs: PropTypes.array,
};

EventRow.defaultProps = {
  DERLookup: {},
  eventLoading: false,
  showResponseWidget: false,
  canEditTimestamp: false,
  onAssetClick: null,
};

EventRow.propTypes = {
  DERLookup: PropTypes.object,
  event: PropTypes.object.isRequired,
  onStatusChange: PropTypes.func.isRequired,
  eventLoading: PropTypes.bool,
  showAccept: PropTypes.bool.isRequired,
  showResponseWidget: PropTypes.bool,
  canEditTimestamp: PropTypes.bool,
  onAssetClick: PropTypes.func,
};

export default EventRow;
