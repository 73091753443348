import { isNumber } from 'lodash';
import React from 'react';
import { CellInfo } from 'react-table-6';

import IconButton from 'components/IconButton';
import { BidOffer } from 'types/bidOffer';

const renderBidOfferDeleteFactory = (data: BidOffer[], deleteRow: (row: number) => void) => {
  const renderBidOfferDelete = (cellInfo: CellInfo) => {
    let disabled = true;
    const { index } = cellInfo;

    const row = data[index];
    if (row && isNumber(row.id)) {
      disabled = false;
    }

    return (
      <IconButton disabled={disabled} icon="cancel" onClick={() => deleteRow(index)} tooltip="" />
    );
  };

  return renderBidOfferDelete;
};

export default renderBidOfferDeleteFactory;
