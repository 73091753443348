import React from 'react';
import PropTypes from 'prop-types';

const Tick = ({ tick, count, format }) => (
  <div className="simple-slider-tick">
    <div className="simple-slider-tick-line" style={{ left: `${tick.percent}%` }} />
    <div
      className="simple-slider-tick-value"
      style={{
        marginLeft: `${-(100 / count) / 2}%`,
        width: `${100 / count}%`,
        left: `${tick.percent}%`,
      }}
    >
      {format(tick.value)}
    </div>
  </div>
);

Tick.propTypes = {
  tick: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired,
  }).isRequired,
  count: PropTypes.number.isRequired,
  format: PropTypes.func,
};

Tick.defaultProps = {
  format: (d) => d,
};

export default Tick;
