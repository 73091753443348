import React from 'react';
import PropTypes from 'prop-types';

import './Checkbox.scss';

/**
 * Creates a custom styled controlled checked checkbox
 * background color and unchecked border color can be customized
 */
const CustomCheckbox = (props) => {
  const showCustomBG = !props.disabled && props.checked && props.backgroundColor;
  const showCustomBorder = !props.disabled && !props.checked && props.borderColor;
  return (
    <div
      className={`custom-checkbox
        ${props.disabled ? 'custom-checkbox--disabled' : ''}
        ${props.className}
        ${props.checked ? `custom-checkbox--checked ${props.className}--checked` : ''}
      `}
    >
      <input
        className="check-input"
        type="checkbox"
        tabIndex={props.focusable ? null : -1}
        value={props.value}
        id={props.id}
        readOnly
        checked={props.checked}
        onClick={props.onClick}
        disabled={props.disabled}
      />
      <label
        className="check-container"
        htmlFor={props.id}
        style={{
          backgroundColor: showCustomBG ? props.backgroundColor : null,
          borderColor: showCustomBorder ? props.borderColor : null,
        }}
      >
        {props.dash ? (
          <div className="dash" style={{ opacity: props.checked ? 1 : 0 }} />
        ) : (
          <div className="check" style={{ opacity: props.checked ? 1 : 0 }} />
        )}
      </label>
    </div>
  );
};

CustomCheckbox.defaultProps = {
  value: ' ',
  checked: false,
  id: 'checkbox',
  backgroundColor: null,
  borderColor: null,
  focusable: true,
  onClick: null,
  disabled: false,
  className: null,
  dash: false,
};

CustomCheckbox.propTypes = {
  value: PropTypes.any,
  id: PropTypes.string,
  checked: PropTypes.bool,
  backgroundColor: PropTypes.string,
  borderColor: PropTypes.string,
  focusable: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  dash: PropTypes.bool,
};

export default CustomCheckbox;
