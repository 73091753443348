import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';

import HeaderNavigationBar from 'components/HeaderNavigationBar';
import Tabs from 'components/Tabs';
import { useRequestEffect, useRequest } from '@opusonesolutions/gridos-app-framework';

import DetailTab from './DetailTab';
import MarketSettingsTab from './MarketSettingsTab';
import ReportTab from './ReportTab';

import './AssetInfo.scss';

const AssetInfo = ({ actions: { getDERPricingEvents }, assetEventsByDay, assetPricingEvents }) => {
  const history = useHistory();
  const [assetInfo, setAssetInfo] = useState(null);
  const { asset: assetID } = useParams();

  const { loading: assetLoading } = useRequestEffect({
    url: `/api/mpi/asset/${assetID}`,
    method: 'get',
    blockRequest: () => assetID === undefined,
    onSuccess: (data) => {
      setAssetInfo(data);
    },
    onError: (error) => {
      if (error.response && error.response.status === 404) {
        history.push('/assets');
      }
    },
    toast: {
      error: 'Could not load asset',
      settings: {
        autoDismiss: true,
      },
    },
  });

  const { loading: saving, makeRequest: runSave } = useRequest(`/api/mpi/der/${assetInfo?.id}`);

  const saveDER = async (updatedDER) => {
    await runSave({
      method: 'patch',
      body: updatedDER,
      toast: {
        success: 'Successfully updated DER',
        error: 'Could not update DER',
        settings: {
          autoDismiss: true,
        },
      },
    });
    setAssetInfo({
      ...assetInfo,
      ...updatedDER,
    });
  };

  let title = 'My Assets';

  if (assetID) {
    title = assetInfo ? assetInfo.name : '';
  }

  return (
    <div className="my-assets">
      <div className="my-assets-contents">
        <HeaderNavigationBar
          breadcrumbParents={[
            {
              to: '/assets',
              label: <h2 className="title">My Assets</h2>,
            },
          ]}
          title={title}
        />
        {assetID !== undefined && assetInfo !== null && (
          <Tabs
            tabs={[
              {
                name: 'Report',
                disabled: false,
                permissionTipOptions: null,
              },
              {
                name: 'Market Settings',
                disabled: false,
                permissionTipOptions: null,
              },
              {
                name: 'Details',
                disabled: false,
                permissionTipOptions: null,
              },
            ]}
          >
            {(TabPanel) => [
              <TabPanel key="report">
                <ReportTab
                  assetEventsByDay={assetEventsByDay}
                  assetID={assetID}
                  assetPricingEvents={assetPricingEvents}
                  getDERPricingEvents={getDERPricingEvents}
                />
              </TabPanel>,
              <TabPanel key="settings">
                <MarketSettingsTab
                  assetID={assetID}
                  assetInfo={assetInfo}
                  saving={saving}
                  updateDER={saveDER}
                />
              </TabPanel>,
              <TabPanel key="details">
                <DetailTab assetInfo={assetInfo} loading={assetLoading} />
              </TabPanel>,
            ]}
          </Tabs>
        )}
      </div>
    </div>
  );
};

AssetInfo.propTypes = {
  actions: PropTypes.object.isRequired,
  assetPricingEvents: PropTypes.array.isRequired,
  assetEventsByDay: PropTypes.array.isRequired,
};

export default AssetInfo;
