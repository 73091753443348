import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import '../styles/CustomerInfoPanel.scss';

class CustomerInfoPanel extends PureComponent {
  render() {
    const { email } = this.props.customer.email;

    return (
      <div className="customer-info-panel">
        <div className="customer-info-panel__header">Account Information</div>
        <div className="customer-info-panel__content">
          <label className="customer-info-panel__label" htmlFor="customer-info-email">
            Email
          </label>
          <input
            className="customer-info-panel__input"
            disabled
            id="customer-info-email"
            type="email"
            value={email}
          />
        </div>
      </div>
    );
  }
}

CustomerInfoPanel.propTypes = {
  customer: PropTypes.shape({
    email: PropTypes.shape({
      email: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default CustomerInfoPanel;
