import React, { useEffect, useState, FunctionComponent } from 'react';
import { CellInfo } from 'react-table-6';

import Select from 'components/Select';

import { hasKey, isBidOfferType, isString, getProperty, BidOfferType } from 'types';
import { BidOffer } from 'types/bidOffer';

const selectOptions = [
  {
    label: 'Bid',
    value: BidOfferType.BID as string,
  },
  {
    label: 'Offer',
    value: BidOfferType.OFFER as string,
  },
];

interface BidOfferSelectProps {
  cellInfo: CellInfo;
  data: BidOffer[];
  isDisabled: boolean;
  setRow: (row: number, column: keyof BidOffer, value?: BidOfferType | number) => void;
}

const BidOfferSelect: FunctionComponent<BidOfferSelectProps> = ({
  cellInfo,
  data,
  isDisabled,
  setRow,
}) => {
  const [value, setValue] = useState(BidOfferType.OFFER);

  const { column, index } = cellInfo;
  const { id: columnId } = column;
  const row = data[index];

  useEffect(() => {
    if (isString(columnId) && hasKey(row, columnId)) {
      const maybeBidOfferType = getProperty(row, columnId);
      if (isBidOfferType(maybeBidOfferType)) {
        setValue(maybeBidOfferType);
      }
    }
  }, [columnId, row]);

  const onChange = (e: any) => {
    const newValue = e.value;

    if (isBidOfferType(newValue)) {
      setValue(newValue);
      if (hasKey(row, columnId)) {
        setRow(index, columnId, newValue);
      }
    }
  };

  return (
    <Select
      className="bid-offer-select"
      inline
      isClearable={false}
      isDisabled={isDisabled}
      onChange={onChange}
      options={selectOptions}
      value={selectOptions.find((option) => option.value === value)}
    />
  );
};

const renderBidOfferSelectFactory = (
  isDisabled: boolean,
  data: BidOffer[],
  setRow: (row: number, column: keyof BidOffer, value?: BidOfferType | number) => void
) => (cellInfo: CellInfo) => (
  <BidOfferSelect cellInfo={cellInfo} data={data} isDisabled={isDisabled} setRow={setRow} />
);

export default renderBidOfferSelectFactory;
