import { AxiosError } from 'axios';
import { DateTime } from 'luxon';
import React, { createContext, useState, ReactNode } from 'react';
import { useRequestEffect, useAuthContext } from '@opusonesolutions/gridos-app-framework';

import { Program, toPrograms } from 'types/program';

interface ProgramsCtx {
  getSamedayEventDuration: () => number;
  getLocaleData: () => { currency: string; locale: string };
  getProgram(id?: string | number | null): Program | null;
  getTimezone: () => string;
  programs: Program[];
}

export const ProgramsContext = createContext<ProgramsCtx>({
  getSamedayEventDuration: () => 3600,
  getLocaleData: () => ({ currency: 'USD', locale: 'en-US' }),
  getTimezone: () => DateTime.local().zone.name,
  programs: [],
  getProgram: () => null,
});

export const ProgramsContextProvider = ({ children }: { children: ReactNode }) => {
  const [programs, setPrograms] = useState<Program[]>([]);
  const { isAuthEnabled, isAuthenticated } = useAuthContext();

  useRequestEffect({
    url: '/api/mpi/customer/programs',
    method: 'get',
    blockRequest: () => isAuthEnabled && !isAuthenticated,
    refetchOnChange: [isAuthEnabled, isAuthenticated],
    onSuccess: (data) => {
      if (data) {
        setPrograms(toPrograms(data));
      }
    },
    initialData: [],
    toast: {
      error: (error: AxiosError) => {
        if (error?.response?.data.message) {
          return `${error?.response?.data.message}`;
        }
        return 'Could not load program data.';
      },
      settings: {
        autoDismiss: true,
      },
    },
  });

  const getSamedayEventDuration = (): number => {
    let smallest = 3600;
    programs.forEach(({ eventDuration }) => {
      if (eventDuration < smallest) {
        smallest = eventDuration;
      }
    });
    return smallest;
  };

  const getLocaleData = () => {
    if (programs && programs[0]) {
      return {
        currency: programs[0].currency,
        locale: programs[0].locale,
      };
    }

    return {
      currency: 'USD',
      locale: 'en-US',
    };
  };

  const getProgram = (id: string | number | null | undefined) => {
    if (id !== null && typeof id !== 'undefined' && id !== '') {
      let parsedID = id;

      if (typeof id === 'string') {
        parsedID = Number.parseInt(id, 10);
      }
      const index = programs.findIndex((p) => p.id === parsedID);
      if (index !== -1) {
        return programs[index];
      }
    }
    return null;
  };

  const getTimezone = (): string => {
    if (programs && programs[0]) {
      return programs[0].timezone;
    }
    return DateTime.local().zone.name;
  };

  return (
    <ProgramsContext.Provider
      value={{
        getSamedayEventDuration,
        getLocaleData,
        getProgram,
        getTimezone,
        programs,
      }}
    >
      {children}
    </ProgramsContext.Provider>
  );
};

export const ProgramsProvider = ProgramsContextProvider;
