import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { Request } from '@opusonesolutions/gridos-app-framework';

import ChartWrapper from 'components/ChartWrapper';
import Select from 'components/Select';

import './LMPChartPanel.scss';

function NoGraphData() {
  return (
    <div className="no-graph-data">
      <div>
        <i className="material-icons">insert_chart_outlined</i>
        <h4>No graph data to display.</h4>
        <p>Please ensure ISO and Zone are selected.</p>
      </div>
    </div>
  );
}

function LMPChart(props) {
  const data = {
    datasets: props.graphData,
  };
  return (
    <div className="chart-container">
      <ChartWrapper
        type="line"
        data={data}
        options={{
          maintainAspectRatio: false,
          responsive: true,
          scales: {
            x: {
              type: 'time',
              scaleLabel: {
                display: true,
                labelString: 'Date',
              },
              min: props.date.startOf('day').valueOf(),
              max: props.date.endOf('day').valueOf(),
            },
            y: {
              suggestedMin: 0, // Ensure the range goes down to 0, but allow negative LMPs
              suggestedMax: 1, // Ensure that we at last show to $1
              gridLines: {
                drawBorder: false,
              },
              scaleLabel: {
                display: true,
                labelString: `${props.currency} / MWh`,
              },
              ticks: {
                callback: (value) => `${value.toFixed(1)}`,
              },
            },
          },
          tooltips: {
            intersect: false,
            mode: 'index',
            callbacks: {
              label: (tooltipItem) => {
                const { dataPoint, dataset } = tooltipItem;
                return `${dataset.label}: ${props.currencyFormatter.format(dataPoint.y)}/MWh`;
              },
            },
          },
        }}
      />
    </div>
  );
}

LMPChart.propTypes = {
  currency: PropTypes.string.isRequired,
  currencyFormatter: PropTypes.object.isRequired,
  date: PropTypes.instanceOf(DateTime).isRequired,
  graphData: PropTypes.array.isRequired,
};

class LMPChartPanel extends Component {
  state = {
    isosAndZones: {},
    selectedIso: null,
    selectedZone: null,
    zoneOptions: [],
    graphData: [],
  };

  componentDidMount() {
    this.fetchIsosAndZones();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.date.hasSame(this.props.date, 'day') === false ||
      prevProps.pricingEventsByDER !== this.props.pricingEventsByDER ||
      prevProps.derLookup !== this.props.derLookup
    ) {
      this._generateGraphDataAndUpdateState();
    }
  }

  // Network Requests
  async fetchIsosAndZones() {
    const request = new Request('/api/mpi/isos-zones');
    const resp = await request.get();

    // Retrieve default ISO and Zone
    if (Object.keys(resp.data).length === 0) {
      return;
    }

    const iso = Object.keys(resp.data)[0];
    const zones = resp.data[iso];

    if (zones.length === 0) {
      return;
    }

    // Set default ISO and Zone
    const selectedZone = { value: zones[0], label: zones[0].toUpperCase() };
    this.setState(
      {
        isosAndZones: resp.data,
        selectedZone,
        selectedIso: { value: iso, label: iso.toUpperCase() },
        zoneOptions: zones.map((zoneName) => ({
          value: zoneName,
          label: zoneName.toUpperCase(),
        })),
      },
      () => {
        if (selectedZone !== null) {
          this.handleZoneChange(selectedZone);
        }
      }
    );
  }

  async fetchZonalLMP(isoName, zoneName) {
    const market = this.props.marketType;
    const date = this.props.date.toFormat('yyyy-MM-dd');
    const request = new Request(`/api/mpi/iso/${isoName}/${zoneName}/lmp/${market}?date=${date}`);
    const resp = await request.get();
    const { prices } = resp.data;
    return prices;
  }

  // (Action/Event) Handlers
  handleIsoChange = (isoOptionObject) => {
    this.setState({
      selectedIso: isoOptionObject,
      selectedZone: null,
      zoneOptions: this._getZoneOptions(isoOptionObject.value),
      graphData: [],
    });
  };

  handleZoneChange = async (zoneOptionObject) => {
    this.setState(
      {
        selectedZone: zoneOptionObject,
      },
      async () => {
        await this._generateGraphDataAndUpdateState();
      }
    );
  };

  // Utility Methods
  _getZoneOptions(selectedIso) {
    return this.state.isosAndZones[selectedIso].map((zoneName) => ({
      value: zoneName,
      label: zoneName.toUpperCase(),
    }));
  }

  _getIsoOptions() {
    const isoNames = Object.keys(this.state.isosAndZones);
    return isoNames.map((isoName) => ({
      value: isoName,
      label: isoName.toUpperCase(),
    }));
  }

  async _generateGraphDataAndUpdateState() {
    if (this.state.selectedIso !== null && this.state.selectedZone !== null) {
      // Fetch Zonal LMP Data
      const zonalLMPData = await this.fetchZonalLMP(
        this.state.selectedIso.value,
        this.state.selectedZone.value
      );

      // If we have all data required, generate data for graph
      const numPEs = Array(...Object.keys(this.props.pricingEventsByDER)).length;
      const numDERs = [...Object.keys(this.props.derLookup)].length;
      if (zonalLMPData && zonalLMPData.length > 0 && numPEs && numDERs) {
        const graphData = this._generateGraphData(
          zonalLMPData,
          this.props.pricingEventsByDER,
          this.props.derLookup
        );
        this.setState({ graphData });
      } else {
        this.setState({ graphData: [] });
      }
    }
  }

  /**
   * This function is responsible for generating the Chart.js datasets array used by the
   * LMPChart component to render the graph
   *
   * Params:
   * date:      <Momemnt> (luxon object)
   * zonalLbmp: [ { start_time: ISO8601, price: float }]
   * pricingEvents: {
   *    der_rdf_id: [{ start_time: ISO8601, unit_price: float }]
   * }
   * derLookup: {
   *    der_rdf_id: {
   *      name: string
   *    }
   * }
   *
   * Returns:
   * [
   *  {
   *    data: [{ x: unix_timestamp, y: float }],
   *    label: string,
   *  }
   * ]
   */
  _generateGraphData(zonalLMP, pricingEvents, derLookup) {
    // Zonal LMP Dataset is always there
    const datasets = [
      {
        backgroundColor: '#ff7300',
        borderColor: '#ff7300',
        data: zonalLMP.map((lmp) => ({
          x: DateTime.fromISO(lmp.start_time).valueOf(),
          y: lmp.price,
        })),
        fill: false,
        label: 'LMP',
        stepped: 'before',
      },
    ];

    const colorSpacing = 360 / Object.keys(derLookup).length;
    Object.keys(derLookup).forEach((derRdfID, index) => {
      datasets.push({
        borderColor: `hsl(${index * colorSpacing}, 100%, 25%)`,
        backgroundColor: `hsl(${index * colorSpacing}, 100%, 25%)`,
        data: (pricingEvents[derRdfID] || [])
          .map((event) => ({
            x: DateTime.fromISO(event.start_time).valueOf(),
            y: event.unit_price,
          }))
          .sort((a, b) => a.x - b.x),
        fill: false,
        label: derLookup[derRdfID].name,
        stepped: 'before',
      });
    });

    return datasets;
  }

  render() {
    return (
      <div className="lmpchartpanel">
        <header>
          <h3 className="title">Market Price &amp; Asset Valuation</h3>

          <div className="iso-zone">
            <Select
              className="isos select"
              options={this._getIsoOptions()}
              placeholder="Select ISO"
              value={this.state.selectedIso}
              onChange={this.handleIsoChange}
              isClearable={false}
            />
            <Select
              className="zones select"
              options={this.state.zoneOptions}
              placeholder="Select Zone"
              isDisabled={!this.state.selectedIso}
              value={this.state.selectedZone}
              onChange={this.handleZoneChange}
              isClearable={false}
            />
          </div>
        </header>

        <div className="content">
          {this.state.graphData.length === 0 && <NoGraphData />}
          {this.state.graphData.length > 0 && (
            <LMPChart
              currency={this.props.currency}
              currencyFormatter={this.props.currencyFormatter}
              graphData={this.state.graphData}
              date={this.props.date}
            />
          )}
        </div>
      </div>
    );
  }
}

LMPChartPanel.propTypes = {
  currency: PropTypes.string.isRequired,
  currencyFormatter: PropTypes.object.isRequired,
  date: PropTypes.object.isRequired,
  derLookup: PropTypes.object.isRequired,
  pricingEventsByDER: PropTypes.object.isRequired,
  marketType: PropTypes.oneOf(['dayahead', 'sameday']).isRequired,
};

export default LMPChartPanel;
