const eventStates = {
  PENDING: 'Pending',
  ACCEPTED: 'Accepted',
  CONFIRMED: 'Confirmed',
  CANCELLED: 'Cancelled',
  ACTIVE: 'Active',
  EXPIRED: 'Expired',
  REJECTED: 'Rejected',
  COMPLETED: 'Completed',
  INCOMPLETE: 'Incomplete',
  COUNTERED: 'Countered',
};

export default eventStates;
