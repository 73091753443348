import React from 'react';
import PropTypes from 'prop-types';
import './MetricsRibbon.scss';

const MetricsRibbon = (props) => (
  <div className="metrics-ribbon">
    {props.metrics.map((m) => (
      <div className="metrics-ribbon-cell" key={`my-assets-graph-${m.label}`}>
        <div className="row">
          <span className="value">{m.value}</span>
          <span className="unit">{m.unit}</span>
        </div>
        <div className="row">
          <span className="type">{m.label}</span>
        </div>
      </div>
    ))}
  </div>
);

MetricsRibbon.propTypes = {
  metrics: PropTypes.array.isRequired,
};

export default MetricsRibbon;
