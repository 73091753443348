import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Button.scss';

const Button = (props) => (
  <button
    className={classNames(
      'custom-btn',
      `${props.type}-btn`,
      props.className,
      { 'circular-type': props.circular },
      { 'square-type': props.square }
    )}
    style={{ width: props.width, height: props.height }}
    disabled={props.disabled}
    onClick={props.onClick}
    title={props.title}
    type="button"
  >
    {props.label || props.children}
    {props.loading && <i className="fa fa-refresh fa-spin fa-1x fa-fw btn-loading" />}
  </button>
);

Button.defaultProps = {
  disabled: false,
  type: 'primary',
  width: null,
  height: null,
  loading: false,
  className: '',
  label: '',
  children: null,
  circular: false,
  square: true,
  onClick: () => {},
  title: '',
};

Button.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.node,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.node]),
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(['primary', 'secondary', 'text', 'tour']),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  loading: PropTypes.bool,
  className: PropTypes.string,
  // Button shapes
  circular: PropTypes.bool,
  square: PropTypes.bool,
  title: PropTypes.string,
};

export default Button;
