import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slider, { Ticks, Rail, Handles, Tracks } from 'react-compound-slider';
import Handle from './Handle';
import Tick from './Tick';
import Track from './Track';
import './SimpleSlider.scss';

class SimpleSlider extends Component {
  state = {
    values: this.props.defaultValue,
  };

  onUpdate = (update) => {
    this.props.onUpdate(update[0]);
  };

  onChange = (values) => {
    this.setState({ values: values[0] });
    this.props.onChange(values[0]);
  };

  // Need to return some form of JSX intside slider component due to bug in library
  generateTicks = () => {
    if (this.props.showTicks) {
      return (
        <Ticks count={this.props.tickCount} values={this.props.tickValues}>
          {({ ticks }) => (
            <div className="slider-ticks">
              {ticks.map((tick) => (
                <Tick key={tick.id} tick={tick} count={ticks.length} />
              ))}
            </div>
          )}
        </Ticks>
      );
    }
    return <div />;
  };

  render() {
    const {
      state: { values },
    } = this;

    return (
      <div className={`simple-slider ${this.props.railType} ${this.props.railCorners}`}>
        <Slider
          className="simple-slider-container"
          mode={1}
          step={this.props.step}
          domain={this.props.domain}
          onUpdate={this.onUpdate}
          onChange={this.onChange}
          values={[values]}
        >
          <Rail>
            {({ getRailProps }) => (
              <div
                className="simple-slider-rail"
                style={{ backgroundColor: this.props.railColor }}
                {...getRailProps()}
              />
            )}
          </Rail>
          <Handles>
            {({ handles, getHandleProps }) => (
              <div className="slider-handles">
                {handles.map((handle) => (
                  <Handle
                    key={handle.id}
                    handle={handle}
                    domain={this.props.domain}
                    getHandleProps={getHandleProps}
                    handleColor={this.props.handleColor}
                  />
                ))}
              </div>
            )}
          </Handles>
          <Tracks right={false}>
            {({ tracks, getTrackProps }) => (
              <div className="slider-tracks">
                {tracks.map(({ id, source, target }) => (
                  <Track
                    key={id}
                    source={source}
                    target={target}
                    getTrackProps={getTrackProps}
                    trackColor={this.props.trackColor}
                  />
                ))}
              </div>
            )}
          </Tracks>
          {this.generateTicks()}
        </Slider>
      </div>
    );
  }
}

SimpleSlider.defaultProps = {
  domain: [100, 500],
  defaultValue: 150,
  railColor: '#D8D8D8',
  handleColor: '#FFFFFF',
  trackColor: 'rgba(0, 121, 193, 0.6)',
  onChange: () => false,
  onUpdate: () => false,
  railType: 'thick',
  railCorners: 'round',
  tickCount: 5,
  tickValues: null,
  showTicks: false,
  step: 1,
};

SimpleSlider.propTypes = {
  onChange: PropTypes.func,
  onUpdate: PropTypes.func,
  domain: PropTypes.array,
  defaultValue: PropTypes.number,
  step: PropTypes.number,
  railColor: PropTypes.string,
  handleColor: PropTypes.string,
  trackColor: PropTypes.string,
  railType: PropTypes.oneOf(['thick', 'thin']),
  railCorners: PropTypes.oneOf(['square', 'round']),
  tickCount: PropTypes.number,
  tickValues: PropTypes.array,
  showTicks: PropTypes.bool,
};

export default SimpleSlider;
