import React, { useRef, useEffect, useState } from 'react';

import {
  BarController,
  Chart,
  Legend,
  Line,
  LineController,
  LinearScale,
  Point,
  Rectangle,
  TimeScale,
  Title,
  Tooltip,
} from 'chart.js';

// The default Chart.js moment wrapper does not support timezones
// so we've written our own to use luxon
import './luxon-tz-adapter';
import './ChartWrapper.scss';

// Jest cannot figure out how to import .esm.js files so this fails
// in the CI. Since the tests cannot verify the canvas anyway, this doesn't matter
if (Chart) {
  Chart.register(
    BarController,
    Legend,
    Line,
    LineController,
    LinearScale,
    Point,
    Rectangle,
    TimeScale,
    Title,
    Tooltip
  );
}

// eslint-disable-next-line react/prop-types
const ChartWrapper = ({ data, options, plugins = undefined, type }) => {
  const canvas = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);

  const createChart = () => {
    const cleanUp = () => {
      if (chartInstance !== null) {
        chartInstance.destroy();
        setChartInstance(null);
      }
    };

    if (canvas === null) {
      // Haven't captured the ref yet
      return cleanUp;
    }

    if (chartInstance !== null) {
      // Need to destroy the old chart before we recreate
      chartInstance.destroy();
    }

    const canvasDOMNode = canvas.current;
    const chart = new Chart(canvasDOMNode, {
      type,
      data,
      options,
      plugins,
    });

    setChartInstance(chart);

    // Ensure that we destroy the chart on the way out
    return cleanUp;
  };

  // If the canvas or overall chart type changes, recreate the chart. Otherwise we just update the chart
  //eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(createChart, [canvas, type, data, options, plugins]);

  return (
    <div className="chart-wrapper">
      <canvas ref={canvas} />
    </div>
  );
};

export default ChartWrapper;
