import { DateTime } from 'luxon';
import React, { useCallback, useContext, useState } from 'react';

import DatePicker from 'components/DatePicker';
import { ProgramsContext } from 'contexts/ProgramsContext';
import useOffers from 'hooks/useOffers';
import { MarketType, OfferType } from 'types';

import BidOfferButtonGroup from './BidOfferButtonGroup';
import BidOfferTable from './BidOfferTable';

import './DatetimeOffer.scss';

type DatetimeOfferProps = {
  allowSinglePair: boolean;
  assetID: string;
  currency: string;
  disableBessOffers: boolean;
  marketType: MarketType;
  assetType: string;
};

const DatetimeOffer = ({
  allowSinglePair,
  assetID,
  currency,
  disableBessOffers,
  marketType,
  assetType,
}: DatetimeOfferProps) => {
  const { getTimezone } = useContext(ProgramsContext);

  const getDateWithTz = useCallback((d: DateTime) => d.setZone(getTimezone()).startOf('day'), [
    getTimezone,
  ]);

  const [date, setDate] = useState(getDateWithTz(DateTime.local()));
  const setDateWithTz = (d: DateTime) => setDate(getDateWithTz(d));

  const {
    deleteAllOffers,
    deleteRow,
    disable,
    edited,
    loading,
    saveTimeSeries,
    setRow,
    timeSeries,
    timezone,
  } = useOffers({
    assetID,
    assetType,
    marketType,
    offerType: OfferType.DatedTimeSeries,
    date,
  });

  const isDeletable = !!timeSeries.find((singleTimeSeries) =>
    singleTimeSeries && singleTimeSeries.id ? !!singleTimeSeries.id : false
  );

  const idPrefix = `${marketType.toLowerCase()}-dt`;

  return (
    <div className="datetime-offer">
      <div className="datetime-offer-header">
        <span className="datetime-offer-title">Datetime Offer</span>
        <span className="datetime-offer-header-content">
          <DatePicker date={date} onChange={(d: DateTime) => setDateWithTz(d)} />
          <BidOfferButtonGroup
            disableDelete={disable || loading || !isDeletable}
            disableSave={disable || loading || !edited}
            deleteOnClick={deleteAllOffers}
            saveOnClick={saveTimeSeries}
          />
        </span>
      </div>
      <div className="datetime-offer-row">
        <div className="datetime-offer-row-title" />
        <div className="datetime-offer-row-content">
          <BidOfferTable
            currency={currency}
            data={timeSeries}
            deleteRow={deleteRow}
            disableBessOffers={disableBessOffers}
            idPrefix={idPrefix}
            isBiddable={!allowSinglePair}
            loading={loading}
            setRow={setRow}
            timezone={timezone}
          />
        </div>
      </div>
    </div>
  );
};

export default DatetimeOffer;
