export const SET_AUTH_STATUS = 'SET_AUTH_STATUS';
export const SET_MENU_STATE = 'SET_MENU_STATE';

function setMenuState(open) {
  return {
    type: SET_MENU_STATE,
    menuOpen: open,
  };
}

export const actions = {
  setMenuState,
};

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  leftMenuOpen: false, // In mobile, is the left menu open?
};

export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case SET_MENU_STATE:
      return {
        ...state,
        leftMenuOpen: action.menuOpen,
      };
    default:
      return state;
  }
}
