import React, { useContext } from 'react';

import Checkbox from 'components/Checkbox';
import { MarketType } from 'types';

import { ProgramsContext } from 'contexts/ProgramsContext';
import BidOfferSection from './BidOfferSection';
import './MarketSettingsTab.scss';

type AssetInfo = {
  der_type: string;
  id: number;
  operating_mode: 'BID_OFFER' | 'UNCONSTRAINED';
  usage_point_type: string;
};

type MarketSettingsTabProps = {
  assetID: string;
  assetInfo: AssetInfo;
  saving: boolean;
  updateDER: (der: { operating_mode: string }) => void;
};

const MarketSettingsTab = ({ assetID, assetInfo, saving, updateDER }: MarketSettingsTabProps) => {
  const { programs } = useContext(ProgramsContext);

  return (
    <div className="my-assets-details">
      {assetInfo.der_type === 'BESS' && (
        <div className="market-settings-section">
          <div className="left-col">
            <div className="title">Configuration</div>
          </div>
          <div className="main">
            <Checkbox
              checked={assetInfo.operating_mode === 'UNCONSTRAINED'}
              disabled={saving || programs[0].battery_control !== 'ANY'}
              onClick={() => {
                updateDER({
                  operating_mode:
                    assetInfo.operating_mode === 'BID_OFFER' ? 'UNCONSTRAINED' : 'BID_OFFER',
                });
              }}
            />
            Allow market operator to manage battery charge and discharge schedules
          </div>
        </div>
      )}
      <BidOfferSection
        assetID={assetID}
        assetInfo={assetInfo}
        program={programs[0]}
        marketType={MarketType.DAYAHEAD}
        title="Day Ahead"
      />
      <BidOfferSection
        assetID={assetID}
        assetInfo={assetInfo}
        program={programs[0]}
        marketType={MarketType.SAMEDAY}
        title="Same Day"
      />
    </div>
  );
};

export default MarketSettingsTab;
