import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash.isequal';
import flatpickr from 'flatpickr';
import { DateTime } from 'luxon';

import './DatePicker.scss';

/*
 * Creates a date picker instance that has forward and back buttons for the dates
 * and an input that triggers the calendar to open. The default is to allow selection
 * of both date and time
 */
class DatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: this.props.defaultDate || this.props.date || DateTime.local(),
    };

    this.calendar = null;
    this.dateInput = null;
  }

  componentDidMount() {
    this.createDateField(this.state.date);
  }

  componentDidUpdate(prevProps) {
    // If calendar is controlled, update the internal date state
    if (this.props.date && this.props.date.valueOf() !== prevProps.date.valueOf()) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ date: this.props.date });
      this.calendar.setDate(this.props.date.toLocaleString());
      this.createDateField(this.props.date);
    }

    if (!isEqual(prevProps.options, this.props.options)) {
      Object.keys(this.props.options).forEach((key) => {
        this.calendar.set(key, this.props.options[key]);
      });
    }
  }

  componentWillUnmount() {
    if (this.calendar) {
      this.calendar.destroy();
    }
  }

  createDateField = (inputDate) => {
    let currentValue = inputDate;
    let minDate = this.props.options.minDate;
    let maxDate = this.props.options.maxDate;
    const now = inputDate.toJSDate();
    const timezoneOffsetInMinutes = now.getTimezoneOffset();

    if (this.props.useUTC) {
      currentValue = currentValue.plus({ minutes: timezoneOffsetInMinutes });
    }

    let options = { ...this.props.options };
    if (this.props.options.minDate) {
      if (this.props.useUTC) {
        minDate = minDate.plus({ minutes: timezoneOffsetInMinutes });
        maxDate = maxDate.plus({ minutes: timezoneOffsetInMinutes });
      }

      options = {
        ...options,
        minDate: minDate.valueOf(),
        maxDate: maxDate.valueOf(),
      };
    }

    this.calendar = flatpickr(this.dateInput, {
      time_24hr: true,
      minuteIncrement: 1,
      dateFormat: this.props.dateFormat,
      onChange: (date) => this.handleDateChange(DateTime.fromJSDate(date[0])),
      defaultDate: currentValue.valueOf(),
      ...options,
    });
    if (this.calendar && this.calendar.calendarContainer) {
      this.calendar.calendarContainer.classList.add('DatePicker-calendar-instance');
    }
  };

  // Add one date to the current date
  addDate = () => {
    const newDate = this.state.date.plus({ days: 1 });
    const maxDate = this.calendar.config._maxDate;

    // Ensure that we do not go past maxDate if set...
    if (maxDate !== undefined && newDate.startOf('day') > maxDate.startOf('d')) {
      return;
    }
    this.handleDateChange(newDate);
  };

  // Subtract one date from the current date
  subtractDate = () => {
    const newDate = this.state.date.plus({ days: -1 });
    this.handleDateChange(newDate);
  };

  handleDateChange = (date) => {
    this.setState({ date });
    this.props.onChange(date);
  };

  render() {
    return (
      <div className="date-picker">
        {this.props.showArrows && (
          <button
            className="date-picker__btn"
            onClick={this.subtractDate}
            disabled={this.props.disabled}
            type="button"
          >
            <i className="material-icons" aria-hidden="true">
              keyboard_arrow_left
            </i>
          </button>
        )}
        <input
          className="date-picker__input"
          name="startDate"
          disabled={this.props.disabled}
          ref={(div) => {
            this.dateInput = div;
          }}
        />
        {this.props.showArrows && (
          <button
            className="date-picker__btn"
            onClick={this.addDate}
            disabled={this.props.disabled}
            type="button"
          >
            <i className="material-icons" aria-hidden="true">
              keyboard_arrow_right
            </i>
          </button>
        )}
      </div>
    );
  }
}

DatePicker.defaultProps = {
  date: null,
  defaultDate: null,
  disabled: false,
  options: {},
  showArrows: true,
  dateFormat: 'F j, Y',
  useUTC: true,
};

DatePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  date: PropTypes.object, // Controlled Component Date (luxon DateTime obj)
  defaultDate: PropTypes.object, // Default date only (luxon DateTime obj)
  disabled: PropTypes.bool,
  options: PropTypes.object,
  showArrows: PropTypes.bool,
  dateFormat: PropTypes.string, // Flatpickr date format string
  useUTC: PropTypes.bool,
};

export default DatePicker;
