import React from 'react';

function ThankYou() {
  return (
    <div className="registration">
      <div className="registration-title">Thank You!</div>
      <p>Your application has been submitted and is being reviewed.</p>
    </div>
  );
}

export default ThankYou;
