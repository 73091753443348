import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import EventContainer from './EventContainer';
import './PricingEventsList.scss';

class PricingEventsList extends PureComponent {
  state = {
    isTimestampOpen: {},
  };

  /**
   * Creates the per timestamp drop down that contains pricing events.
   * @param  {Array} timestamps  List of timestamps (unix timestamps) to display
   * @return {JSX}          Group of pricing event containers
   */
  getPricingEvents = (timestamps) => {
    const list = timestamps.map((timestamp) => {
      return (
        <div key={`timestamp-container-${timestamp}`} className="pricing-events-hour-container">
          <EventContainer
            events={this.props.events[timestamp]}
            DERLookup={this.props.DERLookup}
            onStatusChange={this.props.onStatusChange}
            isTimestampOpen={!!this.state.isTimestampOpen[timestamp]}
            timestamp={timestamp}
            toggleTimestamp={this.toggleTimestamp}
            pricingEventsReq={this.props.pricingEventsReq}
            updateEventReq={this.props.updateEventReq}
            canEditTimestamp={this.props.canEditTimestamp}
            showAccept={this.props.showAccept}
            showResponseWidget={this.props.showResponseWidget}
            onAssetClick={this.props.onAssetClick}
          />
        </div>
      );
    });

    return list;
  };

  // Toggle the timestamp accordion
  toggleTimestamp = (timestamp) => {
    let isTimestampOpen;
    if (timestamp === null) {
      isTimestampOpen = {};
    } else {
      isTimestampOpen = {
        ...this.state.isTimestampOpen,
        [timestamp]: !this.state.isTimestampOpen[timestamp],
      };
    }
    this.setState({
      isTimestampOpen,
    });
  };

  hasNull = (obj) => {
    let nulls = false;
    Object.keys(obj).forEach((p) => {
      if (obj[p] === null) {
        nulls = true;
      }
    });
    return nulls;
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const newDate = this.props.eventDate !== nextProps.eventDate;
    const newMarket = this.props.marketType !== nextProps.marketType;

    if (newDate || newMarket) {
      this.setState({
        isTimestampOpen: {},
      });
    }
  }

  render() {
    const timestamps = [...Object.keys(this.props.events)]
      .map((v) => Number(v))
      .sort((a, b) => a - b);

    return (
      <>
        <div className="pricing-events-list">
          {this.props.showHeader && (
            <div className="pricing-events-header">
              <div className="pricing-events-header-cell cell-sm" title="Time (24h)">
                <h2>
                  Time
                  <br />
                  (24h)
                </h2>
              </div>
              <div className="pricing-events-header-cell cell-sm" title="Price">
                <h2>
                  Price
                  <br />
                  {`${this.props.currency}/MWh`}
                </h2>
              </div>
              <div className="pricing-events-header-cell cell-sm" title="Committed/Offered">
                <h2>
                  Committed/Offered
                  <br />
                  {`${this.props.currency}`}
                </h2>
              </div>
              <div className="pricing-events-header-cell cell-md" title="Committed/Offered (kW)">
                <h2>
                  Committed/Offered
                  <br />
                  (kW)
                </h2>
              </div>
              <div className="pricing-events-header-cell cell-md" title="Committed (kVAr)">
                <h2>
                  Committed
                  <br />
                  (kVAr)
                </h2>
              </div>
              <div className="pricing-events-header-cell cell-md" title="Status">
                <h2>Status</h2>
              </div>
              <div
                className="pricing-events-header-cell cell-md cell-hide-mobile"
                title="Settlement Status"
              >
                <h2>Settlement Status</h2>
              </div>
            </div>
          )}
          <div className="pricing-events-contents">{this.getPricingEvents(timestamps)}</div>
        </div>
      </>
    );
  }
}

PricingEventsList.defaultProps = {
  events: [],
  DERLookup: {},
  canEditTimestamp: () => false,
  onStatusChange: () => false,
  pricingEventsReq: 0,
  showAccept: false,
  showHeader: true,
  showResponseWidget: false,
  onAssetClick: null,
};

PricingEventsList.propTypes = {
  currency: PropTypes.string.isRequired,
  events: PropTypes.object,
  DERLookup: PropTypes.object,
  eventDate: PropTypes.object.isRequired,
  pricingEventsReq: PropTypes.number,
  updateEventReq: PropTypes.object.isRequired,
  canEditTimestamp: PropTypes.func,
  marketType: PropTypes.string.isRequired,
  showAccept: PropTypes.bool,
  showHeader: PropTypes.bool,
  onStatusChange: PropTypes.func,
  showResponseWidget: PropTypes.bool,
  onAssetClick: PropTypes.func,
};

export default PricingEventsList;
