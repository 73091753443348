import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';

import '../styles/CompanyInfoPanel.scss';

class CompanyInfoPanel extends PureComponent {
  constructor(props) {
    super(props);

    // Flatten out the customer object so that our form is
    // easier to work with
    const customer = this.flattenCustomer(props.customer);
    this.state = {
      editMode: false,
      ...customer,
    };
  }

  getAddress(address) {
    // Combines an address object from the back-end
    // into a single string for display
    const {
      line_1: line1,
      line_2: line2,
      postal_code: postalCode,
      locality,
      administrative_area: administrativeArea,
      country,
    } = address;

    // This case can happen when the default props render
    if (Object.keys(address).length === 0) {
      return '';
    }

    return `${line1}\n${
      line2 || ''
    }\n${locality}, ${administrativeArea}\n${country}\n${postalCode}`;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.customer !== nextProps.customer) {
      // Reflatten the customer into our state
      this.setState(this.flattenCustomer(nextProps.customer));
    }

    if (nextProps.saving === false && this.props.saving) {
      // This condition gets hit when saving the customer information
      // was successful. In that case, we want to leave edit mode.
      this.setState({ editMode: false });
    }
  }

  flattenCustomer(customer) {
    const { billing_account_id: billingAccountID, name, company, invoicee } = customer;

    const { phone_number: companyPhone, address: companyAddress } = company;

    const { phone_number: invoiceePhone, address: invoiceeAddress, name: invoiceeName } = invoicee;

    return {
      companyName: name,
      billingAccountID,
      companyAddress,
      companyPhone,
      invoiceeAddress,
      invoiceePhone,
      invoiceeName,
    };
  }

  cancelEdit = () => {
    this.setState({
      editMode: false,
      // cancel state updates for our customer
      ...this.flattenCustomer(this.props.customer),
    });
  };

  enterEditMode = () => {
    this.setState({
      editMode: true,
    });
  };

  verifyAddress(address) {
    // All address fields required except line2
    return (
      address.line_1 &&
      address.locality &&
      address.administrative_area &&
      address.country &&
      address.postal_code
    );
  }

  canSubmitEdit = () => {
    const {
      companyName,
      billingAccountID,
      companyAddress,
      companyPhone,
      invoiceeAddress,
      invoiceePhone,
      invoiceeName,
    } = this.state;

    return (
      companyName &&
      billingAccountID &&
      companyPhone &&
      this.verifyAddress(companyAddress) &&
      invoiceeName &&
      invoiceePhone &&
      this.verifyAddress(invoiceeAddress)
    );
  };

  submitEdit = () => {
    // Build the schema needed for the response
    const {
      companyName,
      billingAccountID,
      companyAddress,
      companyPhone,
      invoiceeAddress,
      invoiceePhone,
      invoiceeName,
    } = this.state;

    const customer = {
      name: companyName,
      billing_account_id: billingAccountID,
      company: {
        address: companyAddress,
        phone_number: companyPhone,
      },
      invoicee: {
        address: invoiceeAddress,
        name: invoiceeName,
        phone_number: invoiceePhone,
      },
    };
    this.props.updateCustomer(customer);
  };

  createChangeHander = (key) => {
    return (e) => {
      // Create a change handler that updates
      // the state with the given key with the new
      // value
      this.setState({
        [key]: e.target.value,
      });
    };
  };

  createAddressChangeHandler = (addr, key) => {
    return (e) => {
      const newState = {
        ...this.state[addr],
        [key]: e.target.value,
      };

      this.setState({
        [addr]: newState,
      });
    };
  };

  renderHeader() {
    const { editMode } = this.state;
    const { saving } = this.props;

    return (
      <div className="company-info-panel__header">
        <span className="text">Company Information</span>
        {!editMode && (
          <button className="company-info-panel__edit-button" onClick={this.enterEditMode}>
            Edit
          </button>
        )}
        {editMode && (
          <>
            {!saving && (
              <button className="company-info-panel__cancel-button" onClick={this.cancelEdit}>
                Cancel
              </button>
            )}
            <Button
              className="company-info-panel__save-button"
              onClick={this.submitEdit}
              disabled={this.canSubmitEdit() ? undefined : true}
              height={44}
              loading={saving}
              label={saving ? undefined : 'Save'}
            />
          </>
        )}
      </div>
    );
  }

  renderInput(label, value, id, disabled, type = 'text', onChange = () => {}) {
    // Render a single input field
    const inputEditStyle = 'company-info-panel__input--edit';
    const inputClass = `company-info-panel__input ${!disabled ? inputEditStyle : ''}`;

    return (
      <div className="company-info-panel__group">
        <label className="company-info-panel__label" htmlFor={id}>
          {label}
        </label>
        <input
          className={inputClass}
          disabled={!disabled ? undefined : true}
          id={id}
          type={type}
          value={value}
          onChange={onChange}
        />
      </div>
    );
  }

  renderAddressViewContent(address, id) {
    const addrString = this.getAddress(address);

    return (
      <div className="company-info-panel__group">
        <label className="company-info-panel__label" htmlFor={id}>
          Address
        </label>
        <textarea
          className="company-info-panel__input company-info-panel__address"
          disabled
          id={id}
          value={addrString}
          rows={5}
        />
      </div>
    );
  }

  renderAddressEditContent(address, id, addressKey) {
    const {
      line_1: line1,
      line_2: line2,
      postal_code: postalCode,
      locality,
      administrative_area: administrativeArea,
      country,
    } = address;

    const { saving: isUpdating } = this.props;

    return (
      <>
        {this.renderInput(
          'Street Address *',
          line1,
          `${id}-line1`,
          isUpdating,
          'text',
          this.createAddressChangeHandler(addressKey, 'line_1')
        )}
        {this.renderInput(
          'Line 2',
          line2,
          `${id}-line2`,
          isUpdating,
          'text',
          this.createAddressChangeHandler(addressKey, 'line_2')
        )}
        <div className="company-info-panel__address-container">
          <div className="address-column">
            {this.renderInput(
              'City *',
              locality,
              `${id}-city`,
              isUpdating,
              'text',
              this.createAddressChangeHandler(addressKey, 'locality')
            )}
            {this.renderInput(
              'Country *',
              country,
              `${id}-country`,
              isUpdating,
              'text',
              this.createAddressChangeHandler(addressKey, 'country')
            )}
          </div>
          <div className="address-column address-column__right">
            {this.renderInput(
              'State/Province *',
              administrativeArea,
              `${id}-aa`,
              isUpdating,
              'text',
              this.createAddressChangeHandler(addressKey, 'administrative_area')
            )}
            {this.renderInput(
              'ZIP/Postal Code *',
              postalCode,
              `${id}-post`,
              isUpdating,
              'text',
              this.createAddressChangeHandler(addressKey, 'postal_code')
            )}
          </div>
        </div>
      </>
    );
  }

  render() {
    const {
      editMode,
      companyName,
      billingAccountID,
      companyAddress,
      companyPhone,
      invoiceeAddress,
      invoiceePhone,
      invoiceeName,
    } = this.state;

    const { saving } = this.props;
    const disabled = !editMode || saving;

    return (
      <div className="company-info-panel">
        {this.renderHeader()}
        <div
          className={`company-info-panel__info-panel ${
            editMode ? 'company-info-panel__info-panel-edit' : ''
          }`}
        >
          <div className="panel-column">
            {this.renderInput(
              `Name ${disabled ? '' : '*'}`,
              companyName,
              'company-info-name',
              disabled,
              'text',
              this.createChangeHander('companyName')
            )}
            {this.renderInput(
              `Phone Number ${disabled ? '' : '*'}`,
              companyPhone,
              'company-info-phone',
              disabled,
              'tel',
              this.createChangeHander('companyPhone')
            )}
          </div>
          <div className="panel-column">
            {editMode
              ? this.renderAddressEditContent(
                  companyAddress,
                  'company-info-address',
                  'companyAddress'
                )
              : this.renderAddressViewContent(companyAddress, 'company-info-address')}
          </div>
        </div>
        <div className="company-info-panel__header company-info-panel__sub-header">
          Invoice Information
        </div>
        <div
          className={`company-info-panel__info-panel ${
            editMode ? 'company-info-panel__info-panel-edit' : ''
          }`}
        >
          <div className="panel-column">
            {this.renderInput(
              `Name ${disabled ? '' : '*'}`,
              invoiceeName,
              'invoice-info-name',
              disabled,
              'text',
              this.createChangeHander('invoiceeName')
            )}
            {this.renderInput(
              `Phone Number ${disabled ? '' : '*'}`,
              invoiceePhone,
              'invoice-info-phone',
              disabled,
              'tel',
              this.createChangeHander('invoiceePhone')
            )}
          </div>
          <div className="panel-column">
            {this.renderInput(
              `Customer Account ID ${disabled ? '' : '*'}`,
              billingAccountID,
              'invoice-info-acct-id',
              disabled,
              'text',
              this.createChangeHander('billingAccountID')
            )}
            {editMode
              ? this.renderAddressEditContent(
                  invoiceeAddress,
                  'invoice-info-address',
                  'invoiceeAddress'
                )
              : this.renderAddressViewContent(invoiceeAddress, 'invoice-info-address')}
          </div>
        </div>
      </div>
    );
  }
}

CompanyInfoPanel.propTypes = {
  customer: PropTypes.shape({
    billing_account_id: PropTypes.string,
    company: PropTypes.shape({
      phone_number: PropTypes.string,
      address: PropTypes.shape({
        line_1: PropTypes.string,
        line_2: PropTypes.string,
        postal_code: PropTypes.string,
        locality: PropTypes.string,
        administrative_area: PropTypes.string,
        country: PropTypes.string,
      }),
    }).isRequired,
    invoicee: PropTypes.shape({
      name: PropTypes.string,
      phone_number: PropTypes.string,
      address: PropTypes.shape({
        line_1: PropTypes.string,
        line_2: PropTypes.string,
        postal_code: PropTypes.string,
        locality: PropTypes.string,
        administrative_area: PropTypes.string,
        country: PropTypes.string,
      }),
    }).isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  updateCustomer: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
};

export default CompanyInfoPanel;
