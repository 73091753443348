import React from 'react';
import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';

import AssetDetailsRow from './AssetDetailsRow';

function getDisplayType(type = '') {
  return type.split('').reduce((word, letter) => {
    if (word.length > 0 && letter.match(/[A-Z]/)) {
      return `${word} ${letter}`;
    }
    return word + letter;
  }, '');
}

const DetailTab = ({ assetInfo, loading, saving }) => {
  const trunc = (n) => Number(n).toFixed(0);

  return (
    <div className="my-assets-details">
      <div className="asset-header">
        <div className="asset-name">
          {loading && (
            <ContentLoader
              height="55px"
              width="200px"
              speed={1}
              primaryColor="#f3f3f3"
              secondaryColor="#ecebeb"
            >
              <rect x="0" y="15" rx="5" ry="5" width="200" height="35" />
            </ContentLoader>
          )}
          <h2>{assetInfo.name}</h2>
        </div>
        <div className="asset-status">
          <p>Enrolled</p>
        </div>
      </div>
      <div className="asset-properties-container">
        <div className="asset-properties">
          <AssetDetailsRow
            attribute={getDisplayType(assetInfo.der_type)}
            label="Asset Type"
            loading={loading || saving}
            string
          />
          {assetInfo.der_type === 'PhotoVoltaic' && assetInfo.control_strategy && (
            <AssetDetailsRow
              attribute={assetInfo.control_strategy === 'SCHEDULED' ? 'Scheduled' : 'Curtailable'}
              label="Control Mode"
              loading={loading || saving}
              string
            />
          )}
          <AssetDetailsRow
            attribute={trunc(assetInfo.real_power)}
            divisor={1000}
            label="Total Power"
            loading={loading || saving}
            number
            unit="kW"
          />
          <AssetDetailsRow
            attribute={trunc(assetInfo.reactive_power)}
            divisor={1000}
            label="Total Reactive Power"
            loading={loading || saving}
            number
            unit="kVAr"
          />
          <AssetDetailsRow
            attribute={assetInfo.power_factor}
            label="Power Factor"
            loading={loading || saving}
            string
          />
          <AssetDetailsRow
            attribute={trunc(assetInfo.apparent_power)}
            divisor={1000}
            label="Rated Power"
            loading={loading || saving}
            number
            unit="kVA"
          />
          <AssetDetailsRow
            attribute={assetInfo.rated_voltage}
            divisor={1000}
            label="Rated Voltage"
            loading={loading || saving}
            number
            unit="kV"
          />
          {assetInfo.der_type === 'BESS' && (
            <AssetDetailsRow
              attribute={assetInfo.ramp_rate}
              divisor={1000}
              label="Ramp Rate"
              loading={loading || saving}
              number
              unit="kW/s"
            />
          )}
        </div>
      </div>
    </div>
  );
};

DetailTab.defaultProps = {
  assetInfo: {},
};

DetailTab.propTypes = {
  assetInfo: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  saving: PropTypes.bool.isRequired,
};

export default DetailTab;
