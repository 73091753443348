import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import '../styles/Asset.scss';

class Asset extends PureComponent {
  getDERType(derClass) {
    switch (derClass) {
      case 'SynchronousMachine':
        return 'Diesel Genset';
      default:
        return null;
    }
  }

  getIcon(derClass) {
    let iconPath = '';
    switch (derClass) {
      case 'SynchronousMachine':
        iconPath = 'diesel_generator.svg';
        break;
      default:
        iconPath = '';
        break;
    }

    if (!iconPath) {
      return null;
    }

    return <img className="profile-asset__icon" src={iconPath} alt="DER type icon" />;
  }

  render() {
    const { der } = this.props;

    return (
      <div className="profile-asset-list__der profile-asset">
        {this.getIcon(der.info.class)}
        <div className="profile-asset__text-container">
          <span className="profile-asset__name">{der.info.name}</span>
          <span className="profile-asset__class">{this.getDERType(der.info.class)}</span>
        </div>
      </div>
    );
  }
}

Asset.propTypes = {
  der: PropTypes.shape({
    id: PropTypes.number.isRequired,
    info: PropTypes.shape({
      class: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default Asset;
