import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import Modal from 'components/Modal';
import './CancelEventsModal.scss';

class CancelEventsModal extends PureComponent {
  state = {
    reason: '',
  };

  cancelConfirmedEvent = () => {
    this.props.closeCancellationModal();
    this.props.updatePricingEventStatus(
      this.props.eventToCancel,
      this.props.currentDate,
      'Cancelled',
      this.state.reason
    );
  };

  handleCancelReason = (e) => this.setState({ reason: e.target.value });

  render() {
    const { eventToCancel } = this.props;
    const disableCancel = this.state.reason === '' || false;

    return (
      <div className="cancel-events">
        <Modal
          className="modal--cancel-events"
          height="420px"
          width="420px"
          active
          border-radius="4px"
          onCancel={this.props.closeCancellationModal}
          showFooter={false}
          showHeader={false}
        >
          <div className="cancel-events-modal-contents">
            <div className="cancel-events-close-icon">
              <Button className="cancel-btn-close-icon" onClick={this.props.closeCancellationModal}>
                <i className="material-icons">close</i>
              </Button>
            </div>
            <div className="cancel-text-1">{`Are you sure you want to cancel this ${eventToCancel.status.toLowerCase()} event?`}</div>
            <div className="cancel-text-2">*Please provide reason for cancelling:</div>
            <textarea
              className="cancel-reason"
              placeholder="Reason for cancelling..."
              onChange={this.handleCancelReason}
              value={this.state.reason}
            />
            <div className="cancel-text-3">Caution: This might cause cancellation fees.</div>
            <div className="cancel-events-buttons">
              <Button
                className={
                  disableCancel
                    ? 'cancel-button cancel-button--yes-cancel-disabled'
                    : 'cancel-button cancel-button--yes-cancel-enabled'
                }
                label="Yes, Cancel"
                disabled={disableCancel}
                onClick={this.cancelConfirmedEvent}
              />
              <Button
                className="cancel-button cancel-button--go-back"
                label="Go Back"
                onClick={this.props.closeCancellationModal}
              />
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

CancelEventsModal.propTypes = {
  closeCancellationModal: PropTypes.func.isRequired,
  updatePricingEventStatus: PropTypes.func.isRequired,
  eventToCancel: PropTypes.object.isRequired,
  currentDate: PropTypes.object.isRequired,
};

export default CancelEventsModal;
