import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DERactions } from 'store/DERs';

import Assets from '../components/Assets';

const mapStateToProps = (state) => ({
  ...state.DER,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...DERactions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Assets);
