import React from 'react';
import PropTypes from 'prop-types';

const Track = ({ source, target, getTrackProps, trackColor }) => (
  <div
    className="simple-slider-track"
    style={{
      backgroundColor: trackColor,
      left: `${source.percent}%`,
      width: `${target.percent - source.percent}%`,
    }}
    {...getTrackProps()}
  />
);

Track.propTypes = {
  source: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired,
  }).isRequired,
  target: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired,
  }).isRequired,
  trackColor: PropTypes.string.isRequired,
  getTrackProps: PropTypes.func.isRequired,
};

export default Track;
