import StepZilla from 'react-stepzilla';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Request } from '@opusonesolutions/gridos-app-framework';

import HeaderNavigationBar from 'components/HeaderNavigationBar';
import AssetGeneralInfo from '../components/AssetGeneralInfo';
import AssetNameplateInfo from '../components/AssetNameplateInfo';
import AssetAdditionalInfo from '../components/AssetAdditionalInfo';
import ThankYou from '../components/ThankYou';
import '../styles/Registration.scss';

class AssetRegistration extends Component {
  state = {
    currentStep: 0,
    errorMessage: '',
    AssetGeneralInfo: {
      assetName: '',
      assetType: '',
      manufacturer: '',
      model: '',
      communicationCapacity: '',
    },
    AssetNameplateInfo: {
      totalPower: '',
      totalReactivePower: '',
      ratedPower: '',
      ratedVoltage: '',
      powerFactor: '',
    },
    AssetAdditionalInfo: {
      assetLocation: '',
      city: '',
      country: '',
      administrativeArea: '',
      postalCode: '',
      permitVerificationNo: '',
      assetInstallationDate: '',
      meterInstalled: false,
      meterSerialNo: '',
      meterServicePointId: '',
    },
  };

  onFieldChange(step, event) {
    const updates = {
      ...this.state[step],
      [event.target.name]: event.target.value,
    };
    this.setState({ ...this.state, [step]: updates });
  }

  onCopyPrimaryContact(event) {
    const shouldCopyPrimaryContact = event.target.value === 'on';
    const updatedInvoiceInfo = this.copyPrimaryContactIntoInvoiceContact(shouldCopyPrimaryContact);
    this.setState({ ...this.state, InvoiceInfo: updatedInvoiceInfo });
  }

  getCustomerRegistrationData() {
    const { ...invoiceCopy } = this.state.InvoiceInfo;
    delete invoiceCopy.invoiceContactSameAsPrimary;

    return {
      PrimaryContact: this.state.PrimaryContact,
      InvoiceInfo: invoiceCopy,
      AssetGeneralInfo: this.state.AssetGeneralInfo,
      AssetNameplateInfo: this.state.AssetNameplateInfo,
      AssetAdditionalInfo: this.state.AssetAdditionalInfo,
    };
  }

  copyPrimaryContactIntoInvoiceContact(invoiceContactIsSameAsPrimary) {
    if (invoiceContactIsSameAsPrimary) {
      const { customerAccountId } = this.state.InvoiceInfo;
      const { ...contactCopy } = this.state.PrimaryContact;
      delete contactCopy.email;
      return {
        ...contactCopy,
        customerAccountId,
        invoiceContactSameAsPrimary: invoiceContactIsSameAsPrimary,
      };
    }

    return {
      ...this.state.InvoiceInfo,
      invoiceContactSameAsPrimary: invoiceContactIsSameAsPrimary,
    };
  }

  handleStepChange(currentStep) {
    this.setState({ currentStep, errorMessage: '' });
  }

  stepClassname(step) {
    if (step === this.state.currentStep) {
      return 'progress-step is-active';
    }
    if (step < this.state.currentStep) {
      return 'progress-step is-complete';
    }
    return 'progress-step';
  }

  submitApplication = async () => {
    const submitRequest = new Request('/api/mpi/asset/register');

    try {
      await submitRequest.post(this.getCustomerRegistrationData());
      this.setState({ errorMessage: '' });
      return true;
    } catch (err) {
      this.setState({ errorMessage: 'An error occurred, please try again.' });
      return false;
    }
  };

  render() {
    const steps = [
      {
        name: 'Asset General Info',
        component: (
          <AssetGeneralInfo
            onFieldChange={(e) => this.onFieldChange('AssetGeneralInfo', e)}
            {...this.state.AssetGeneralInfo}
          />
        ),
      },
      {
        name: 'Asset Nameplate Info',
        component: (
          <AssetNameplateInfo
            onFieldChange={(e) => this.onFieldChange('AssetNameplateInfo', e)}
            {...this.state.AssetNameplateInfo}
          />
        ),
      },
      {
        name: 'Asset Additional Info',
        component: (
          <AssetAdditionalInfo
            onFieldChange={(e) => this.onFieldChange('AssetAdditionalInfo', e)}
            submitApplication={this.submitApplication}
            errorMessage={this.state.errorMessage}
            {...this.state.AssetAdditionalInfo}
          />
        ),
      },
      { name: 'Thank You', component: <ThankYou /> },
    ];

    return (
      <div className="registration-container asset-registration">
        <HeaderNavigationBar title="My Assets" />
        <div className="asset-registration-navigation">
          <button onClick={this.props.history.goBack} className="back-navigation-btn">
            <i className="material-icons">arrow_back</i>
            <p>Enroll New Asset</p>
          </button>
        </div>
        <div className="registration-contents">
          <div className="progress-container">
            <ul className="progress-tracker progress-tracker--text progress-tracker--center">
              <li className={this.stepClassname(0)}>
                <span className="progress-marker">1</span>
                <span className="progress-text">
                  <h4 className="progress-title">Asset General Info</h4>
                </span>
              </li>

              <li className={this.stepClassname(1)}>
                <span className="progress-marker">2</span>
                <span className="progress-text">
                  <h4 className="progress-title">Asset Namplate Info</h4>
                </span>
              </li>

              <li className={this.stepClassname(2)}>
                <span className="progress-marker">3</span>
                <span className="progress-text">
                  <h4 className="progress-title">Asset Additional Info</h4>
                </span>
              </li>
            </ul>
          </div>

          <div className="registration-steps">
            <StepZilla
              steps={steps}
              showSteps={false}
              onStepChange={(step) => this.handleStepChange(step)}
              nextTextOnFinalActionStep="Submit"
              prevBtnOnLastStep={false}
            />
          </div>
        </div>
      </div>
    );
  }
}

AssetRegistration.propTypes = {
  history: PropTypes.object.isRequired,
};

export default AssetRegistration;
