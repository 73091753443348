import React from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import classNames from 'classnames';

import Button from '../Button';
import Tooltip from '../Tooltip';

import './IconButton.scss';

const BaseButton = ({
  className,
  iconClassName,
  round,
  active,
  disabled,
  onClick,
  icon,
  hasNotification,
  children,
  rotate,
}) => (
  <div className={`${className} icon-btn-container`}>
    <Button
      type="primary"
      className={classNames({
        'icon-btn': true,
        'icon-btn--active': active,
        [className]: true,
        round,
      })}
      disabled={disabled}
      onClick={onClick}
    >
      {hasNotification && <div className="has-notification">&nbsp;</div>}
      {icon ? (
        <span className="icon-container">
          <i
            {...(rotate && rotate > 0 && rotate < 360
              ? { style: { transform: `rotate(${Math.trunc(rotate)}deg)` } }
              : {})}
            className={classNames('material-icons', iconClassName)}
          >
            {icon}
          </i>
        </span>
      ) : (
        <div className="icon-container">{children}</div>
      )}
    </Button>
  </div>
);

const IconButton = ({ tooltip, ...props }) => {
  return tooltip ? (
    <Tooltip content={tooltip}>
      <BaseButton {...props} />
    </Tooltip>
  ) : (
    <BaseButton {...props} />
  );
};

const iconButtonPropTypes = {
  disabled: PropTypes.bool,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool,
  children: PropTypes.node,
  hasNotification: PropTypes.bool,
  round: PropTypes.bool,
  rotate: PropTypes.number,
};

const iconButtonDefaultProps = {
  active: false,
  disabled: false,
  hasNotification: false,
  round: false,
  className: '',
  iconClassName: '',
  children: undefined,
  rotate: undefined,
  icon: undefined,
};

const withTooltipPropTypes = {
  ...iconButtonPropTypes,
  tooltip: oneOfType([PropTypes.string, PropTypes.element]),
};

const withTooltipDefaultProps = {
  ...iconButtonDefaultProps,
  tooltip: undefined,
};

BaseButton.propTypes = iconButtonPropTypes;
BaseButton.defaultProps = iconButtonDefaultProps;

IconButton.propTypes = withTooltipPropTypes;
IconButton.defaultProps = withTooltipDefaultProps;

export default IconButton;
