import { DateTime } from 'luxon';
import React, { useContext, useMemo, useState } from 'react';

import DatePicker from 'components/DatePicker';
import { ProgramsContext } from 'contexts/ProgramsContext';
import useOffers from 'hooks/useOffers';
import LocalDateTime from 'helpers/LocalDateTime';
import { MarketType, OfferType } from 'types';

import BidOfferButtonGroup from '../BidOfferButtonGroup';
import ShiftingBidsTable from '../ShiftingBidsTable';

import './ShiftingBids.scss';

type ShiftingBidsProps = {
  isStanding: boolean;
  assetID: string;
  program: {
    currency: string;
    eventDuration: number;
  };
  marketType: MarketType;
  assetType: string;
};

const ShiftingBids = ({
  isStanding,
  assetID,
  program,
  marketType,
  assetType,
}: ShiftingBidsProps) => {
  const { getTimezone } = useContext(ProgramsContext);

  // Use LocalDateTime class to ensure that datetime values are in LocalZone
  const [localDate, setLocalDate] = useState(new LocalDateTime(DateTime.local().startOf('day')));

  // hkamal:
  // Set timezone by creating a datetime with the program timezone and same raw time values as localDate.
  // We avoid setZone because it shifts hours to compensate for offset changes which we don't want, since we
  // want to faithfully take the user's date selection without shifting hours around.
  const programDate = useMemo(
    () =>
      DateTime.fromObject({
        ...localDate.datetime.toObject(),
        zone: getTimezone(),
      }).startOf('day'),
    [localDate, getTimezone]
  );

  const {
    deleteAllOffers,
    deleteRow,
    disable,
    edited,
    loading,
    saveTimeSeries,
    setRow,
    timeSeries,
    timezone,
    saveOffer,
  } = useOffers({
    assetID,
    assetType,
    marketType,
    offerType: isStanding ? OfferType.StandingTimeSeries : OfferType.DatedTimeSeries,
    date: isStanding ? undefined : programDate,
  });

  const isDeletable = !!timeSeries.find((singleTimeSeries) =>
    singleTimeSeries && singleTimeSeries.id ? !!singleTimeSeries.id : false
  );
  const idPrefix = `${marketType.toLowerCase()}-dt`;

  return (
    <div className="shifting-bids">
      <div className="datetime-offer-header">
        <span className="datetime-offer-title">
          {isStanding ? 'Standing Bids' : 'Datetime Bids'}
        </span>
        <span className="datetime-offer-header-content">
          {!isStanding && (
            <DatePicker
              date={localDate.datetime}
              onChange={(d: DateTime) => setLocalDate(new LocalDateTime(d))}
              options={{ enableTime: true }}
              dateFormat="F j, Y"
              useUTC={false}
            />
          )}
          <BidOfferButtonGroup
            showSave={false}
            disableDelete={disable || loading || !isDeletable}
            disableSave={disable || loading || !edited}
            deleteOnClick={deleteAllOffers}
            saveOnClick={saveTimeSeries}
          />
        </span>
      </div>
      <div className="datetime-offer-row">
        <div className="datetime-offer-row-title" />
        <div className="datetime-offer-row-content">
          <ShiftingBidsTable
            program={program}
            data={timeSeries}
            deleteRow={deleteRow}
            loading={loading}
            marketType={marketType}
            timezone={timezone}
            assetType={assetType}
            idPrefix={idPrefix}
            setRow={setRow}
            date={programDate}
            onSave={saveOffer}
          />
        </div>
      </div>
    </div>
  );
};

export default ShiftingBids;
