import React, { Component } from 'react';
import PropTypes from 'prop-types';

import asyncActionStates from 'helpers/asyncActionStates';

import EventRow from './EventRow';
import TimestampRow from './TimestampRow';

class EventContainer extends Component {
  state = {
    events: {},
  };

  // Get the total height of the event container. Used to make transitions smoother
  getContainerSize = () => {
    if (this.props.isTimestampOpen) {
      return 'unset';
    }
    return 0;
  };

  // Convert list of events into dictionary and set in state
  setDefaultValues = (events) => {
    const eventLookup = events
      ? events.reduce((lu, event) => ({ ...lu, [event.id]: event }), {})
      : {};
    this.setState({ events: eventLookup });
  };

  // Loading status for event button. Success is still loading as list of events
  // is being requested after update is successful
  isLoading = (id) => this.props.updateEventReq[id] === asyncActionStates.LOADING;

  UNSAFE_componentWillMount() {
    if (this.props.events) {
      this.setDefaultValues(this.props.events);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { events } = this.props;
    const { events: newEvents } = nextProps;

    /**
     * Update panel events if :
     * - there is new pricing event data (only if the panel is not in edit mode)
     */

    const newPricingEvents = events !== newEvents;
    if (newPricingEvents) {
      this.setDefaultValues(newEvents);
    }
  }

  render() {
    const events = Object.values(this.state.events);
    const canEditTimestamp = this.props.canEditTimestamp(
      this.props.isTimestampOpen,
      this.props.events
    );

    return (
      <>
        <TimestampRow
          key={`timestampRow-${this.props.timestamp}`}
          timestamp={this.props.timestamp}
          events={this.props.events}
          isTimestampOpen={this.props.isTimestampOpen}
          toggleTimestamp={this.props.toggleTimestamp}
          loading={this.props.pricingEventsReq === asyncActionStates.LOADING}
        />
        {events.length > 0 && (
          <div
            className="pricing-events-event-container"
            style={{ height: this.getContainerSize() }}
          >
            {events.map((event) => (
              <div key={`event-${event.id}`}>
                <EventRow
                  key={`eventRow-${event.id}`}
                  event={event}
                  DERLookup={this.props.DERLookup}
                  onStatusChange={this.props.onStatusChange}
                  eventLoading={this.isLoading(event.id)}
                  showAccept={this.props.showAccept}
                  showResponseWidget={this.props.showResponseWidget}
                  canEditTimestamp={canEditTimestamp}
                  onAssetClick={this.props.onAssetClick}
                />
              </div>
            ))}
          </div>
        )}
      </>
    );
  }
}

EventContainer.defaultProps = {
  pricingEventsReq: 0,
  events: [],
  canEditTimestamp: () => false,
  onAssetClick: () => {},
};

EventContainer.propTypes = {
  events: PropTypes.array,
  DERLookup: PropTypes.object.isRequired,
  onStatusChange: PropTypes.func.isRequired,
  isTimestampOpen: PropTypes.bool.isRequired,
  timestamp: PropTypes.number.isRequired,
  pricingEventsReq: PropTypes.number,
  toggleTimestamp: PropTypes.func.isRequired,
  updateEventReq: PropTypes.object.isRequired,
  showAccept: PropTypes.bool.isRequired,
  canEditTimestamp: PropTypes.func,
  showResponseWidget: PropTypes.bool.isRequired,
  onAssetClick: PropTypes.func,
};

export default EventContainer;
