import React, { useMemo, FunctionComponent } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useAuthContext } from '@opusonesolutions/gridos-app-framework';
import CurrentTimeWidget from '../CurrentTimeWidget';
import NavigationButton from './NavigationButton';

import './LeftRailNavigation.scss';

type LeftRailNavProps = {
  actions: {
    setMenuState: (open: boolean) => void;
  };
  leftMenuOpen: boolean;
};

const LeftRailNavigation: FunctionComponent<LeftRailNavProps> = ({ actions, leftMenuOpen }) => {
  const history = useHistory();
  const location = useLocation();
  const { productDetails } = useAuthContext();

  const handleNavigationClick = useMemo(
    () => (id: string) => {
      // Close the menu in case we're on mobile
      actions.setMenuState(false);
      history.push(`/${id}`);
    },
    [actions, history]
  );

  if (location.pathname.includes('/login') || location.pathname === '/register') {
    return null;
  }

  return (
    <div className={`mpi-left-rail ${leftMenuOpen ? 'visible' : ''}`}>
      <div className="left-rail-top">
        <div className="left-rail-header">
          <img className="logo" src="/logo.svg" alt="Company Logo" />
          <CurrentTimeWidget color="#FFF" style={{ fontSize: '13px' }} />
        </div>
        <div className="home-container">
          <NavigationButton
            id=""
            title="Home"
            selected={location.pathname === '/'}
            onClick={handleNavigationClick}
            icon="home"
          />
        </div>
        <div className="market-btn-container">
          <NavigationButton
            id="same-day-market"
            title="Same Day Market"
            selected={location.pathname.startsWith('/same-day-market')}
            onClick={handleNavigationClick}
            iconSrc="/ic_market_40px.svg"
            height="72px"
          />
          <NavigationButton
            id="day-ahead-market"
            title="Day Ahead Market"
            selected={location.pathname.startsWith('/day-ahead-market')}
            onClick={handleNavigationClick}
            iconSrc="/ic_market_40px.svg"
            height="72px"
          />
        </div>
        <div className="info-btn-container">
          <NavigationButton
            id="assets"
            title="My Assets"
            selected={location.pathname.startsWith('/assets')}
            onClick={handleNavigationClick}
            icon="dns"
          />
          <NavigationButton
            id="offers"
            title="Bids/Offers"
            selected={location.pathname.startsWith('/offers')}
            onClick={handleNavigationClick}
            icon="show_chart"
          />
          <NavigationButton
            id="report"
            title="Report"
            selected={location.pathname.startsWith('/report')}
            onClick={handleNavigationClick}
            icon="assessment"
          />
        </div>
      </div>
      <div className="left-rail-bottom">
        <p>Powered by</p>
        <img className="gridOS-logo" src="/gridOS_logo.svg" alt="GridOS" />
        <p className="version">Version: {productDetails.version}</p>
      </div>
    </div>
  );
};

export default LeftRailNavigation;
