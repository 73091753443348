import { DateTime } from 'luxon';
import { Request } from '@opusonesolutions/gridos-app-framework';

import asyncActionStates from 'helpers/asyncActionStates';

import parsePricingEvent from '../helpers/parsePricingEvent';
import eventStates from '../helpers/eventStates';

const GET_DERS_LOADING = 'GET_DERS_LOADING';
const GET_DERS_SUCCESS = 'GET_DERS_SUCCESS';
const GET_DERS_ERROR = 'GET_DERS_ERROR';
const CLEAR_DERS = 'CLEAR_DERS';
const GET_DER_PE_SUCCESS = 'GET_DER_PE_SUCCESS';
const GET_DER_PE_LOADING = 'GET_DER_PE_LOADING';
const GET_DER_PE_FAILURE = 'GET_DER_PE_FAILURE';
const UPDATE_DER_REQ_STATUS = 'UPDATE_DER_REQ_STATUS';
const UPDATE_DER_SUCCESS = 'UPDATE_DER_SUCCESS';

function getDERs() {
  return (dispatch) => {
    dispatch({ type: GET_DERS_LOADING });
    const request = new Request('/api/mpi/der');

    // Get a list of all pricing events.
    return request
      .get()
      .then(({ data }) => {
        const ders = data
          .sort((a, b) => a.id - b.id)
          .reduce((lu, der) => {
            lu[der.rdf_id] = der;
            lu[der.rdf_id].name = der.info.name;
            return lu;
          }, {});

        dispatch({
          type: GET_DERS_SUCCESS,
          payload: ders,
        });
      })
      .catch(() => dispatch({ type: GET_DERS_ERROR }));
  };
}

function getDERPricingEvents(id, start, end) {
  return async (dispatch) => {
    let startTime;
    let endTime;

    if (start === undefined) {
      startTime = DateTime.local().startOf('day').plus({ months: -1 });
    } else {
      startTime = DateTime.fromISO(start);
    }

    if (end === undefined) {
      endTime = DateTime.local().endOf('day');
    } else {
      endTime = DateTime.fromISO(end);
    }

    const encStartTime = encodeURIComponent(startTime.toISO());
    const encEndTime = encodeURIComponent(endTime.toISO());
    const request = new Request(
      `/api/mpi/asset/${id}/pricing_events?from_start_time=${encStartTime}&to_start_time=${encEndTime}`
    );
    dispatch({ type: GET_DER_PE_LOADING });

    try {
      const { data } = await request.get();
      const events = [];
      const dailies = [];
      const KEY_MAP = {
        DAYAHEAD: 'dayAhead',
        SAMEDAY: 'sameDay',
      };
      const ACTIVE_STATUSES = [eventStates.CONFIRMED, eventStates.ACTIVE, eventStates.COMPLETED];
      const startTimeIndexMap = {};
      const dailyIndexMap = {};

      for (let i = 0; i < data.length; i += 1) {
        // Build LMP+D events by hour for summary
        const pe = parsePricingEvent(data[i]);
        const key = KEY_MAP[pe.market_type];
        const idx = startTimeIndexMap[pe.start_time];

        if (idx !== undefined) {
          // Already seen this timepoint, replace unit_price
          events[idx][key] = pe.unit_price;
        } else {
          // First time we saw this time
          const time = DateTime.fromISO(pe.start_time);
          events.push({
            startTime: time.toSeconds(),
            time,
            [key]: pe.unit_price,
          });
          startTimeIndexMap[pe.start_time] = events.length - 1;
        }

        // Build (max & avg LMP+D), (Committed & actual Generation) by Day
        const dayStamp = DateTime.fromISO(pe.start_time).startOf('day');
        const dIdx = dailyIndexMap[dayStamp.toISO()];
        let committedEnergy = 0;
        let generatedEnergy = 0;

        // Since only same day events are actually run, only those should
        // be included in the committed & generated total
        if (ACTIVE_STATUSES.includes(pe.state.state_type) && key === 'sameDay') {
          // Committed & generated values are in MWh, so need to convert
          // through the event duration
          const duration = parseInt(pe.duration, 10);
          committedEnergy += pe.powerRequiredMW * (duration / 3600);
          generatedEnergy += pe.powerDeliveredMW * (duration / 3600);
        }

        if (dIdx !== undefined) {
          // Already seen this timepoint, combine power, get max unit_price, sum + count occurrences
          dailies[dIdx].committedEnergy += committedEnergy;
          dailies[dIdx].generatedEnergy += generatedEnergy;
          if (dailies[dIdx][key]) {
            const currentMax = dailies[dIdx][key].max || Number.NEGATIVE_INFINITY;
            dailies[dIdx][key].max = Math.max(currentMax, pe.unit_price);
            dailies[dIdx][key].sum += pe.unit_price;
            dailies[dIdx][key].count += pe.unit_price ? 1 : 0;
          } else {
            dailies[dIdx][key] = {
              max: Math.max(Number.NEGATIVE_INFINITY, pe.unit_price),
              sum: pe.unit_price,
              count: pe.unit_price ? 1 : 0,
            };
          }
        } else {
          // First time we saw this time
          const time = dayStamp;
          dailies.push({
            startTime: dayStamp.toSeconds(),
            time,
            committedEnergy,
            generatedEnergy,
            [key]: {
              max: Math.max(pe.unit_price || Number.NEGATIVE_INFINITY),
              sum: pe.unit_price,
              count: pe.unit_price ? 1 : 0,
            },
          });
          dailyIndexMap[dayStamp.toISO()] = dailies.length - 1;
        }
      }

      // Add in events for rest of hours in the range
      let t = startTime;
      while (t < endTime) {
        if (startTimeIndexMap[t.toISO()] === undefined) {
          events.push({
            startTime: t.toSeconds(),
            time: t,
          });
        }
        t = t.plus({ hours: 1 });
      }

      // Add in dailies for rest of days in the range
      let d = startTime;
      while (d < endTime) {
        if (dailyIndexMap[d.toISO()] === undefined) {
          dailies.push({
            startTime: d.toSeconds(),
            time: d,
          });
        }
        d = d.plus({ days: 1 });
      }

      events.sort((a, b) => a.startTime - b.startTime);
      dailies.sort((a, b) => a.startTime - b.startTime);

      dispatch({
        type: GET_DER_PE_SUCCESS,
        payload: {
          events,
          dailies,
        },
      });
    } catch (error) {
      dispatch({ type: GET_DER_PE_FAILURE });
    }
  };
}

function initializeAssetRequest() {
  return { type: UPDATE_DER_REQ_STATUS, payload: asyncActionStates.INITIAL };
}

function updateAssetSettings(updates) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_DER_REQ_STATUS,
      payload: asyncActionStates.LOADING,
    });

    const request = new Request('api/mpi/der');

    try {
      const { data } = await request.patch(updates);
      const updatedAssets = data.reduce((lu, asset) => {
        lu[asset.rdf_id] = asset;
        lu[asset.rdf_id].name = asset.info.name;
        return lu;
      }, {});
      dispatch({
        type: UPDATE_DER_SUCCESS,
        assets: updatedAssets,
      });
    } catch (err) {
      dispatch({
        type: UPDATE_DER_REQ_STATUS,
        payload: asyncActionStates.ERROR,
      });
    }
  };
}

function clearDERs() {
  return { type: CLEAR_DERS };
}

export const DERactions = {
  getDERs,
  getDERPricingEvents,
  updateAssetSettings,
  initializeAssetRequest,
  clearDERs,
};

const initialState = {
  DERLookup: {},
  attributesReqStatus: asyncActionStates.INITIAL,
  peReqStatus: asyncActionStates.INITIAL,
  assetPricingEvents: [],
  assetEventsByDay: [],
  updateAttributesReqStatus: asyncActionStates.INITIAL,
  updateAssetReq: asyncActionStates.INITIAL,
  DERRequest: asyncActionStates.INITIAL,
};

export default function DERReducer(state = initialState, action) {
  switch (action.type) {
    case GET_DERS_LOADING:
      return {
        ...state,
        DERRequest: asyncActionStates.LOADING,
      };
    case GET_DERS_SUCCESS:
      return {
        ...state,
        DERLookup: action.payload,
        DERRequest: asyncActionStates.SUCCESS,
      };
    case GET_DERS_ERROR:
      return {
        ...state,
        DERRequest: asyncActionStates.ERROR,
      };
    case CLEAR_DERS:
      return {
        ...state,
        DERLookup: {},
      };
    case GET_DER_PE_LOADING:
      return {
        ...state,
        assetEventsByDay: [], // Clear when re-loading
        peReqStatus: asyncActionStates.LOADING,
      };
    case GET_DER_PE_SUCCESS:
      return {
        ...state,
        assetPricingEvents: action.payload.events,
        assetEventsByDay: action.payload.dailies,
        peReqStatus: asyncActionStates.SUCCESS,
      };
    case GET_DER_PE_FAILURE:
      return {
        ...state,
        peReqStatus: asyncActionStates.ERROR,
      };
    case UPDATE_DER_REQ_STATUS:
      return {
        ...state,
        updateAssetReq: action.payload,
      };
    case UPDATE_DER_SUCCESS:
      return {
        ...state,
        updateAssetReq: asyncActionStates.SUCCESS,
        DERLookup: {
          ...state.DERLookup,
          ...action.assets,
        },
      };
    default:
      return state;
  }
}
