import React from 'react';
import PropTypes from 'prop-types';

const NavigationButton = (props) => (
  <div className="nav-button-container" style={{ height: props.height }}>
    <button
      className={`nav-button ${props.selected && 'nav-button--selected'}`}
      style={{ height: props.height }}
      onClick={() => props.onClick(props.id)}
    >
      <div className="nav-button-contents">
        {props.icon && <i className="material-icons nav-icon">{props.icon}</i>}
        {props.iconSrc && <img className="nav-icon" src={props.iconSrc} alt={props.title} />}
        <p>{props.title}</p>
      </div>
    </button>
  </div>
);

NavigationButton.propTypes = {
  title: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  icon: PropTypes.string, // Material Design Icon Name
  height: PropTypes.string,
  iconSrc: PropTypes.string,
};

NavigationButton.defaultProps = {
  selected: false,
  icon: null,
  iconSrc: null,
  height: '50px',
};

export default NavigationButton;
