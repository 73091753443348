import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import pricingEventsReducer from './pricingEvents';
import DERReducer from './DERs';
import appReducer from './appState';

const rootReducer = combineReducers({
  location: routerReducer,
  events: pricingEventsReducer,
  DER: DERReducer,
  global: appReducer,
});

export default rootReducer;
