import React from 'react';
import ReactDOM from 'react-dom';
import { bindActionCreators } from 'redux';
import { Provider, connect } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { AuthContextProvider } from '@opusonesolutions/gridos-app-framework';

import { ProgramsContextProvider } from 'contexts/ProgramsContext';
import * as appActions from 'store/appState';

import 'core-js/stable';
import 'typeface-roboto';
import 'what-input';

import App from './App';
import './index.scss';

import configureStore from './store/configureStore';

const store = configureStore();

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setMenuState: appActions.actions.setMenuState,
    },
    dispatch
  ),
});

const mapStateToProps = (state) => ({
  ...state.global,
});

const ConnectedApp = connect(mapStateToProps, mapDispatchToProps)(App);

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <ToastProvider autoDismiss autoDismissTimeout={6000}>
        <AuthContextProvider serviceName="mpi">
          <ProgramsContextProvider>
            <ConnectedApp />
          </ProgramsContextProvider>
        </AuthContextProvider>
      </ToastProvider>
    </Router>
  </Provider>,
  document.getElementById('root')
);
