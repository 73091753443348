import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from 'components/Checkbox';
import ToggleSwitch from 'components/ToggleSwitch';

import { Link } from 'react-router-dom';

const AssetRow = (props) => {
  const { asset, editing } = props;

  return (
    <div className={`assets-table__asset-row ${editing && 'assets-table__asset-row--editing'}`}>
      <div className="assets-table__selected">
        <Checkbox
          className="assets-table__select-checkbox"
          checked={asset.selected}
          backgroundColor={asset.selected ? '#0079C1' : '#FFF'}
          checkColor="#FFF"
          borderColor="#d1d1d1"
          onClick={() => props.handleSelectedToggle(asset.rdf_id, !asset.selected)}
          disabled={editing}
        />
      </div>
      <div className="assets-table__name" style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
        <Link className="assets-table__link" to={`/assets/${asset.rdf_id}`}>
          {asset.name}
        </Link>
      </div>
      <div className="assets-table__status assets-table--hide-mobile">
        <div className="assets-table__status--enrolled">Enrolled</div>
      </div>
      <div className="assets-table__type assets-table--hide-mobile">
        <div className="assets-table__type">{asset.info.der_type}</div>
      </div>
      <div className="assets-table__email assets-table--hide-mobile">{asset.email.email}</div>
      <div className="assets-table__enabled assets-table--hide-mobile">
        <ToggleSwitch
          refId={`${asset.id}__enabled`}
          checked={asset.email.enabled}
          disabled={!editing}
          onChange={() => props.handleEmailToggle(asset.rdf_id, asset.id, !asset.email.enabled)}
        />
      </div>
    </div>
  );
};

AssetRow.propTypes = {
  asset: PropTypes.object.isRequired,
  editing: PropTypes.bool.isRequired,
  handleEmailToggle: PropTypes.func.isRequired,
  handleSelectedToggle: PropTypes.func.isRequired,
};

export default AssetRow;
