import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from 'store/pricingEvents';
import { DERactions } from 'store/DERs';

import DayAheadMarket from '../components/DayAheadMarket';

const mapStateToProps = (state) => ({
  events: state.events,
  ...state.DER,
  ...state.global,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      ...actions,
      ...DERactions,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(DayAheadMarket);
