import React, { PureComponent, ReactNode } from 'react';
import './RadioButtonGroup.scss';

type RadioButtonListType = 'row' | 'column';

type RadioButtonProps = {
  id: string;
  label: string | ReactNode;
  listType: RadioButtonListType;
  onChange: (value: any) => void;
  options: Array<{
    body?: string | ReactNode | null;
    disabled?: boolean;
    id: string;
    label: string | ReactNode | null;
  }>;
  value: any;
};

class RadioButtonGroup extends PureComponent<RadioButtonProps> {
  static defaultProps = {
    listType: 'row',
    label: '',
  };

  render() {
    return (
      <div className={`radio-btn-group radio-btn-group--${this.props.listType}`}>
        <label className="label">{this.props.label}</label>
        {this.props.options.map((option) => (
          <div className="option-group" key={option.id}>
            <input
              className="radio-btn-input"
              type="radio"
              value={option.id}
              id={`${this.props.id}-${option.id}`}
              name={this.props.id}
              onChange={(e) => this.props.onChange(e.target.value)}
              disabled={option.disabled}
              checked={this.props.value === option.id}
            />
            <div
              role="button"
              tabIndex={0}
              onClick={() => !option.disabled && this.props.onChange(option.id)}
              onKeyPress={({ key }) =>
                key === 'Enter' && !option.disabled && this.props.onChange(option.id)
              }
              className="option-label-container"
            >
              <label htmlFor={`${this.props.id}-${option.id}`}>
                <div className="option-icon-group">
                  <div
                    className={`radio-btn ${
                      this.props.value === option.id ? 'radio-btn--selected' : ''
                    }`}
                  >
                    <div className="radio-btn-fill" />
                  </div>
                  <span>{option.label}</span>
                </div>
              </label>
              {option.body && <div className="option-label-body">{option.body}</div>}
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default RadioButtonGroup;
