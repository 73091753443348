export interface Email {
  email: string;
  enabled: boolean;
  label: string;
}

export const toEmail = (rawEmail: any): Email => {
  const { email, enabled, label } = rawEmail;
  return { email, enabled, label };
};

export interface Feeder {
  id: string;
  name: string;
}

export const toFeeder = (rawFeeder: any): Feeder => {
  const { id, name } = rawFeeder;
  return { id, name };
};

export interface DerInfo {
  apparentPower: number;
  derClass: string;
  derType: string;
  derRdfId: string;
  feeder: Feeder;
  genCapacity: number;
  location: [string, string];
  name: string;
  phases: number;
  powerFactor: number;
  ratedVoltage: number;
  reactivePower: number;
  realPower: number;
}

export const toDerInfo = (rawDerInfo: any): DerInfo => {
  const {
    apparent_power: apparentPower,
    class: derClass,
    der_type: derType,
    der_rdf_id: derRdfId,
    feeder,
    gen_capacity: genCapacity,
    location,
    name,
    phases,
    power_factor: powerFactor,
    rated_voltage: ratedVoltage,
    reactive_power: reactivePower,
    real_power: realPower,
  } = rawDerInfo;
  return {
    apparentPower,
    derClass,
    derType,
    derRdfId,
    feeder: toFeeder(feeder),
    genCapacity,
    location,
    name,
    phases,
    powerFactor,
    ratedVoltage,
    reactivePower,
    realPower,
  };
};

export interface Der {
  activated: boolean;
  email: Email;
  id: number;
  info: DerInfo;
  operatingMode: string;
  rdfId: string;
}

export const toDer = (rawDer: any): Der => {
  const { activated, email, id, info, operating_mode: operatingMode, rdf_id: rdfId } = rawDer;

  return {
    activated,
    email: toEmail(email),
    id,
    info: toDerInfo(info),
    operatingMode,
    rdfId,
  };
};
