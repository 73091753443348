import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class FormField extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  validate() {
    const result = {
      valid: true,
      message: '',
      name: this.props.name,
    };

    if (this.props.isRequired && this.props.value.trim() === '') {
      result.valid = false;
      result.message = `${this.props.label} is required`;
      result.name = this.props.name;
    }

    return result;
  }

  render() {
    return (
      <div className="form-field-container">
        <div className="form-field">
          {this.props.label && (
            <div className="field-label">
              <label htmlFor={this.props.name}>{this.props.label}</label>
              {this.props.isRequired && <span className="required-field">*</span>}
            </div>
          )}
          <input
            name={this.props.name}
            type={this.props.type}
            placeholder={this.props.placeholder}
            value={this.props.value}
            onChange={this.props.onChange}
            required={this.props.isRequired}
          />
        </div>
        {this.props.unitSymbol && <div className="field-symbol">{this.props.unitSymbol}</div>}
      </div>
    );
  }
}

FormField.defaultProps = {
  type: 'text',
  value: '',
  isRequired: false,
  unitSymbol: '',
  placeholder: '',
};

FormField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
  unitSymbol: PropTypes.string,
  placeholder: PropTypes.string,
};

export default FormField;
