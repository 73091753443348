import React from 'react';
import PropTypes from 'prop-types';

const Handle = (props) => {
  const {
    domain: [min, max],
    handle: { id, value, percent },
    getHandleProps,
    handleColor,
  } = props;

  return (
    <div
      role="slider"
      aria-valuemin={min}
      aria-valuemax={max}
      aria-valuenow={value}
      style={{
        backgroundColor: handleColor,
        left: `${percent}%`,
      }}
      className="simple-slider-handle"
      {...getHandleProps(id)}
    />
  );
};

Handle.propTypes = {
  domain: PropTypes.array.isRequired,
  handle: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired,
  }).isRequired,
  handleColor: PropTypes.string.isRequired,
  getHandleProps: PropTypes.func.isRequired,
};

export default Handle;
