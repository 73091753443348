import React, { useEffect, useState, FunctionComponent, ChangeEvent } from 'react';
import { CellInfo } from 'react-table-6';

import SimpleNumberInput from 'components/SimpleNumberInput';
import { hasKey, isNumber, isString, isUndefined, getProperty, BidOfferType } from 'types';
import { BidOffer } from 'types/bidOffer';

interface EditableNumberInputProps {
  cellInfo: CellInfo;
  data: BidOffer[];
  idPrefix: string;
  isDisabled: boolean;
  setRow: (row: number, column: keyof BidOffer, value?: BidOfferType | number) => void;
  updateValue?: (newValue: MaybeNumber) => void;
}

type MaybeNumber = number | undefined;

export const EditableNumberInput: FunctionComponent<EditableNumberInputProps> = ({
  cellInfo,
  data,
  isDisabled,
  idPrefix,
  setRow,
  updateValue,
}) => {
  const [value, setValue] = useState<MaybeNumber>();

  const { column, index } = cellInfo;
  const { id: columnId } = column;
  const row = data[index];

  useEffect(() => {
    if (isString(columnId) && hasKey(row, columnId)) {
      const maybeNumber = getProperty(row, columnId);
      if (isNumber(maybeNumber) || isUndefined(maybeNumber)) {
        setValue(maybeNumber);
        updateValue && updateValue(maybeNumber);
      }
    }
  }, [columnId, row, updateValue]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    let newValue: MaybeNumber = parseFloat(e.target.value);

    // If parseFloat returns NaN, normalize to undefined
    if (isNaN(newValue)) {
      newValue = undefined;
    }

    setValue(newValue);
    updateValue && updateValue(newValue);
    if (hasKey(row, columnId) && isNumber(newValue)) {
      setRow(index, columnId, newValue);
    }
  };

  return (
    <SimpleNumberInput
      disabled={isDisabled}
      id={`${idPrefix}-${index}-${columnId}`}
      max={1e6}
      min={-1e6}
      step={0.001}
      value={value}
      onChange={onChange}
    />
  );
};

const renderNumberInputFactory = (
  isDisabled: boolean,
  idPrefix: string,
  data: BidOffer[],
  setRow: (row: number, column: keyof BidOffer, value?: BidOfferType | number) => void
) => (cellInfo: CellInfo) => (
  <EditableNumberInput
    isDisabled={isDisabled}
    cellInfo={cellInfo}
    data={data}
    idPrefix={idPrefix}
    setRow={setRow}
  />
);

export default renderNumberInputFactory;
