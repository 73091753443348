import { AxiosError } from 'axios';
import { DateTime } from 'luxon';
import { useRequestEffect } from '@opusonesolutions/gridos-app-framework';

import { toEvent } from 'types/event';

const useGetPricingEvents = (startTime: DateTime, endTime: DateTime) => {
  const {
    data: events,
    loading: loadingEvents,
    refetch: refetchEvents,
  }: {
    data: any[];
    loading: boolean;
    refetch: () => void;
  } = useRequestEffect({
    url: '/api/mpi/pricing_event',
    method: 'get',
    params: {
      from_start_time: startTime.toISO(),
      to_start_time: endTime.toISO(),
    },
    dataTransform: (data) => data.map((event: any) => toEvent(event)),
    refetchOnChange: [startTime, endTime],
    initialData: [],
    toast: {
      error: (error: AxiosError) => {
        if (error?.response?.data.message) {
          return `${error?.response?.data.message}`;
        }
        return 'Could not load DERs.';
      },
      settings: {
        autoDismiss: true,
      },
    },
  });

  return {
    events,
    loadingEvents,
    refetchEvents,
  };
};

export default useGetPricingEvents;
