/* eslint-disable react/prop-types */
import classnames from 'classnames';
import React from 'react';
import Select from 'react-select';

import './Select.scss';

const WrappedSelect = (props) => {
  return (
    <div
      className={classnames({
        'wrapped-select': true,
        'wrapped-select--row': !!props.row,
      })}
    >
      {props.label && <label className="label">{props.label}</label>}
      <Select
        className="base-select"
        {...props}
        styles={{
          control: (provided) => {
            const style = { ...provided };
            delete style.minHeight;
            return style;
          },
          dropdownIndicator: (provided) => {
            const style = { ...provided };

            if (props.inline) {
              // Sets us to the same lineHeight as an input
              style.padding = 0;
            }

            return style;
          },
        }}
      />
    </div>
  );
};

export default WrappedSelect;
