import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';

import './Tabs.scss';

const ThemedTabs = (props) => (
  <Tabs
    className={`themed-tabs ${props.className ? props.className : ''} ${props.underline}`}
    defaultIndex={!props.selectedIndex && !props.onSelect ? props.defaultIndex : null}
    onSelect={props.onSelect ? (tabIndex) => props.onSelect(tabIndex) : null}
    selectedIndex={props.selectedIndex}
  >
    <TabList>
      <div className="tab-container">
        {props.tabs.map((tab) => (
          <Tab disabled={tab.disabled} key={tab.name}>
            {tab.name}
          </Tab>
        ))}
      </div>
      {props.headerRenderer && (
        <div className="extra-content-container">{props.headerRenderer()}</div>
      )}
    </TabList>
    {props.children(TabPanel)}
  </Tabs>
);

ThemedTabs.defaultProps = {
  className: null,
  underline: 'shadow',
  defaultIndex: 0,
  onSelect: null,
  selectedIndex: null,
  headerRenderer: null,
};

ThemedTabs.propTypes = {
  className: PropTypes.string,
  underline: PropTypes.oneOf(['line', 'shadow']),
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      disabled: PropTypes.bool,
      permissionTipOptions: PropTypes.shape({
        hide: PropTypes.bool,
        title: PropTypes.string,
        placement: PropTypes.oneOf(['top', 'bottom', 'left', 'right', 'none']),
        children: PropTypes.element,
      }),
    })
  ).isRequired,
  children: PropTypes.func.isRequired,
  defaultIndex: PropTypes.number,
  onSelect: PropTypes.func,
  selectedIndex: PropTypes.number,
  headerRenderer: PropTypes.func,
};

export default ThemedTabs;
