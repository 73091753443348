import { Duration } from 'luxon';
import { MarketType } from 'types';
import LocalDateTime from 'helpers/LocalDateTime';
import { MarketParams, State } from './State';

export enum Type {
  Initialize,
  SetStartOffset,
  SetEndOffset,
  SetPrice,
  SetQuantity,
  ClearErrors,
}

export interface Initialize {
  type: Type.Initialize;
  marketParams: MarketParams;
}

export function makeInitState(marketParams: MarketParams): State {
  const startOffset = Duration.fromMillis(0);
  const endOffset = startOffset.plus(marketParams.eventDuration);
  return {
    marketParams,
    startOffset,
    endOffset,
    errors: [],
  };
}

export function initialize(params: Initialize): State {
  return makeInitState(params.marketParams);
}

function validateOffset(offset: Duration, marketParams: MarketParams): string[] {
  const errors: string[] = [];
  offset = offset.shiftTo('hours', 'minutes');

  if (marketParams.marketType === MarketType.DAYAHEAD) {
    // DAYAHEAD market must have 0 minutes component
    if (offset.minutes !== 0) {
      errors.push('For day ahead markets you must select a time that is on the hour.');
    }
  } else if (marketParams.marketType === MarketType.SAMEDAY) {
    // If SAMEDAY market is running on hour intervals, enforce the same on offsets
    const eventDuration = marketParams.eventDuration.shiftTo('hours', 'minutes');
    if (eventDuration.minutes === 0) {
      if (offset.minutes !== 0) {
        errors.push(
          `The same day market is running on 1 hour intervals.
          Please select a start time that is an appropriate multiple.`
        );
      }

      // If SAMEDAY market is running on sub-hourly interval, enforce that offset is a multiple
    } else if (offset.minutes % eventDuration.minutes !== 0) {
      errors.push(
        `The same day market is running on ${eventDuration.minutes} minute intervals.
        Please select a start time that is an appropriate multiple.`
      );
    }
  } else {
    throw new Error(`Unhandled market type ${marketParams.marketType}.`);
  }

  return errors;
}

export interface SetStartOffset {
  type: Type.SetStartOffset;
  pickedDateTime: LocalDateTime;
}
export function setStartOffset(state: State, params: SetStartOffset): State {
  // If the new offset is invalid, don't change anything and toss errors
  const startOffset = params.pickedDateTime.asOffset();
  const errors = validateOffset(startOffset, state.marketParams);
  if (errors.length > 0) {
    return {
      ...state,
      errors: state.errors.concat(errors),
    };
  }

  // If the new start offset is too close to end of day, toss an error
  if (startOffset.plus(state.marketParams.eventDuration).as('days') > 1) {
    return {
      ...state,
      errors: state.errors.concat([
        `Please select a start time that is at least ${state.marketParams.eventDuration.as(
          'minutes'
        )} muinutes before the end of the day.`,
      ]),
    };
  }

  // If end offset is too close to start offset, move it forward
  let endOffset = state.endOffset;
  if (endOffset.minus(startOffset).as('seconds') < state.marketParams.eventDuration.as('seconds')) {
    endOffset = startOffset.plus(state.marketParams.eventDuration);
  }

  return {
    ...state,
    startOffset,
    endOffset,
  };
}

export interface SetEndOffset {
  type: Type.SetEndOffset;
  pickedDateTime: LocalDateTime;
}
export function setEndOffset(state: State, params: SetEndOffset): State {
  // If endOffset is 0, push it forward a day to account for user selecting 24 or 00
  const rawEndOffset = params.pickedDateTime.asOffset();
  const endOffset =
    rawEndOffset.as('seconds') === 0 ? Duration.fromObject({ days: 1 }) : rawEndOffset;

  // If the new offset is invalid, don't change anything and toss errors
  const errors = validateOffset(endOffset, state.marketParams);
  if (errors.length > 0) {
    return {
      ...state,
      errors: state.errors.concat(errors),
    };
  }

  // If the new end offset is too close to start offset, toss an error
  if (
    endOffset.minus(state.startOffset).as('seconds') <
    state.marketParams.eventDuration.as('seconds')
  ) {
    return {
      ...state,
      errors: state.errors.concat([
        `Please select an end time that is at least ${state.marketParams.eventDuration.as(
          'minutes'
        )} minutes after start time.`,
      ]),
    };
  }

  return {
    ...state,
    endOffset,
  };
}

export interface SetPrice {
  type: Type.SetPrice;
  price?: number;
}
export function setPrice(state: State, params: SetPrice): State {
  return {
    ...state,
    price: params.price,
  };
}

export interface SetQuantity {
  type: Type.SetQuantity;
  quantity?: number;
}
export function setQuantity(state: State, params: SetQuantity): State {
  return {
    ...state,
    quantity: params.quantity,
  };
}

export interface ClearErrors {
  type: Type.ClearErrors;
}
export function clearErrors(state: State) {
  return {
    ...state,
    errors: [],
  };
}

export type Action =
  | Initialize
  | SetStartOffset
  | SetEndOffset
  | SetPrice
  | SetQuantity
  | ClearErrors;
