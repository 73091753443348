import React, { isValidElement } from 'react';
import PropTypes from 'prop-types';
import Tippy from '@tippy.js/react';

import './Tooltip.scss';

const DEFAULT_TOOLTIP_EXPIRY = 1500;

const getClassName = (className, isElem) =>
  `${className ? `${className}` : ''} ${isElem ? 'custom-tip' : ''}`;

const Tooltip = ({ content, className, children, arrow, placement, enabled, persist, expires }) => {
  let expiryTimer;
  return content ? (
    <Tippy
      content={content}
      allowHTML
      arrow={arrow}
      isEnabled={enabled}
      className={getClassName(className, isValidElement(content))}
      placement={placement}
      showOnInit={persist}
      {...(persist ? { isVisible: true, hideOnClick: false } : {})}
      onShown={({ hide }) => {
        if (expires && !persist) {
          expiryTimer = setTimeout(() => {
            hide();
          }, DEFAULT_TOOLTIP_EXPIRY);
        }
      }}
      onHide={() => {
        if (expiryTimer) {
          clearTimeout(expiryTimer);
        }
      }}
    >
      <span className={`${className} tip-span`}>{children}</span>
    </Tippy>
  ) : (
    children
  );
};

Tooltip.defaultProps = {
  children: null,
  enabled: true,
  placement: 'top',
  expires: false,
  className: '',
  arrow: false,
  persist: false,
};

Tooltip.propTypes = {
  placement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  children: PropTypes.element,
  enabled: PropTypes.bool,
  expires: PropTypes.bool,
  className: PropTypes.string,
  arrow: PropTypes.bool,
  persist: PropTypes.bool,
};

export default Tooltip;
