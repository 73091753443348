import React from 'react';
import { useRequestEffect, useRequest } from '@opusonesolutions/gridos-app-framework';

import HeaderNavigationBar from 'components/HeaderNavigationBar';
import { Customer } from 'types/customer';

import AssetList from './AssetList';
import CustomerInfoPanel from './CustomerInfoPanel';
import CompanyInfoPanel from './CompanyInfoPanel';
import '../styles/Profile.scss';

const Profile = () => {
  const { data: customer, refetch } = useRequestEffect<Customer>({
    url: '/api/mpi/customer',
    method: 'get',
    refetchOnChange: [],
  });

  const { makeRequest: runSave, loading: saving } = useRequest('/api/mpi/customer');
  const saveCustomer = async (cust: Customer) => {
    await runSave({
      body: cust,
      method: 'patch',
      onSuccess: refetch,
      toast: {
        error: 'Failed to update customer profile.',
        success: 'Customer profile updated.',
      },
    });
  };

  return (
    <div className="my-profile">
      <HeaderNavigationBar title="My Profile" />
      <div className="content-container">
        {customer && (
          <>
            <div className="column-1">
              <CustomerInfoPanel customer={customer} />
              <CompanyInfoPanel customer={customer} saving={saving} updateCustomer={saveCustomer} />
            </div>
            <div className="column-2">
              <AssetList ders={customer ? customer.ders : []} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Profile;
