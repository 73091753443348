import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { ProgramsContext } from 'contexts/ProgramsContext';

class CurrentTimeWidget extends PureComponent {
  state = {
    time: DateTime.local(),
  };

  componentDidMount() {
    // Update the current time every second
    this.intervalID = setInterval(() => this.tick(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  tick = () => {
    this.setState({ time: DateTime.local() });
  };

  handleTimezone = (date) => {
    const timezone = this.context.getTimezone();
    return date.setZone(timezone).toFormat('ZZZZ HH:mm:ss');
  };

  render() {
    return (
      <div className="current-time-widget" style={{ ...this.props.style, color: this.props.color }}>
        {this.handleTimezone(this.state.time)}
      </div>
    );
  }
}

CurrentTimeWidget.contextType = ProgramsContext;
CurrentTimeWidget.contextTypes = {
  programs: PropTypes.array,
  getTimezone: PropTypes.func,
};

CurrentTimeWidget.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object,
};

CurrentTimeWidget.defaultProps = {
  color: '#000',
  style: {},
};

export default CurrentTimeWidget;
