import React, { FunctionComponent } from 'react';
import ContentLoader from 'react-content-loader';

interface BaseAssetDetailsRowProps {
  divisor: number;
  label: string;
  loading: boolean;
  number: boolean;
  string: boolean;
  unit: string;
}

interface NumberAssetDetailsProps extends BaseAssetDetailsRowProps {
  attribute: number;
  number: true;
}

interface StringAssetDetailsProps extends BaseAssetDetailsRowProps {
  attribute: string;
  string: true;
}

type AssetDetailsProps = NumberAssetDetailsProps | StringAssetDetailsProps;

const AssetDetailsRow: FunctionComponent<AssetDetailsProps> = (props) => {
  const { attribute = '', divisor = 1, label, loading = false, number, string, unit = '' } = props;

  const _renderDisplayView = () => {
    let attr;
    let value;
    if (attribute === '' || attribute === null) {
      value = '';
    } else if (attribute === 0) {
      value = attribute;
    } else if (typeof attribute === 'number') {
      value = attribute / divisor;
    }
    // Render numeric attribute
    if (number) {
      attr = (
        <>
          {value}
          {attribute !== '' && attribute !== null && <small>{unit && ` ${unit}`}</small>}
        </>
      );
    }

    // Render string attribute
    if (string) {
      attr = attribute;
    }

    return <p>{attr}</p>;
  };

  return (
    <div className="property-row">
      <div className="property-label">
        <p>{label}</p>
      </div>
      {loading && (
        <div className="property-loading">
          <ContentLoader
            height="16px"
            width="200px"
            speed={1}
            // @ts-ignore
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
          >
            <rect x="0" y="0" rx="5" ry="5" width="200" height="16" />
          </ContentLoader>
        </div>
      )}
      {!loading && <div className="property-value">{_renderDisplayView()}</div>}
    </div>
  );
};

export default AssetDetailsRow;
