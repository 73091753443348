import React, { useEffect, useState, FunctionComponent } from 'react';

import Modal from 'components/Modal';
import SimpleNumberInput from 'components/SimpleNumberInput';
import RadioButtonGroup from 'components/RadioButtonGroup';
import Select from 'components/Select';
import useOffers from 'hooks/useOffers';
import { BidOfferType, MarketType, OfferType } from 'types';
import { BidOffer } from 'types/bidOffer';

import BidOfferButtonGroup from './BidOfferButtonGroup';
import BidOfferTable from './BidOfferTable';

import './StandingOffer.scss';

enum StandingOfferMode {
  'SinglePair' = 'SinglePair',
  'TimeSeries' = 'TimeSeries',
}

interface StandingOfferToggleProps {
  disableSinglePair: boolean;
  id: string;
  mode: StandingOfferMode;
  onChange: (mode: StandingOfferMode) => void;
}

const StandingOfferToggle: FunctionComponent<StandingOfferToggleProps> = ({
  disableSinglePair,
  id,
  mode,
  onChange,
}) => (
  <RadioButtonGroup
    id={id}
    listType="row"
    onChange={onChange}
    options={[
      {
        disabled: disableSinglePair,
        id: StandingOfferMode.SinglePair,
        label: 'Use single price/quantity pair',
      },
      {
        id: StandingOfferMode.TimeSeries,
        label: 'Use timeseries',
      },
    ]}
    value={mode}
  />
);

interface SinglePairInputProps {
  assetID: string;
  currency: string;
  loading: boolean;
  marketType: MarketType;
  offer?: BidOffer;
  setPair: (column: keyof BidOffer, value?: number | BidOfferType) => void;
  showOfferSelector: boolean;
  title: string;
}

const SinglePairInput: FunctionComponent<SinglePairInputProps> = ({
  assetID,
  currency,
  loading,
  marketType,
  offer,
  setPair,
  showOfferSelector,
  title,
}) => {
  const [offerType, setOfferType] = useState(offer?.bidOfferType);
  const [price, setPrice] = useState(offer?.price);
  const [quantity, setQuantity] = useState(offer?.quantity);

  useEffect(() => {
    setOfferType(offer?.bidOfferType);
    setPrice(offer?.price);
    setQuantity(offer?.quantity);
  }, [offer]);

  return (
    <div className="standing-offer-row">
      <span className="standing-offer-row-title" />
      <span className="standing-offer-row-content">
        {showOfferSelector && (
          <span className="row-input">
            <Select
              className="mode-select"
              options={TYPES}
              inline
              isDisabled={loading}
              value={TYPES.find((t) => t.value === offerType)}
              onChange={(t: { value: BidOfferType }) => setPair('bidOfferType', t.value)}
              isClearable={false}
            />
          </span>
        )}
        <span className="row-input">
          <SimpleNumberInput
            id="standing-offer-pair-price-input"
            max={1e6}
            min={-1e6}
            step={0.001}
            disabled={loading}
            value={price}
            onChange={(e: any) => setPair('price', parseFloat(e.target.value))}
            unit={`${currency}/MWh`}
          />
        </span>
        <span className="row-input">
          <SimpleNumberInput
            id="standing-offer-pair-quantity-input"
            max={1e6}
            min={-1e6}
            step={0.001}
            disabled={loading}
            value={quantity}
            onChange={(e: any) => setPair('quantity', parseFloat(e.target.value))}
            unit="MW"
          />
        </span>
      </span>
    </div>
  );
};

const TYPES = [
  {
    label: 'Bid',
    value: 'BID',
  },
  {
    label: 'Offer',
    value: 'OFFER',
  },
];

type StandingOfferProps = {
  allowSinglePair: boolean;
  assetID: string;
  currency: string;
  disableBessOffers: boolean;
  marketType: MarketType;
  showOfferSelector: boolean;
  assetType: string;
};

const StandingOffer = ({
  allowSinglePair,
  assetID,
  currency,
  disableBessOffers,
  marketType,
  showOfferSelector = false,
  assetType,
}: StandingOfferProps) => {
  const [mode, setMode] = useState(
    allowSinglePair ? StandingOfferMode.SinglePair : StandingOfferMode.TimeSeries
  );
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const title = marketType === 'DAYAHEAD' ? 'Day Ahead' : 'Same Day';
  const toggleMode = () => {
    mode === StandingOfferMode.SinglePair
      ? setMode(StandingOfferMode.TimeSeries)
      : setMode(StandingOfferMode.SinglePair);
  };

  const {
    deleteAllOffers,
    disable,
    edited,
    loading,
    deletePair,
    deleteRow,
    setEdited,
    setPair,
    setRow,
    savePair,
    saveTimeSeries,
    singlePair,
    timeSeries,
    timezone,
  } = useOffers({
    assetID,
    assetType,
    marketType,
    offerType:
      mode === StandingOfferMode.SinglePair ? OfferType.StandingPair : OfferType.StandingTimeSeries,
  });

  const toggleOnChange = (value: StandingOfferMode) => {
    if (!allowSinglePair && value === StandingOfferMode.SinglePair) {
      return;
    }
    if (value === mode) {
      return;
    }
    if (edited) {
      setShowConfirmModal(true);
    } else {
      setShowConfirmModal(false);
      toggleMode();
    }
  };

  const hasPair = !!singlePair?.id;
  const hasTimeSeries = !!timeSeries.find((singleTimeSeries) =>
    singleTimeSeries && singleTimeSeries.id ? !!singleTimeSeries.id : false
  );

  const idPrefix = `${marketType.toLowerCase()}-so`;
  const isDeletable =
    (mode === StandingOfferMode.SinglePair && hasPair) ||
    (mode === StandingOfferMode.TimeSeries && hasTimeSeries);

  return (
    <div className="standing-offer">
      <div className="standing-offer-header">
        <span className="standing-offer-title">Standing Offer</span>
        <span className="standing-offer-header-content">
          <StandingOfferToggle
            disableSinglePair={!allowSinglePair}
            id={`standing-offer-radio-${marketType}`}
            mode={mode}
            onChange={toggleOnChange}
          />
          <BidOfferButtonGroup
            disableDelete={disable || loading || !isDeletable}
            disableSave={disable || loading || !edited}
            deleteOnClick={mode === StandingOfferMode.SinglePair ? deletePair : deleteAllOffers}
            saveOnClick={mode === StandingOfferMode.SinglePair ? savePair : saveTimeSeries}
          />
        </span>
      </div>
      {showConfirmModal && (
        <Modal
          active
          onCancel={() => setShowConfirmModal(false)}
          onConfirm={() => {
            setEdited(false);
            setShowConfirmModal(false);
            toggleMode();
          }}
        >
          Switching without saving will erase any current changes. Continue?
        </Modal>
      )}
      {mode === StandingOfferMode.SinglePair && (
        <SinglePairInput
          assetID={assetID}
          currency={currency}
          loading={loading}
          marketType={marketType}
          offer={singlePair}
          setPair={setPair}
          showOfferSelector={showOfferSelector}
          title={title}
        />
      )}
      {mode === StandingOfferMode.TimeSeries && (
        <div className="standing-offer-row">
          <div className="standing-offer-row-title" />
          <div className="standing-offer-row-content">
            <BidOfferTable
              currency={currency}
              data={timeSeries}
              deleteRow={deleteRow}
              disableBessOffers={disableBessOffers}
              idPrefix={idPrefix}
              isBiddable={!allowSinglePair}
              loading={loading}
              setRow={setRow}
              timezone={timezone}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default StandingOffer;
