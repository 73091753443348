import React from 'react';

import Button from 'components/Button';

import './BidOfferButtonGroup.scss';

interface BidOfferButtonGroupProps {
  showDelete?: boolean;
  showSave?: boolean;
  disableDelete: boolean;
  disableSave: boolean;
  deleteOnClick: () => void;
  saveOnClick: () => void;
}

const BidOfferButtonGroup = ({
  showDelete,
  showSave,
  disableDelete,
  disableSave,
  deleteOnClick,
  saveOnClick,
}: BidOfferButtonGroupProps) => {
  return (
    <span className="btn-group">
      {showDelete && (
        <Button className="delete-btn" onClick={deleteOnClick} disabled={disableDelete}>
          Delete
        </Button>
      )}
      {showSave && (
        <Button className="save-btn" onClick={saveOnClick} disabled={disableSave}>
          Save
        </Button>
      )}
    </span>
  );
};

BidOfferButtonGroup.defaultProps = {
  showDelete: true,
  showSave: true,
};

export default BidOfferButtonGroup;
