export interface Program {
  battery_control: string;
  currency: string;
  eventDuration: number;
  id: number;
  locale: string;
  name: string;
  timezone: string;
}

export const toPrograms = (programs: any[]): Program[] => {
  return programs.map(
    ({
      sameday_event_duration: eventDuration,
      id,
      name,
      timezone,
      battery_control,
      currency,
      locale,
    }) => {
      return { eventDuration, id, name, timezone, battery_control, currency, locale };
    }
  );
};
