import React, { useContext, useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import { useRequestEffect, useRequest } from '@opusonesolutions/gridos-app-framework';

import Button from 'components/Button';
import ChartWrapper from 'components/ChartWrapper';
import HeaderNavigationBar from 'components/HeaderNavigationBar';
import MetricsRibbon from 'components/MetricsRibbon';
import Select from 'components/Select';
import { ProgramsContext } from 'contexts/ProgramsContext';

import fileExportSave from 'helpers/downloadFile';
import CALENDAR_MONTHS from 'helpers/months';
import EVENT_STATES from 'helpers/eventStates';
import useLocaleFormatter from 'hooks/useLocaleFormatters';

import { PricingEvent } from './pricingEvents';
import './Report.scss';

type AggregatedDailyEvents = {
  date: number; // Unix timestamp
  committedEnergy: number | null;
  earned: number | null;
};
type EventByDayDict = { [key: number]: PricingEvent[] };

function getYears(endYear: number) {
  let start = DateTime.local();
  const end = DateTime.local(endYear, 1, 1);

  const years = [];
  while (end <= start) {
    const year = start.year;
    years.push({ value: year, label: year });
    start = start.plus({ years: -1 });
  }

  return years;
}

const COMMITTED_STATUSES = [EVENT_STATES.CONFIRMED, EVENT_STATES.ACTIVE, EVENT_STATES.COMPLETED];
const EARNED_STATUS = EVENT_STATES.COMPLETED;
const END_YEAR = 2017;
const YEARS = getYears(END_YEAR);

const Report = () => {
  const { programs } = useContext(ProgramsContext);
  const { currencyFormatter } = useLocaleFormatter(programs[0]?.currency, programs[0]?.locale);

  const [selectedMonth, setSelectedMonth] = useState(DateTime.local().month - 1);
  const [selectedYear, setSelectedYear] = useState(DateTime.local().year);

  const { monthStart, monthEnd } = useMemo(() => {
    const start = DateTime.local(selectedYear, selectedMonth + 1, 1).startOf('day');
    const end = start.endOf('month');
    return { monthStart: start, monthEnd: end };
  }, [selectedYear, selectedMonth]);

  const { data: pricingEvents = [], loading: eventsLoading } = useRequestEffect<PricingEvent[]>({
    method: 'get',
    url: `/api/mpi/pricing_event`,
    params: {
      from_start_time: monthStart.toISO(),
      to_start_time: monthEnd.toISO(),
    },
    initialData: [],
    refetchOnChange: [monthStart, monthEnd],
    toast: {
      error: 'Could not load pricing events',
    },
  });

  const getAggregatedValues = (events: PricingEvent[]) => {
    const [committedEnergy, earned] = getCommitted(events);

    return {
      dailyValues: aggregateByDate(bucketByDate(events)),
      committedEnergy,
      earned,
    };
  };

  const getCommitted = (events: PricingEvent[]) =>
    events.reduce(
      (totals, event) => {
        const updatedTotals = [...totals];
        let committedTotal = 0;
        if (
          COMMITTED_STATUSES.includes(event.state.state_type) &&
          event.market_type !== 'DAYAHEAD' &&
          event.power_required !== 'None'
        ) {
          committedTotal =
            parseFloat(event.unit_price) *
            (parseFloat(event.power_required) / 1000000) *
            (event.duration / 3600);
          updatedTotals[0] += committedTotal;
        }

        // Only SD events are settled. The settled price will be in
        // the settlement_value field of the event.
        if (
          event.state.state_type === EARNED_STATUS &&
          event.market_type === 'SAMEDAY' &&
          event.settlement_value !== 'None'
        ) {
          updatedTotals[1] += parseFloat(event.settlement_value);
        }
        return updatedTotals;
      },
      [0, 0]
    );

  const bucketByDate = (events: PricingEvent[]) => {
    const accumulator: EventByDayDict = {};
    return events.reduce((lookup, event) => {
      if (event.market_type === 'DAYAHEAD') {
        // Only SD events are actually run, so only they can influence committed dollars
        return lookup;
      }

      const date = DateTime.fromISO(event.start_time).day;
      if (lookup[date]) {
        lookup[date] = [...lookup[date], event];
      } else {
        lookup[date] = [event];
      }
      return lookup;
    }, accumulator);
  };

  const aggregateByDate = (events: EventByDayDict) => {
    const days = DateTime.local(selectedYear, selectedMonth + 1).daysInMonth;
    const dates = [...Array(days).keys()];

    const aggregatedEvents: AggregatedDailyEvents[] = [];
    return dates.reduce((list, date) => {
      const calendarDate = date + 1;
      let committedEnergy = null;
      let earned = null;

      if (events[calendarDate]) {
        [committedEnergy, earned] = getCommitted(events[calendarDate]);
      }

      list.push({
        date: monthStart.plus({ days: date }).valueOf(),
        committedEnergy,
        earned,
      });
      return list;
    }, aggregatedEvents);
  };

  const { dailyValues, committedEnergy, earned } = getAggregatedValues(pricingEvents);

  const { makeRequest: runDownload, loading: downloadingSettlement } = useRequest(
    '/api/mpi/pricing_event/settlement/report'
  );
  const downloadSettlementReport = async () => {
    return runDownload({
      method: 'get',
      params: {
        month: selectedMonth + 1,
        year: selectedYear,
      },
      onSuccess: (data: Blob, headers: Record<string, any>) => {
        fileExportSave(data, headers);
      },
      toast: {
        error: 'Failed to download settlement report',
      },
      responseType: 'blob',
      headers: {
        'Cache-Control': 'no-cache, no-store',
        Pragma: 'no-cache',
        Expires: '0',
      },
    });
  };

  const {
    makeRequest: runMktTransactionDownload,
    loading: downloadingMarketTransactions,
  } = useRequest('/api/mpi/pricing_event/market_transactions/report');
  const downloadMarketTransactionsReport = async () => {
    return runMktTransactionDownload({
      method: 'get',
      params: {
        start_date: monthStart.toFormat('yyyy-LL-dd'),
        end_date: monthEnd.toFormat('yyyy-LL-dd'),
      },
      onSuccess: (data: Blob, headers: Record<string, any>) => {
        fileExportSave(data, headers);
      },
      toast: {
        error: 'Failed to download market transactions report',
      },
      responseType: 'blob',
      headers: {
        'Cache-Control': 'no-cache, no-store',
        Pragma: 'no-cache',
        Expires: '0',
      },
    });
  };

  return (
    <div className="report-container">
      <HeaderNavigationBar title="Report" />
      <div className="report-card">
        <div className="card-header">
          <h2>Revenue</h2>
          <div className="card-header-right">
            <div className="month-controls">
              <Select
                className="month-selector"
                options={CALENDAR_MONTHS}
                isSearchable={false}
                isClearable={false}
                value={CALENDAR_MONTHS[selectedMonth]}
                onChange={({ value }: any) => {
                  if (value !== selectedMonth) {
                    setSelectedMonth(value);
                  }
                }}
                id="revenue-month"
              />
              <Select
                className="year-selector"
                options={YEARS}
                isSearchable={false}
                isClearable={false}
                value={YEARS[DateTime.local().year - selectedYear]}
                onChange={({ value }: any) => {
                  if (value !== selectedYear) {
                    setSelectedYear(value);
                  }
                }}
                id="revenue-year"
              />
            </div>
            <Button
              className="settlement-button"
              loading={downloadingSettlement}
              onClick={downloadSettlementReport}
            >
              Download Settlement Report
            </Button>
            <Button
              loading={downloadingMarketTransactions}
              onClick={downloadMarketTransactionsReport}
            >
              Download Market Transactions Report
            </Button>
          </div>
        </div>
        <div className="card-body">
          <MetricsRibbon
            metrics={[
              {
                label: 'Total Earned',
                value: currencyFormatter.format(earned),
              },
              {
                label: 'Total Committed',
                value: currencyFormatter.format(committedEnergy),
              },
            ]}
          />
          <div className="graph">
            {pricingEvents.length > 0 && (
              <ChartWrapper
                type="line"
                data={{
                  datasets: [
                    {
                      label: 'Total Committed',
                      backgroundColor: '#f15c9d',
                      borderColor: '#f15c9d',
                      data: dailyValues.map((v) => ({
                        x: v.date,
                        y: v.committedEnergy,
                      })),
                      fill: false,
                    },
                    {
                      label: 'Total Earned',
                      backgroundColor: '#ffcb39',
                      borderColor: '#ffcb39',
                      data: dailyValues.map((v) => ({
                        x: v.date,
                        y: v.earned,
                      })),
                      fill: false,
                    },
                  ],
                }}
                options={{
                  maintainAspectRatio: false,
                  scales: {
                    x: {
                      type: 'time',
                      scaleLabel: {
                        display: true,
                        labelString: 'Date',
                      },
                      min: monthStart.valueOf(),
                      max: monthEnd.valueOf(),
                    },
                    y: {
                      suggestedMin: 0,
                      suggestedMax: 1, // Ensure that we at last show to 1MW
                      gridLines: {
                        drawBorder: false,
                      },
                      scaleLabel: {
                        display: true,
                        labelString: `${programs[0]?.currency} (Total)`,
                      },
                      ticks: {
                        callback: (value: number) =>
                          (Math.round(value * 100) / 100).toLocaleString(),
                      },
                    },
                  },
                  tooltips: {
                    intersect: false,
                    mode: 'index',
                    callbacks: {
                      title: (tooltipItems: any) => {
                        let title = '';
                        if (tooltipItems.length > 0) {
                          const { dataPoint } = tooltipItems[0];
                          const dateTime = DateTime.fromMillis(dataPoint.x);
                          title = dateTime.toFormat('DDD');
                        }

                        return title;
                      },
                      label: (tooltipItem: any) => {
                        const { dataPoint, dataset } = tooltipItem;
                        return `${dataset.label}: ${currencyFormatter.format(dataPoint.y)}`;
                      },
                    },
                  },
                }}
              />
            )}
            {!pricingEvents.length && !eventsLoading && (
              <p className="no-events-message">No pricing events found in the selected month.</p>
            )}
            {eventsLoading && <i className="fa fa-refresh fa-spin fa-5x fa-fw" />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Report;
