import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions as appActions } from 'store/appState';

import HeaderNavigationBar from './HeaderNavigationBar';
import './HeaderNavigationBar.scss';

const mapStateToProps = (state) => ({
  menuOpen: state.global.leftMenuOpen,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...appActions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderNavigationBar);
