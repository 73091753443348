import React, { Component } from 'react';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

import ChartWrapper from 'components/ChartWrapper';
import eventStates from 'helpers/eventStates';
import './GenerationChartPanel.scss';

const ACTIVE_STATUSES = [eventStates.CONFIRMED, eventStates.ACTIVE, eventStates.COMPLETED];

class GenerationChartPanel extends Component {
  static getDerivedStateFromProps() {
    return {
      liveStartHour: DateTime.local().startOf('hour'),
      liveEndHour: DateTime.local().endOf('hour'),
    };
  }

  state = {
    liveStartHour: DateTime.local().startOf('hour'),
    liveEndHour: DateTime.local().endOf('hour'),
  };

  getChartDatasets = () => {
    const colorPanel = ['#FFC222', '#E64097', '#06AFA8', '#FF7F00', '#78A22F'];
    const datasets = Object.values(this.props.DERLookup).map((der, index) => ({
      backgroundColor: colorPanel[index % colorPanel.length],
      borderColor: colorPanel[index % colorPanel.length],
      borderWidth: 2,
      data: (this.props.pricingEventsByDER[der.rdf_id] || [])
        .filter((event) => ACTIVE_STATUSES.includes(event.status))
        .map((event) => ({
          x: DateTime.fromISO(event.start_time).valueOf(),
          y: parseFloat(event.power_required) / 1e3,
        }))
        .sort((a, b) => a.x - b.x),
      fill: false,
      label: der.name,
      stepped: 'before',
    }));
    return datasets;
  };

  render() {
    const datasets = this.getChartDatasets();
    const liveBackgroundPlugin = {
      id: 'live-background',
      afterUpdate: (chart) => {
        const ctx = chart.ctx;
        const { bottom, top } = chart.chartArea;
        const gradient = ctx.createLinearGradient(
          0,
          isNaN(top) ? 0 : top,
          0,
          isNaN(bottom) ? 0 : bottom
        );
        gradient.addColorStop(0, 'rgba(0, 121, 193, 1)');
        gradient.addColorStop(1, 'rgba(0, 121, 193, 0.2)');
        liveBackgroundPlugin.gradient = gradient; // Cache on ourself for easy access
      },
      beforeDatasetsDraw: (chart) => {
        const ctx = chart.ctx;
        const { bottom, top } = chart.chartArea;
        const axis = chart.scales.x;
        const left = axis.getPixelForValue(this.state.liveStartHour);
        const right = axis.getPixelForValue(this.state.liveEndHour);
        const width = right - left;

        ctx.fillStyle = liveBackgroundPlugin.gradient;
        ctx.fillRect(left, top, width, bottom - top);
      },
    };

    return (
      <div className="generation-chart-panel">
        <div className="generation-schedule-chart">
          {!!datasets.length && (
            <ChartWrapper
              type="line"
              data={{
                datasets,
              }}
              options={{
                maintainAspectRatio: false,
                responsive: true,
                scales: {
                  x: {
                    type: 'time',
                    scaleLabel: {
                      display: true,
                      labelString: 'Date',
                    },
                    min: this.props.currentDate.startOf('day').valueOf(),
                    max: this.props.currentDate.endOf('day').valueOf(),
                  },
                  y: {
                    suggestedMin: 0,
                    gridLines: {
                      drawBorder: false,
                    },
                    scaleLabel: {
                      display: true,
                      labelString: 'kW',
                    },
                    ticks: {
                      callback: (value) => `${value.toLocaleString()}`,
                    },
                  },
                },
                tooltips: {
                  intersect: false,
                  mode: 'index',
                  callbacks: {
                    label: (tooltipItem) => {
                      const { dataPoint, dataset } = tooltipItem;
                      let value = dataPoint.y;
                      value = Math.round(value * 100) / 100; // Get 2 decimal places
                      return `${dataset.label}: ${value.toLocaleString()} kW`;
                    },
                  },
                },
              }}
              plugins={[liveBackgroundPlugin]}
            />
          )}
        </div>
      </div>
    );
  }
}

GenerationChartPanel.propTypes = {
  currentDate: PropTypes.object,
  DERLookup: PropTypes.object,
  pricingEventsByDER: PropTypes.object.isRequired,
};

GenerationChartPanel.defaultProps = {
  currentDate: DateTime.local(),
  DERLookup: {},
};

export default GenerationChartPanel;
