import React, { FunctionComponent, useMemo } from 'react';
import ReactTable from 'react-table-6';

import { BidOfferType } from 'types';
import { BidOffer } from 'types/bidOffer';

import renderBidOfferSelectFactory from './renderSelectFactory';
import renderBidOfferDeleteFactory from './renderBidOfferDeleteFactory';
import renderNumberInputFactory from './renderNumberInputFactory';

import 'react-table-6/react-table.css';
import './BidOfferTable.scss';

const TIME_FORMAT = 'HH:mm:ss';

interface PageOptions {
  defaultPageSize: number;
  showPagination: boolean;
  showPageSizeOptions: boolean;
}

const getPageOptions = (data: BidOffer[]): PageOptions => {
  const pageOptions: PageOptions = {
    defaultPageSize: 12,
    showPagination: false,
    showPageSizeOptions: false,
  };

  if (data.length > 12) {
    pageOptions.showPagination = true;
  }

  return pageOptions;
};

interface BidOfferTableParams {
  currency: string;
  data: BidOffer[];
  deleteRow: (index: number) => void;
  idPrefix: string;
  isBiddable: boolean;
  loading: boolean;
  setRow: (row: number, column: keyof BidOffer, value?: number | BidOfferType) => void;
  disableBessOffers: boolean;
  timezone: string;
}

const BidOfferTable: FunctionComponent<BidOfferTableParams> = ({
  currency,
  data,
  deleteRow,
  idPrefix,
  isBiddable,
  loading,
  setRow,
  disableBessOffers,
  timezone,
}) => {
  const pageOptions = useMemo(() => getPageOptions(data), [data]);

  const renderBidOfferDelete = useMemo(() => renderBidOfferDeleteFactory(data, deleteRow), [
    data,
    deleteRow,
  ]);
  const renderBidOfferSelect = useMemo(
    () => renderBidOfferSelectFactory(!isBiddable || disableBessOffers, data, setRow),
    [data, disableBessOffers, isBiddable, setRow]
  );
  const renderNumberInput = useMemo(
    () => renderNumberInputFactory(disableBessOffers, idPrefix, data, setRow),
    [data, idPrefix, setRow, disableBessOffers]
  );

  const columns = useMemo(
    () => [
      {
        Header: `Start time (${timezone})`,
        id: 'startTime',
        accessor: (d: any) => d.startTime.toFormat(TIME_FORMAT),
      },
      {
        Header: `End time (${timezone})`,
        id: 'endTime',
        accessor: (d: any) => d.endTime.toFormat(TIME_FORMAT),
      },
      {
        Header: 'Bid/Offer',
        accessor: 'bidOfferType',
        Cell: renderBidOfferSelect,
      },
      {
        Header: `Price (${currency}/MWh)`,
        accessor: 'price',
        Cell: renderNumberInput,
      },
      {
        Header: 'Quantity (MW)',
        accessor: 'quantity',
        Cell: renderNumberInput,
      },
      {
        Header: 'Delete',
        Cell: renderBidOfferDelete,
        width: 100,
      },
    ],
    [renderBidOfferDelete, renderBidOfferSelect, renderNumberInput, timezone, currency]
  );

  return (
    <ReactTable
      className="-striped"
      columns={columns}
      data={data}
      loading={loading}
      {...pageOptions}
    />
  );
};

export default BidOfferTable;
