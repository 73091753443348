import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import FormField from './FormField';
import validateRequiredFields from '../helpers/requiredFieldValidation';

class AssetNameplateInfo extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isValid: true,
    };
    this.requiredFields = ['ratedPower', 'ratedVoltage', 'powerFactor'];
  }

  isValidated() {
    const isValid = validateRequiredFields(this.props, this.requiredFields);
    this.setState({ isValid });
    return isValid;
  }

  render() {
    return (
      <div>
        <div className="registration-title">Asset Nameplate Info</div>

        {this.state.isValid || (
          <div className="registration-info">
            All fields marked with <span className="required-field">*</span> are required.
          </div>
        )}

        <form name="asset-general-info">
          <FormField
            name="ratedPower"
            label="Rated Power"
            type="number"
            value={this.props.ratedPower}
            isRequired
            onChange={(e) => this.props.onFieldChange(e)}
            unitSymbol="kVA"
          />

          <FormField
            name="ratedVoltage"
            label="Rated Voltage"
            type="number"
            value={this.props.ratedVoltage}
            isRequired
            onChange={(e) => this.props.onFieldChange(e)}
            unitSymbol="kV"
          />
          <FormField
            name="powerFactor"
            label="Power Factor"
            type="number"
            value={this.props.powerFactor}
            isRequired
            onChange={(e) => this.props.onFieldChange(e)}
          />
        </form>
      </div>
    );
  }
}

AssetNameplateInfo.propTypes = {
  onFieldChange: PropTypes.func.isRequired,
  ratedPower: PropTypes.string.isRequired,
  ratedVoltage: PropTypes.string.isRequired,
  powerFactor: PropTypes.string.isRequired,
};

export default AssetNameplateInfo;
