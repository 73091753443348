import { DateTime } from 'luxon';
import React from 'react';

import IconButton from 'components/IconButton';
import './EventDisplay.scss';

type EventDisplayProps = {
  addTime: (amount: number) => void;
  duration: number;
  eventStartTime: DateTime;
};

const EventDisplay = ({ addTime, duration, eventStartTime }: EventDisplayProps) => {
  const endOfHour = eventStartTime.plus({ seconds: duration - 1 }).toFormat('HH:mm:ss');
  const startOfHour = eventStartTime.toFormat('DD: HH:mm:ss');

  return (
    <div className="event-display-container">
      <IconButton
        className="map-button"
        height="49px"
        icon="keyboard_arrow_left"
        onClick={() => addTime(-1)}
        tooltip="Previous Hour"
        width="40px"
      />
      <span className="event-display">
        <span className="display-label">Event:</span>
        {`${startOfHour} - ${endOfHour}`}
      </span>
      <IconButton
        className="map-button"
        height="49px"
        icon="keyboard_arrow_right"
        onClick={() => addTime(1)}
        tooltip="Next Hour"
        width="40px"
      />
    </div>
  );
};

export default EventDisplay;
