import { DateTime } from 'luxon';
import { useContext, useState, useMemo } from 'react';

import { ProgramsContext } from 'contexts/ProgramsContext';
import useOffersApi from 'hooks/useOffersApi';
import { BidOfferType, MarketType, OfferType } from 'types';
import { BidOffer } from 'types/bidOffer';

import { useSinglePair, useTimeSeries } from './useTimeSeries';

interface UseOffersOptions {
  assetID: string;
  assetType: string;
  date?: DateTime;
  marketType: MarketType;
  offerType: OfferType;
}

type MaybeNumber = number | undefined;

const useOffers = ({ assetID, assetType, date, marketType, offerType }: UseOffersOptions) => {
  const [deleteId, setDeleteId] = useState<MaybeNumber>();
  const [edited, setEdited] = useState(false);

  const { getSamedayEventDuration, getTimezone } = useContext(ProgramsContext);
  const eventDuration = marketType === MarketType.DAYAHEAD ? 3600 : getSamedayEventDuration();
  const timezone = getTimezone();

  const { deleteAllOffers, disable, loading, saveOffers, offers } = useOffersApi({
    assetID,
    date,
    deleteId,
    eventDuration,
    marketType,
    offerType,
    setDeleteId,
    setEdited,
    timezone,
    assetType,
  });

  const { deletePair, setPair, singlePair } = useSinglePair({
    marketType,
    offers,
    assetType,
  });

  const { deleteRow, setRow, timeSeries } = useTimeSeries({
    date,
    eventDuration,
    marketType,
    offers,
    timezone,
    assetType,
  });

  const memoDeletePair = useMemo(
    () => () => {
      const { id } = singlePair;
      if (id) {
        deletePair();
        setDeleteId(id);
        setEdited(false);
      }
    },
    [deletePair, singlePair]
  );
  const memoDeleteRow = useMemo(
    () => (index: number) => {
      const { id } = timeSeries[index];
      if (id) {
        deleteRow(index);
        setDeleteId(id);
      }
    },
    [deleteRow, timeSeries]
  );

  const memoSavePair = useMemo(() => () => saveOffers([singlePair]), [saveOffers, singlePair]);
  const memoSaveTimeSeries = useMemo(() => () => saveOffers(timeSeries), [saveOffers, timeSeries]);

  const memoSetPair = useMemo(
    () => (column: keyof BidOffer, value?: BidOfferType | number) => {
      setPair(column, value);
      setEdited(true);
    },
    [setPair]
  );
  const memoSetRow = useMemo(
    () => (index: number, column: keyof BidOffer, value?: BidOfferType | number) => {
      setRow(index, column, value);
      setEdited(true);
    },
    [setRow]
  );

  return {
    deleteAllOffers,
    deletePair: memoDeletePair,
    deleteRow: memoDeleteRow,
    disable,
    edited,
    loading,
    savePair: memoSavePair,
    saveTimeSeries: memoSaveTimeSeries,
    setEdited,
    setPair: memoSetPair,
    setRow: memoSetRow,
    singlePair,
    timeSeries,
    timezone,
    saveOffer: saveOffers,
  };
};

export default useOffers;
