import { Duration } from 'luxon';
import { MarketType } from 'types';

export interface MarketParams {
  eventDuration: Duration;
  marketType: MarketType;
}

export function marketParamsEquals(a: MarketParams, b: MarketParams): boolean {
  return a.eventDuration.equals(b.eventDuration) && a.marketType === b.marketType;
}

export interface State {
  marketParams: MarketParams;
  startOffset: Duration;
  endOffset: Duration;
  price?: number;
  quantity?: number;
  errors: string[];
}
