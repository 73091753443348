import FileSaver from 'file-saver';

const fileExportSave = (data: Blob, headers: { [key: string]: string }, fallbackName = '') => {
  const contentDisposition = headers['content-disposition'];
  let fileName = fallbackName;

  if (contentDisposition) {
    const parts = contentDisposition.split(';');
    for (let i = 0; i < parts.length; i += 1) {
      const part = parts[i];
      if (part.match('filename=')) {
        fileName = `${part.split('=')[1]}`;
        break;
      }
    }
  }

  // flask will wrap names with double quotes if there is a space
  // we can strip it since the OS will handle the rest
  if (fileName[0] === '"' && fileName[fileName.length - 1] === '"') {
    fileName = fileName.substr(1, fileName.length - 2);
  }

  FileSaver.saveAs(data, fileName);
};

export default fileExportSave;
