import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Checkbox from 'components/Checkbox';

import FormField from './FormField';
import validateRequiredFields from '../helpers/requiredFieldValidation';

class AssetAdditionalInfo extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isValid: true,
    };

    this.requiredFields = ['assetLocation', 'city', 'administrativeArea', 'country', 'postalCode'];
  }

  onMeterInstalledChange() {
    this.props.onFieldChange({
      target: {
        name: 'meterInstalled',
        value: !this.props.meterInstalled,
      },
    });
  }

  async isValidated() {
    const isValid = validateRequiredFields(this.props, this.requiredFields);
    this.setState({ isValid });

    let returnValue = isValid;

    if (isValid) {
      returnValue = await this.props.submitApplication();
    }

    return returnValue;
  }

  render() {
    return (
      <div>
        <div className="registration-title">Asset Additional Info</div>
        <form name="asset-general-info">
          {this.state.isValid || (
            <div className="registration-info">
              All fields marked with <span className="required-field">*</span> are required.
            </div>
          )}
          {this.props.errorMessage && (
            <div className="registration-error">{this.props.errorMessage}</div>
          )}

          <FormField
            name="assetLocation"
            label="Asset Location"
            value={this.props.assetLocation}
            onChange={(e) => this.props.onFieldChange(e)}
            isRequired
          />

          <div className="column-container">
            <div className="left-column">
              <FormField
                name="city"
                label="City"
                value={this.props.city}
                onChange={(e) => this.props.onFieldChange(e)}
                isRequired
              />

              <FormField
                name="administrativeArea"
                label="State/Province"
                value={this.props.administrativeArea}
                onChange={(e) => this.props.onFieldChange(e)}
                isRequired
              />
            </div>
            <div className="right-column">
              <FormField
                name="country"
                label="Country"
                value={this.props.country}
                onChange={(e) => this.props.onFieldChange(e)}
                isRequired
              />

              <FormField
                name="postalCode"
                label="Zip/Postal Code"
                value={this.props.postalCode}
                onChange={(e) => this.props.onFieldChange(e)}
                isRequired
              />
            </div>
          </div>

          <div className="column-container">
            <div className="left-column">
              <FormField
                name="permitVerificationNo"
                label="Permit Verification Number"
                value={this.props.permitVerificationNo}
                onChange={(e) => this.props.onFieldChange(e)}
              />
            </div>
            <div className="right-column">
              <FormField
                name="assetInstallationDate"
                type="date"
                label="Asset Installation Date"
                value={this.props.assetInstallationDate}
                onChange={(e) => this.props.onFieldChange(e)}
              />
            </div>
          </div>

          <div className="checkbox-container">
            <Checkbox
              checked={this.props.meterInstalled}
              backgroundColor="#FFF"
              checkColor="#0079C1"
              borderColor="#d1d1d1"
              onClick={() => this.onMeterInstalledChange()}
              name="meterInstalled"
            />

            <label htmlFor="invoiceContactSameAsPrimary">Meter Installed?</label>
          </div>

          {this.props.meterInstalled && (
            <div className="column-container">
              <div className="left-column">
                <FormField
                  name="meterSerialNo"
                  label="Meter Serial Number"
                  value={this.props.meterSerialNo}
                  onChange={(e) => this.props.onFieldChange(e)}
                />
              </div>
              <div className="right-column">
                <FormField
                  name="meterServicePointId"
                  label="Meter Service Point ID"
                  value={this.props.meterServicePointId}
                  onChange={(e) => this.props.onFieldChange(e)}
                />
              </div>
            </div>
          )}
        </form>
      </div>
    );
  }
}

AssetAdditionalInfo.propTypes = {
  onFieldChange: PropTypes.func.isRequired,
  assetLocation: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  administrativeArea: PropTypes.string.isRequired,
  postalCode: PropTypes.string.isRequired,
  permitVerificationNo: PropTypes.string.isRequired,
  assetInstallationDate: PropTypes.string.isRequired,
  meterInstalled: PropTypes.bool.isRequired,
  meterSerialNo: PropTypes.string.isRequired,
  meterServicePointId: PropTypes.string.isRequired,
  submitApplication: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
};

export default AssetAdditionalInfo;
