import React from 'react';
import { useHistory, Route, Switch } from 'react-router-dom';
import { ProtectedRoute, useAuthContext } from '@opusonesolutions/gridos-app-framework';

import LeftRailNavigation from 'components/LeftRailNavigation';
import BidsOffers from 'routes/BidsOffers';
import Home from 'routes/Home/containers/HomeContainer';
import Login from 'routes/Login';
import DayAheadMarket from 'routes/DayAheadMarket/containers/DayAheadContainer';
import SameDayMarket from 'routes/SameDayMarket/containers/SameDayContainer';
import Assets from 'routes/Assets/containers/AssetsContainer';
import AssetInfo from 'routes/AssetInfo/containers/AssetInfoContainer';
import Profile from 'routes/Profile/components/Profile';
import CustomerRegistration from 'routes/Registration/routes/CustomerRegistration';
import AssetRegistration from 'routes/Registration/routes/AssetRegistration';
import Report from 'routes/Report';

const App = (props) => {
  const { isAuthEnabled, permissions } = useAuthContext();
  const history = useHistory();

  return (
    <div className="MPI">
      <LeftRailNavigation {...props} />
      <Switch>
        <ProtectedRoute exact path="/">
          <Home history={history} />
        </ProtectedRoute>
        <ProtectedRoute exact path="/day-ahead-market">
          <DayAheadMarket history={history} authEnabled={isAuthEnabled} permissions={permissions} />
        </ProtectedRoute>
        <ProtectedRoute exact path="/day-ahead-market/:date/:hour/:eventId">
          <DayAheadMarket history={history} authEnabled={isAuthEnabled} permissions={permissions} />
        </ProtectedRoute>
        <ProtectedRoute exact path="/same-day-market">
          <SameDayMarket history={history} />
        </ProtectedRoute>
        <ProtectedRoute exact path="/same-day-market/:date/:hour/:eventId">
          <SameDayMarket history={history} />
        </ProtectedRoute>
        <ProtectedRoute exact path="/assets">
          <Assets history={history} />
        </ProtectedRoute>
        <ProtectedRoute exact path="/assets/register">
          <AssetRegistration history={history} />
        </ProtectedRoute>
        <ProtectedRoute exact path="/assets/:asset">
          <AssetInfo />
        </ProtectedRoute>
        <ProtectedRoute exact path="/offers">
          <BidsOffers />
        </ProtectedRoute>
        <ProtectedRoute exact path="/profile">
          <Profile />
        </ProtectedRoute>
        <ProtectedRoute exact path="/report">
          <Report />
        </ProtectedRoute>
        <Route path="/login" component={Login} />
        <Route path="/register" component={CustomerRegistration} />
      </Switch>
    </div>
  );
};

export default App;
