import * as Actions from './Actions';
import { State } from './State';

export default function reducer(state: State | undefined, action: Actions.Action) {
  switch (action.type) {
    case Actions.Type.Initialize:
      return Actions.initialize(action);

    default:
      if (state === undefined) {
        throw new Error(`Must have state for non-initialize action '${action.type}'.`);
      }

      switch (action.type) {
        case Actions.Type.SetStartOffset:
          return Actions.setStartOffset(state, action);

        case Actions.Type.SetEndOffset:
          return Actions.setEndOffset(state, action);

        case Actions.Type.SetPrice:
          return Actions.setPrice(state, action);

        case Actions.Type.SetQuantity:
          return Actions.setQuantity(state, action);

        case Actions.Type.ClearErrors:
          return Actions.clearErrors(state);

        default:
          throw new Error(`Unhandled action '${JSON.stringify(action, undefined, 2)}'`);
      }
  }
}
