import React, { FunctionComponent } from 'react';

import ChartWrapper from 'components/ChartWrapper';
import { BidOffer } from 'types/bidOffer';

import './SupplyStackGraph.scss';

type SupplyStackGraphProps = {
  currencyFormatter: Intl.NumberFormat;
  offers: BidOffer[];
  title: string;
  xLabel: string;
  yLabel: string;
};

const SupplyStackGraph: FunctionComponent<SupplyStackGraphProps> = ({
  currencyFormatter,
  offers,
  title,
  xLabel,
  yLabel,
}) => {
  offers.sort((a, b) => {
    const price1 = a.price || 0;
    const price2 = b.price || 0;

    if (price1 < price2) {
      return -1;
    }
    if (price1 > price2) {
      return 1;
    }
    return 0;
  });

  const data: Record<string, any> = [];
  let powerSum = 0.0;

  offers.forEach((offer) => {
    data.push({
      x: powerSum,
      y: offer.price,
    });
    powerSum += offer.quantity || 0.0;
  });

  // Add one extra point to extend the line
  data.push({
    x: powerSum,
    y: offers[offers.length - 1]?.price,
  });

  const dataset = {
    label: 'Supply Stack',
    backgroundColor: '#00467F',
    borderColor: '#00467F',
    fill: false,
    stepped: 'before',
    pointRadius: 0,
    data,
  };

  return (
    <div className="supply-stack-graph">
      <ChartWrapper
        data={{
          datasets: [dataset],
        }}
        options={{
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          scales: {
            x: {
              type: 'linear',
              min: 0,
              suggestedMax: powerSum || 1,
              scaleLabel: {
                display: true,
                labelString: xLabel,
              },
              ticks: {
                callback: (value: number) => `${(value * 1e3).toFixed(0)}`,
              },
            },
            y: {
              suggestedMin: 0,
              gridLines: {
                drawBorder: false,
              },
              scaleLabel: {
                display: true,
                labelString: yLabel,
              },
            },
          },
          title: {
            display: true,
            position: 'top',
            text: title,
          },
          tooltips: {
            intersect: false,
            mode: 'nearest',
            axis: 'x',
            callbacks: {
              title: (tooltipItems: Array<any>) => {
                const { dataPoint } = tooltipItems[0];
                return `${(dataPoint.x * 1e3).toFixed(0)} kW`;
              },
              label: (tooltipItem: any) => {
                return `${currencyFormatter.format(tooltipItem.dataPoint.y)} /MW`;
              },
            },
          },
        }}
        type="line"
      />
    </div>
  );
};

export default SupplyStackGraph;
