import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import Checkbox from 'components/Checkbox';
import HeaderNavigationBar from 'components/HeaderNavigationBar';
import asyncActionStates from 'helpers/asyncActionStates';
import AssetRow from './AssetRow';
import '../styles/Assets.scss';

const { LOADING, SUCCESS, ERROR } = asyncActionStates;

class Assets extends PureComponent {
  state = {
    assets: {},
    currentTab: 0,
    editing: false,
  };

  componentWillUnmount() {
    this.props.actions.clearDERs();
  }

  UNSAFE_componentWillMount() {
    this.props.actions.getDERs();
    this.setState({ assets: this.props.DERLookup });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.DERLookup !== this.props.DERLookup) {
      this.setState({ assets: nextProps.DERLookup });
    }

    if (this.props.updateAssetReq === LOADING && nextProps.updateAssetReq === SUCCESS) {
      this.setState({ editing: false });
    }
  }

  handleActivateDeactivate = (assets, activated) => {
    const edited = assets
      .filter((asset) => asset.selected)
      .map((asset) => ({
        id: asset.id,
        activated,
      }));

    if (edited.length) {
      this.props.actions.updateAssetSettings(edited);
    }
  };

  handleSelectedToggle = (rdfId, selected) => {
    this.setState({
      assets: {
        ...this.state.assets,
        [rdfId]: {
          ...this.state.assets[rdfId],
          selected,
        },
      },
    });
  };

  handleSelectAllToggle = (assets, selected) => {
    const newAssets = { ...this.state.assets };

    assets.forEach((asset) => {
      newAssets[asset.rdf_id] = {
        ...this.state.assets[asset.rdf_id],
        selected,
      };
    });

    this.setState({
      assets: newAssets,
    });
  };

  handleEmailToggle = (rdfId, id, status) => {
    this.setState({
      assets: {
        ...this.state.assets,
        [rdfId]: {
          ...this.state.assets[rdfId],
          email: {
            ...this.state.assets[rdfId].email,
            enabled: status,
          },
          edited: true,
        },
      },
    });
  };

  handleEditClick = () => {
    this.props.actions.initializeAssetRequest();
    this.setState({ editing: true });
  };

  handleSaveClick = () => {
    const edited = Object.values(this.state.assets)
      .filter((asset) => asset.edited)
      .map((asset) => ({
        id: asset.id,
        enabled: asset.email.enabled,
        activated: asset.activated,
      }));

    if (edited.length > 0) {
      this.props.actions.updateAssetSettings(edited);
    }
  };

  handleCancelClick = () => this.setState({ editing: false, assets: this.props.DERLookup });

  handleUpdateTab = (index) => this.setState({ currentTab: index });

  renderAssetList(assets, active) {
    const allSelected = assets.every((asset) => asset.selected);
    const someSelected = assets.some((asset) => asset.selected);
    const activeText = active ? 'Deactivate' : 'Activate';

    return (
      <div className="assets-content">
        <div className="assets-table__header-container">
          {!this.state.editing && (
            <>
              <div className="assets-table__selected">
                <Checkbox
                  className="select-all_checkbox"
                  checked={allSelected && assets.length > 0}
                  backgroundColor={allSelected && assets.length ? '#0079C1' : '#FFF'}
                  checkColor="#FFF"
                  borderColor="#d1d1d1"
                  onClick={() => this.handleSelectAllToggle(assets, !allSelected)}
                  disabled={this.state.editing}
                />
              </div>
              <button
                className="activate-deactivate-btn"
                disabled={!someSelected}
                onClick={() => this.handleActivateDeactivate(assets, !active)}
              >
                {this.props.updateAssetReq === LOADING ? (
                  <i className="fas fa-sync fa-spin" />
                ) : (
                  activeText
                )}
              </button>
            </>
          )}
        </div>
        <div className="assets-table__asset-titles">
          <div className="assets-table__name">Name</div>
          <div className="assets-table__status assets-table--hide-mobile">Status</div>
          <div className="assets-table__type assets-table--hide-mobile">Type</div>
          <div className="assets-table__email assets-table--hide-mobile">Email</div>
          <div className="assets-table__enabled assets-table--hide-mobile">Email Notification</div>
        </div>
        <div className="scroll-container">
          {assets.map((a) => (
            <AssetRow
              key={a.rdf_id}
              asset={a}
              editing={this.state.editing}
              handleEmailToggle={this.handleEmailToggle}
              handleSelectedToggle={this.handleSelectedToggle}
            />
          ))}
        </div>
      </div>
    );
  }

  render() {
    const activeAssets = [];
    const inactiveAssets = [];

    Object.values(this.state.assets).forEach((asset) => {
      if (asset.activated) {
        activeAssets.push(asset);
      } else {
        inactiveAssets.push(asset);
      }
    });

    const sorter = Intl.Collator(undefined, {
      numeric: true,
      sensitivity: 'base',
    });
    activeAssets.sort((a, b) => sorter.compare(a.name, b.name));
    inactiveAssets.sort((a, b) => sorter.compare(a.name, b.name));

    return (
      <div className="assets-container">
        <HeaderNavigationBar title="My Assets" />
        <Tabs selectedIndex={this.state.currentTab} onSelect={this.handleUpdateTab}>
          <TabList>
            <Tab>{`Active ${activeAssets.length}`}</Tab>
            <Tab>{`Inactive ${inactiveAssets.length}`}</Tab>
            <div className="asset-controls">
              <p className="asset-controls-feedback">
                {this.props.updateAssetReq === SUCCESS && 'Update successful.'}
                {this.props.updateAssetReq === ERROR && 'Update failed, please try again.'}
              </p>
              {!this.state.editing && (
                <>
                  <button onClick={this.handleEditClick} className="control-btn edit-btn">
                    Edit
                  </button>
                  <button
                    className="control-btn enroll-asset-btn"
                    onClick={() => this.props.history.push('/assets/register')}
                  >
                    Enroll Asset
                  </button>
                </>
              )}
              {this.state.editing && (
                <div>
                  <button onClick={this.handleCancelClick} className="control-btn cancel-btn">
                    Cancel
                  </button>
                  <button onClick={this.handleSaveClick} className="control-btn save-btn">
                    {this.props.updateAssetReq === LOADING ? (
                      <i className="fas fa-sync fa-spin" />
                    ) : (
                      <p>Save</p>
                    )}
                  </button>
                </div>
              )}
            </div>
          </TabList>
          <TabPanel>{this.renderAssetList(activeAssets, true)}</TabPanel>
          <TabPanel>{this.renderAssetList(inactiveAssets, false)}</TabPanel>
        </Tabs>
      </div>
    );
  }
}

Assets.propTypes = {
  DERLookup: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  updateAssetReq: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired,
};

export default Assets;
