/* eslint-disable jsx-a11y/no-static-element-interactions */
// Rule disabled to allow header to be interactable and also allow for nested controls.

import React, { PureComponent } from 'react';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

import { ProgramsContext } from 'contexts/ProgramsContext';

import eventStates from './eventStates';

const ACTIVE_STATUSES = [eventStates.CONFIRMED, eventStates.ACTIVE, eventStates.COMPLETED];
const OFFER_STATUSES = [eventStates.PENDING, eventStates.ACCEPTED, eventStates.COUNTERED];

// Creates a row summary header for the pricing events page at a single timestamp
class TimestampRow extends PureComponent {
  getPlaceHolder = (hasEvents, isLoading) =>
    isLoading ? <i className="fa fa-spinner fa-pulse fa-1x fa-fw" /> : <p>--</p>;

  getDoubleValuePlaceHolder = (hasEvents, isLoading) =>
    isLoading ? <i className="fa fa-spinner fa-pulse fa-1x fa-fw" /> : <p>--/--</p>;

  // Display a range or single value depending on the event prices in this timestamp
  getRange = (eventList) => {
    const eventPrices = eventList.map((event) => event.unit_price);
    if (eventPrices.length <= 0) {
      return <p>--</p>;
    }

    const currencyFormatter = new Intl.NumberFormat(this.context.programs[0]?.locale || 'en-US', {
      style: 'currency',
      currency: this.context.programs[0]?.currency || 'USD',
    });

    if (eventPrices.length === 1) {
      return currencyFormatter.format(eventPrices[0]);
    }

    const max = currencyFormatter.format(Math.max(...eventPrices));
    const min = currencyFormatter.format(Math.min(...eventPrices));
    if (max === min) {
      return max;
    }

    return `${min} - ${max}`;
  };

  calculateCommittedOfferedMonetaryValues = (events) => {
    const p = events.reduce(
      (a, b) => {
        if (ACTIVE_STATUSES.includes(b.status)) {
          a[0] += b.totalPrice;
          a[1] += b.totalPrice;
        }
        if (OFFER_STATUSES.includes(b.status)) {
          a[1] += b.totalPrice;
        }
        return a;
      },
      [0, 0]
    );

    const currencyFormatter = new Intl.NumberFormat(this.context.programs[0]?.locale || 'en-US', {
      style: 'currency',
      currency: this.context.programs[0]?.currency || 'USD',
    });

    return (
      <>
        <p className="committed-value">{currencyFormatter.format(p[0])}</p>/
        <p>{currencyFormatter.format(p[1])}</p>
      </>
    );
  };

  calculateCommittedOfferedPowerRequiredKW = (events) => {
    const e = events.reduce(
      (a, b) => {
        if (ACTIVE_STATUSES.includes(b.status)) {
          a[0] += b.realPower / 1000;
          a[1] += b.realPower / 1000;
        }
        if (OFFER_STATUSES.includes(b.status)) {
          a[1] += b.realPower / 1000;
        }
        return a;
      },
      [0, 0]
    );
    const numberFormatter = new Intl.NumberFormat(this.context.programs[0]?.locale || 'en-US');

    return (
      <>
        <div className="committed-value">{numberFormatter.format(e[0])}</div>/
        <div>{numberFormatter.format(e[1])}</div>
      </>
    );
  };

  calculateCommittedOfferedReactivePowerRequired = (events) => {
    const e = events.reduce((a, b) => {
      if (ACTIVE_STATUSES.includes(b.status) || OFFER_STATUSES.includes(b.status)) {
        a += b.reactivePower / 1000;
      }
      return a;
    }, 0);
    const numberFormatter = new Intl.NumberFormat(this.context.programs[0]?.locale || 'en-US');
    return <div className="committed-value">{numberFormatter.format(e)}</div>;
  };

  render() {
    const { events, timestamp, isTimestampOpen, toggleTimestamp, loading } = this.props;

    const chevronDirection = isTimestampOpen ? 'up' : 'down';

    const hasData = events !== null && events.length > 0 && !loading;

    const duration = events && events.length > 0 ? parseInt(events[0].duration, 10) : 3600;
    const startTime = DateTime.fromMillis(timestamp);
    const endTime = startTime.plus({ seconds: duration - 1 });

    return (
      <div
        role="button"
        tabIndex={0}
        className="pricing-events-hour-header"
        onClick={() => toggleTimestamp(timestamp)}
        onKeyPress={(e) => {
          e.preventDefault();
          if (e.key === ' ' || e.key === 'Enter') {
            toggleTimestamp(timestamp);
          }
        }}
      >
        <div className="pricing-events-hour-cell cell-sm">
          <p>
            {startTime.toFormat('HH:mm:ss')} - {endTime.toFormat('HH:mm:ss')}
          </p>
        </div>
        <div className="pricing-events-hour-cell cell-sm">
          {hasData ? <p>{this.getRange(events)}</p> : this.getPlaceHolder(events, loading)}
        </div>
        <div className="pricing-events-hour-cell cell-sm">
          {hasData
            ? this.calculateCommittedOfferedMonetaryValues(events)
            : this.getDoubleValuePlaceHolder(events, loading)}
        </div>
        <div className="pricing-events-hour-cell cell-md">
          {hasData
            ? this.calculateCommittedOfferedPowerRequiredKW(events)
            : this.getDoubleValuePlaceHolder(events, loading)}
        </div>
        <div className="pricing-events-hour-cell cell-md">
          {hasData
            ? this.calculateCommittedOfferedReactivePowerRequired(events)
            : this.getPlaceHolder(events, loading)}
        </div>
        <div className="pricing-events-hour-cell cell-lg pricing-events-hour-toggle">
          <div className="pricing-events-hour__btn">
            <i className={`fa fa-chevron-${chevronDirection}`} aria-hidden="true" />
          </div>
        </div>
      </div>
    );
  }
}

TimestampRow.contextType = ProgramsContext;
TimestampRow.contextTypes = {
  programs: PropTypes.array,
};

TimestampRow.defaultProps = {
  events: [],
  isTimestampOpen: false,
  loading: false,
};

TimestampRow.propTypes = {
  events: PropTypes.array,
  timestamp: PropTypes.number.isRequired,
  isTimestampOpen: PropTypes.bool,
  loading: PropTypes.bool,
  toggleTimestamp: PropTypes.func.isRequired,
};

export default TimestampRow;
