import { AxiosError } from 'axios';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useRequestEffect, useRequest } from '@opusonesolutions/gridos-app-framework';

import { MarketType, OfferType } from 'types';
import { BidOffer, fromBidOffer, sortBidOffer, toBidOffer } from 'types/bidOffer';

const getMessageEnd = (offerType: OfferType): string => {
  if (offerType === OfferType.DatedTimeSeries) {
    return 'dated timeseries offers';
  }
  if (offerType === OfferType.StandingTimeSeries) {
    return 'standing offers';
  }
  if (offerType === OfferType.StandingPair) {
    return 'standing offer';
  }
  return '';
};

const getDeleteAllErrorMessage = (marketType: MarketType, offerType: OfferType): string => {
  return `Could not delete ${marketType.toLowerCase()} ${getMessageEnd(offerType)}`;
};

const getLoadErrorMessage = (marketType: MarketType, offerType: OfferType): string => {
  return `Could not load ${marketType.toLowerCase()} ${getMessageEnd(offerType)}.`;
};

const getSaveErrorMessage = (marketType: MarketType, offerType: OfferType): string => {
  return `Failed to save ${marketType.toLowerCase()} ${getMessageEnd(offerType)}.`;
};

const getSaveSuccessMessage = (marketType: MarketType, offerType: OfferType): string => {
  return `Successfully saved ${marketType.toLowerCase()} ${getMessageEnd(offerType)}.`;
};

const getBaseUrl = (assetID: string, offerType: OfferType) =>
  `/api/mpi/asset/${assetID}/${
    offerType === OfferType.DatedTimeSeries ? 'bid_offer' : 'standing_offer'
  }`;

const getUrl = (assetID: string, marketType: MarketType, offerType: OfferType): string =>
  `${getBaseUrl(assetID, offerType)}/market/${marketType.toLowerCase()}`;

export const useGetOffersRequestEffect = (
  assetID: string,
  date: DateTime | undefined,
  marketType: MarketType,
  offerType: OfferType,
  setEdited: (edited: boolean) => void,
  timezone: string,
  assetType: string
) => {
  const { data, loading, refetch } = useRequestEffect<BidOffer[]>({
    url: getUrl(assetID, marketType, offerType),
    method: 'get',
    params: {
      start_time: date?.toISO(),
      end_time: date?.plus({ days: 1 }).toISO(),
    },
    blockRequest: () => assetID === undefined,
    dataTransform: (offers) => sortBidOffer(toBidOffer(offers, timezone, !!date, assetType)),
    refetchOnChange: [assetID, date, marketType, offerType],
    initialData: [],
    onSuccess: () => setEdited(false),
    toast: {
      error: (error: AxiosError) => {
        if (error?.response?.data.message) {
          return `${error?.response?.data.message}`;
        }
        return getLoadErrorMessage(marketType, offerType);
      },
      settings: {
        autoDismiss: true,
      },
    },
  });
  return {
    loadingOffers: loading,
    offers: data || [],
    refetchOffers: refetch,
  };
};

export const useGetAllOffersRequestEffect = (
  eventDuration: number,
  marketType: MarketType,
  startTime: DateTime,
  timezone: string
) => {
  const { data, loading } = useRequestEffect<BidOffer[]>({
    url: `/api/mpi/bid_offer/market/${marketType.toLowerCase()}`,
    method: 'get',
    params: {
      start_time: startTime.toISO(),
      end_time: startTime.plus({ seconds: eventDuration }).toISO(),
      market_timezone: timezone,
      interval: eventDuration,
    },
    blockRequest: () => !eventDuration || !marketType || !startTime,
    dataTransform: (offers) => sortBidOffer(toBidOffer(offers, timezone, true)),
    refetchOnChange: [eventDuration, marketType, startTime],
    initialData: [],
    toast: {
      error: (error: AxiosError) => {
        if (error?.response?.data.message) {
          return `${error?.response?.data.message}`;
        }
        return 'Could not load bids/offers.';
      },
      settings: {
        autoDismiss: true,
      },
    },
  });
  return {
    loadingOffers: loading,
    offers: data || [],
  };
};

export const useDeleteOfferRequestEffect = (
  assetID: string,
  deleteId: number | undefined,
  offerType: OfferType,
  refetchOffers: () => void,
  setDeleteId: (deleteId?: number) => void
) => {
  const { loading } = useRequestEffect({
    url: `${getBaseUrl(assetID, offerType)}/${deleteId || ''}`,
    method: 'delete',
    blockRequest: () => deleteId === undefined,
    refetchOnChange: [assetID, deleteId, offerType],
    onError: () => refetchOffers(),
    onSuccess: () => {
      refetchOffers();
      setDeleteId();
    },
    toast: {
      error: (error: AxiosError) => {
        if (error?.response?.data.message) {
          return `${error?.response?.data.message}`;
        }
        return 'Could not delete offer.';
      },
      settings: {
        autoDismiss: true,
      },
    },
  });

  return { deletingOffer: loading };
};

export const useDeleteAllOffersRequest = (
  assetID: string,
  date: DateTime | undefined,
  eventDuration: number,
  marketType: MarketType,
  offerType: OfferType,
  refetchOffers: () => void,
  assetType: string
) => {
  const { loading, makeRequest } = useRequest(getUrl(assetID, marketType, offerType));

  const deleteAllOffers = useMemo(
    () => async () => {
      await makeRequest({
        method: 'delete',
        params: {
          duration: assetType.includes('Load') ? undefined : eventDuration,
          start_time: date ? date.toISO() : '',
          end_time: date ? date.plus({ days: 1 }).toISO() : '',
        },
        onSuccess: () => refetchOffers(),
        toast: {
          error: (error: AxiosError) => {
            if (error?.response?.data.message) {
              return `${error?.response?.data.message}`;
            }
            return getDeleteAllErrorMessage(marketType, offerType);
          },
          settings: {
            autoDismiss: true,
          },
        },
      });
    },
    [date, eventDuration, makeRequest, marketType, offerType, refetchOffers, assetType]
  );

  return { deleteAllOffers, deletingAllOffers: loading };
};

export const useSaveOffersRequest = (
  assetID: string,
  date: DateTime | undefined,
  marketType: MarketType,
  offerType: OfferType,
  refetchOffers: () => void
) => {
  const { loading, makeRequest } = useRequest(getUrl(assetID, marketType, offerType));

  const saveOffers = useMemo(
    () => async (offers: BidOffer[]) => {
      await makeRequest({
        method: 'put',
        body: fromBidOffer(assetID, offers, !!date),
        onSuccess: () => refetchOffers(),
        toast: {
          error: (error: AxiosError) => {
            if (error?.response?.data.message) {
              return `${error?.response?.data.message}`;
            }
            return getSaveErrorMessage(marketType, offerType);
          },
          success: getSaveSuccessMessage(marketType, offerType),
          settings: {
            autoDismiss: true,
          },
        },
      });
    },
    [assetID, date, makeRequest, marketType, offerType, refetchOffers]
  );

  return { saveOffers, savingOffers: loading };
};

interface useOffersApiOptions {
  assetID: string;
  date?: DateTime;
  deleteId?: number;
  eventDuration: number;
  marketType: MarketType;
  setDeleteId: (deleteId?: number) => void;
  setEdited: (edited: boolean) => void;
  offerType: OfferType;
  timezone: string;
  assetType: string;
}

const useOffersApi = ({
  assetID,
  date,
  deleteId,
  eventDuration,
  marketType,
  offerType,
  setDeleteId,
  setEdited,
  timezone,
  assetType,
}: useOffersApiOptions) => {
  const { loadingOffers, offers, refetchOffers } = useGetOffersRequestEffect(
    assetID,
    date,
    marketType,
    offerType,
    setEdited,
    timezone,
    assetType
  );

  const { deletingOffer } = useDeleteOfferRequestEffect(
    assetID,
    deleteId,
    offerType,
    refetchOffers,
    setDeleteId
  );

  const { deleteAllOffers, deletingAllOffers } = useDeleteAllOffersRequest(
    assetID,
    date,
    eventDuration,
    marketType,
    offerType,
    refetchOffers,
    assetType
  );

  const { saveOffers, savingOffers } = useSaveOffersRequest(
    assetID,
    date,
    marketType,
    offerType,
    refetchOffers
  );

  return {
    deleteAllOffers,
    disable: deletingOffer || deletingAllOffers || savingOffers,
    loading: loadingOffers,
    saveOffers,
    offers,
  };
};

export default useOffersApi;
