import React from 'react';
import { useHistory } from 'react-router-dom';

import Button from 'components/Button';

const NoCommittedEvents = () => {
  const history = useHistory();
  return (
    <div className="events-placeholder">
      <div className="events-message">
        <img
          className="events-placeholder__img"
          src="/no_committed_event.svg"
          alt="No Committed Events"
        />
        <h2>No Committed Events</h2>
        <p>No committed events on selected date.</p>
        <p>Start exploring the markets to participate.</p>
        <div className="market-links">
          <Button onClick={() => history.push('/day-ahead-market')} label="Day Ahead Market" />
          <Button onClick={() => history.push('/same-day-market')} label="Same Day Market" />
        </div>
      </div>
    </div>
  );
};

export default NoCommittedEvents;
