import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import '../styles/AssetList.scss';
import Asset from './Asset';

class AssetList extends PureComponent {
  render() {
    const { ders } = this.props;

    return (
      <div className="profile-asset-list">
        <div className="profile-asset-list__header">Assets Assigned to Me</div>
        <div className="profile-asset-list__ders">
          {ders.map((der) => (
            <Asset der={der} key={der.id} />
          ))}
        </div>
        <div className="profile-asset-list__footer">
          <Link to="/assets" className="asset-link">
            Go to My Assets
          </Link>
        </div>
      </div>
    );
  }
}

AssetList.propTypes = {
  ders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      info: PropTypes.shape({
        class: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    })
  ).isRequired,
};

export default AssetList;
